import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Rooms } from '@/services/api/rooms'
import { RoomSingleRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGetRoomById = (id: number, props?: UseQueryOptions<RoomSingleRo, AxiosErrorResponse>) => {
  return useQuery<RoomSingleRo, AxiosErrorResponse>(
    ['rooms', id],
    async () => {
      const response = await Rooms.getById(id)
      return response.data
    },
    props,
  )
}
