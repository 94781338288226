import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GoogleSheets } from '@/services/api/google-sheets'
import { GoogleSheetsUserRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGoogleGetUserSettings = (userId: number, props?: UseQueryOptions<GoogleSheetsUserRo, AxiosErrorResponse>) => {
  return useQuery<GoogleSheetsUserRo, AxiosErrorResponse>(
    ['google-sheets', 'settings', 'user', userId],
    async () => {
      const response = await GoogleSheets.getUserSettings(userId)
      return response.data
    },
    props,
  )
}
