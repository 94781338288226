import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { ReviewEditDto, ReviewEditRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'
import { Reviews } from '@/services/api/reviews'

export type UseEditReviewPayload = {
  id: number
} & ReviewEditDto

export const useEditReview = (
  props?: UseMutationOptions<ReviewEditRo, AxiosErrorResponse, UseEditReviewPayload>
) => {
  const queryClient = useQueryClient()

  return useMutation<ReviewEditRo, AxiosErrorResponse, UseEditReviewPayload>(
    ['reviews'],
    async (payload: UseEditReviewPayload) => {
      const { id, ...params } = payload
      const response = await Reviews.edit(id, params)

      return response.data;
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['reviews'])
        return props?.onSuccess?.(...args)
      }
    },
  )
}
