import { UserContractsTable } from '@/components/Containers/UserContractsTable/UserContractsTable'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'

export const UserContractsPage = () => {
  return (
    <DashboardLayout>
      <UserContractsTable />
    </DashboardLayout>
  )
}
