import { useFormContext } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'

export interface WithFormContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (values: UseFormReturn<any, any, any>) => JSX.Element
}

export const WithFormContext = ({ children }: WithFormContextProps) => {
  const context = useFormContext()

  return children(context)
}
