import { AxiosResponse } from 'axios'

import { LoginRo, LoginUserDto, RefreshTokensDto } from '@/services/data-contracts'
import { Request } from '../Request'

export const Auth = {
  login: async (params: LoginUserDto) => Request.post<LoginUserDto, AxiosResponse<LoginRo>>('/auth/login', params),
  logout: async () => Request.post<void, AxiosResponse<boolean>>('/auth/logout'),
  refreshTokens: async (params: RefreshTokensDto) => Request.post<RefreshTokensDto, AxiosResponse<LoginRo>>('/auth/refresh-tokens', params),
  checkTokens: async () => Request.post<void, AxiosResponse<boolean>>('/auth/refresh-tokens'),
}
