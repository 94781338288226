export enum CustomSettingTypeEnum {
  Text = 'text',
}

export type CustomSetting = {
  name: string
  title: string
}

export type TextSettingProps = CustomSetting
export type TextSettingValues = {
  title: string
  content: string
  type: CustomSettingTypeEnum.Text
}
