import { UserContracts } from '@/services/api/user-contracts'
import { UserContractRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useGetUserContractById = (
  id: number,
  props?: UseQueryOptions<UserContractRo, AxiosErrorResponse>,
) => {
  return useQuery<UserContractRo, AxiosErrorResponse>(
    ['user-contracts', id],
    async () => {
      const response = await UserContracts.getById(id)
      return response.data
    },
    props,
  )
}
