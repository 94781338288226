import { UserGroupsTable } from '@/components/Containers/UserGroupsTable/UserGroupsTable'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'

export const UserGroupsPage = () => {
  return (
    <DashboardLayout>
      <UserGroupsTable />
    </DashboardLayout>
  )
}
