import { FormEventHandler, useState } from 'react'

import { useSnackBar } from '@/context/SnackbarContext'
import { useGoogleCheckAlive } from '@/services/hooks/google-sheets/useGoogleCheckAlive'
import { useGoogleExportProfit } from '@/services/hooks/google-sheets/useGoogleExportProfit'
import { parseGoogleSheetId } from '@/utils/parseGoogleSheetId'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@mui/material'

export const GoogleSheetsExportProfit = () => {
  const [input, setInput] = useState('')
  const { data: isAlive } = useGoogleCheckAlive()
  const { showSnackBar } = useSnackBar()
  const exportProfit = useGoogleExportProfit({
    onSuccess: () =>
      showSnackBar(`Профит успешно выгружен по ID: "${input}"`, 'success'),
    onError: error =>
      showSnackBar('Не удалось выгрузить профит.', 'error', error),
  })

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()

    exportProfit.mutate({ googleSheetId: input })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card
        sx={{ minWidth: 400, px: 2, pb: 2, height: 260 }}
        variant="outlined"
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            mb={2}
          >
            <MonetizationOnIcon sx={{ fontSize: 58 }} color="secondary" />
          </Box>
          <Box mb={2}>
            <Typography variant="h4">Выгрузить профит игроков</Typography>
          </Box>
          <Box>
            <TextField
              autoComplete="on"
              disabled={!isAlive || exportProfit.isLoading}
              fullWidth
              size="small"
              placeholder="Скопируйте ID или URL таблицы"
              value={input}
              onChange={event => setInput(event.target.value)}
              onPaste={event => {
                const raw = event.clipboardData.getData('Text')
                const parsedId = parseGoogleSheetId(raw)

                if (parsedId) {
                  requestAnimationFrame(() => {
                    setInput(parsedId)
                  })
                }
              }}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            fullWidth
            disabled={input.length <= 0 || !isAlive || exportProfit.isLoading}
            type="submit"
          >
            Выгрузить
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
