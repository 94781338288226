import { useMemo, useState } from 'react'

import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useGoogleCheckAlive } from '@/services/hooks/google-sheets/useGoogleCheckAlive'
import { useGoogleExportUserInfo } from '@/services/hooks/google-sheets/useGoogleExportUserInfo'
import { useGoogleGetUserSettings } from '@/services/hooks/google-sheets/useGoogleGetUserSettings'
import { Alert, Button, FormControl, Grid, TextField } from '@mui/material'

import { AxiosErrorResponse } from '@/types'

import { GridError } from './GridError'

type ExportUserInfoProps = {
  userId: number
}

export const ExportUserInfo = ({ userId }: ExportUserInfoProps) => {
  const [from, setFrom] = useState<string>('')
  const [to, setTo] = useState<string>('')
  const [error, setError] = useState<string>('')
  const { data: userSettings, isFetching: isUserSettingsFetching } =
    useGoogleGetUserSettings(userId)

  const { data: isAliveAuth } = useGoogleCheckAlive()
  const exportUserInfo = useGoogleExportUserInfo({
    onError: (error: AxiosErrorResponse) => {
      setError(error?.response?.data?.error || error.message)
    },
  })

  const handleExportUserBalancesTransfers = () => {
    setError('')

    exportUserInfo.mutate({
      userId,
      payload: {
        // Needs Moscow time
        'datetime.from': from ? `${from}:00+03:00` : undefined,
        'datetime.to': to ? `${to}:00+03:00` : undefined,
      },
    })
  }

  const { isLoading } = exportUserInfo
  const canExportError = useMemo(() => {
    if (!userSettings) {
      return 'Не удалось загрузить настройки пользователя'
    }

    if (!userSettings.balancesTransfersSheetId) {
      return 'Не заполнен ID для экспорта'
    }

    if (!isAliveAuth) {
      return 'Необходимо сначала авторизоваться в Google API'
    }
  }, [userSettings, isAliveAuth])

  const isValidFilter = useMemo(() => {
    if (from && to) {
      return new Date(to).getTime() - new Date(from).getTime() > 0
    }

    return true
  }, [from, to])

  const canExport = !canExportError && !isLoading && isValidFilter

  return (
    <WithLoader isLoading={isUserSettingsFetching}>
      <Grid container spacing={2}>
        <GridError error={error} />
        {canExportError && (
          <Grid item xs={12}>
            <Alert severity="info">{canExportError}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  disabled={isLoading}
                  InputLabelProps={{ shrink: true }}
                  label="Дата (от)"
                  type="datetime-local"
                  value={from}
                  onChange={event => setFrom(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  disabled={isLoading}
                  InputLabelProps={{ shrink: true }}
                  label="Дата (до)"
                  type="datetime-local"
                  value={to}
                  onChange={event => setTo(event.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={!canExport}
            onClick={handleExportUserBalancesTransfers}
          >
            Экспорт балансов / переводов
          </Button>
        </Grid>
      </Grid>
    </WithLoader>
  )
}
