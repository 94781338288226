import { Cabinet } from '@/services/api/cabinet'
import { PlayerRoomSettingsListRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export type UseGetPlayerRoomSettingsPayload = {
  userId: number
  params?: any
}

export type UseGetPlayerRoomSettingsListResponse = PlayerRoomSettingsListRo

export const useGetPlayerRoomsSettingsList = (
  payload: UseGetPlayerRoomSettingsPayload,
  props?: UseQueryOptions<
    UseGetPlayerRoomSettingsListResponse,
    AxiosErrorResponse
  >,
) => {
  return useQuery<UseGetPlayerRoomSettingsListResponse, AxiosErrorResponse>(
    [
      'user',
      'cabinet',
      'player',
      'rooms-settings-list',
      payload.userId,
      payload.params,
    ],
    async () => {
      const response = await Cabinet.getPlayerRoomSettingsList(
        payload.userId,
        payload.params,
      )
      return response.data as UseGetPlayerRoomSettingsListResponse
    },
    props,
  )
}
