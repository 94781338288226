import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'
import { UserGroups } from '@/services/api/user-groups'

type UseDeleteUserGroupPayload = {
  id: number;
}

export const useDeleteUserGroup = (props?: UseMutationOptions<boolean, AxiosErrorResponse, UseDeleteUserGroupPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteUserGroupPayload>(
    ['user-groups'],
    async (payload: UseDeleteUserGroupPayload) => {
      const response = await UserGroups.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-groups'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
