import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal'
import { ReviewModal } from '@/components/Modals/ReviewModal/ReviewModal'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableUser } from '@/components/Tables/columns/TableUser/TableUser'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { AddEntityButton } from '@/components/Tables/toolbar/AddEntityButton'
import {
  REVIEW_COMMENT_LOYALTY_FIELD,
  REVIEW_COMMENT_RECOMMENDATIONS_FIELD,
  REVIEW_COMMENT_TECHNICAL_FIELD,
  REVIEW_PROMOTION_OPINION_FIELD,
  REVIEW_SCORE_MENTAL_FIELD,
  REVIEW_SCORE_SELECT_FIELD,
  REVIEW_SCORE_TECHNICAL_FIELD,
  REVIEW_USER_RANK_HIDDEN_FIELD,
} from '@/constants'
import { useSnackBar } from '@/context/SnackbarContext'
import { ReviewSingleRo } from '@/services/data-contracts'
import { useDeleteReview } from '@/services/hooks/reviews/useDeleteReview'
import { useGetReviews } from '@/services/hooks/reviews/useGetReviews'
import { getFieldLabel } from '@/utils/getFieldLabel'
import { getFieldName } from '@/utils/getFieldName'
import MUIDataTable from 'mui-datatables'
import { useModal } from 'mui-modal-provider'

import { ReviewType } from '@/types'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'

export interface ReviewsTableProps {
  type: ReviewType
  playerId: number
  canAdd: boolean
  canEdit: boolean
  canDelete: boolean
  tableBodyHeight?: string
}

const SORT_DEFAULT = 'createdAt:DESC'

const TABLE_FIELDS = [
  REVIEW_COMMENT_TECHNICAL_FIELD,
  REVIEW_COMMENT_LOYALTY_FIELD,
  REVIEW_COMMENT_RECOMMENDATIONS_FIELD,
  REVIEW_SCORE_TECHNICAL_FIELD,
  REVIEW_SCORE_MENTAL_FIELD,
  REVIEW_SCORE_SELECT_FIELD,
  REVIEW_PROMOTION_OPINION_FIELD,
  REVIEW_USER_RANK_HIDDEN_FIELD,
]

export const ReviewsTable = ({
  canAdd,
  canEdit,
  canDelete,
  playerId,
  type,
  tableBodyHeight,
}: ReviewsTableProps) => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()

  const { showModal } = useModal()
  const { showSnackBar } = useSnackBar()

  const { data: reviews, isLoading: isLoadingReviews } = useGetReviews({
    ...tablePagination.queryParams,
    sort: SORT_DEFAULT,
    'player.equal': playerId,
    'type.equal': type,
  })

  const deleteReview = useDeleteReview({
    onSuccess: () => showSnackBar('Отзыв успешно удален', 'success'),
    onError: () => showSnackBar('Ошибка при удалении отзыва', 'error'),
  })

  const handleOpenCreateReviewModal = () => {
    const modal = showModal(ReviewModal, {
      mode: 'create',
      type: 'monthly',
      playerId,
      reviewId: undefined,
      onSuccess: () => {
        showSnackBar('Успешно создан отзыв', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при создании отзыва', 'error')
      },
    })
  }

  const handleOpenEditReviewModal = (row: ReviewSingleRo) => {
    const modal = showModal(ReviewModal, {
      mode: 'edit',
      type: 'monthly',
      playerId,
      reviewId: row.id,
      onSuccess: () => {
        showSnackBar('Успешно отредактирован отзыв', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при редактировании отзыва', 'error')
      },
    })
  }

  const handleOpenCreateIntermediateReviewModal = () => {
    const modal = showModal(ReviewModal, {
      mode: 'create',
      type: 'intermediate',
      playerId,
      reviewId: undefined,
      onSuccess: () => {
        showSnackBar('Успешно создан промежуточный отзыв', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при создании промежуточного отзыва', 'error')
      },
    })
  }

  const handleOpenEditIntermediateReviewModal = (row: ReviewSingleRo) => {
    const modal = showModal(ReviewModal, {
      mode: 'edit',
      type: 'intermediate',
      playerId,
      reviewId: row.id,
      onSuccess: () => {
        showSnackBar('Успешно отредактирован промежуточный отзыв', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при редактировании промежуточного отзыва', 'error')
      },
    })
  }

  const handleDeleteModal = (row: ReviewSingleRo) => {
    const modal = showModal(ConfirmModal, {
      headerText: `Точно удалить отзыв "${row.name}"?`,
      buttons: {
        confirm: {
          children: 'Удалить',
          onClick: () => {
            deleteReview.mutate({ id: row.id })
            modal.hide()
          },
        },
        cancel: {
          children: 'Отменить',
          onClick: () => {
            modal.hide()
          },
        },
      },
    })
  }

  const [data, count] = reviews ? [reviews.data, reviews.count] : [[], 0]

  const titleMap: Record<ReviewType, string> = {
    monthly: 'Отзывы на игрока',
    intermediate: 'Промежуточные отзывы на игрока',
  }

  const createHandlerMap: Record<ReviewType, () => void> = {
    monthly: handleOpenCreateReviewModal,
    intermediate: handleOpenCreateIntermediateReviewModal,
  }

  const editHandlerMap: Record<ReviewType, (row: ReviewSingleRo) => void> = {
    monthly: handleOpenEditReviewModal,
    intermediate: handleOpenEditIntermediateReviewModal,
  }

  const { TableMenuColumn } = useMenu<ReviewSingleRo>({
    data,
    options: [
      ...(canEdit
        ? [
            {
              id: 'edit',
              label: 'Редактировать',
              onClick: editHandlerMap[type],
            },
          ]
        : []),
      ...(canDelete
        ? [{ id: 'delete', label: 'Удалить', onClick: handleDeleteModal }]
        : []),
    ],
  })

  return (
    <MUIDataTable
      title={titleMap[type]}
      columns={[
        {
          name: 'createdAt',
          label: 'Создан',
          options: {
            customBodyRender: TableCreatedAt,
          },
        },
        {
          name: 'createdBy.username',
          label: 'Создал',
          options: {
            customBodyRender: TableUser,
          },
        },
        ...TABLE_FIELDS.map(field => ({
          name: getFieldName(field),
          label: getFieldLabel(field, 'review'),
        })),
        {
          name: '__actions__',
          label: ' ',
          options: {
            customBodyRenderLite: TableMenuColumn,
          },
        },
      ]}
      data={data}
      options={{
        ...tableDefault.options,
        ...tablePagination.options,
        count,
        tableBodyHeight,
        textLabels: createTextLabels({
          isLoading: isLoadingReviews,
          textLabels: {
            body: {
              noMatch: 'Отзывов не найдено',
            },
          },
        }),
        ...(canAdd && {
          customToolbar: () => (
            <AddEntityButton onClick={createHandlerMap[type]} />
          ),
        }),
      }}
      components={tableDefault.components}
    />
  )
}
