import * as yup from 'yup'

export type ManuallyEnterTournamentSchema = {
  room: {
    id: number
  }
  date: string
  startTime: string
  title: string
  buyIn: number
  screenshot?: {
    id: number
  }
  studentComment?: string
}

export const manuallyEnterTournamentSchema = yup.object({
  room: yup
    .object({
      id: yup.string().required('Рум обязателен'),
    })
    .required('Рум обязателен'),
  date: yup.string().required('Введите дату проведения турнира'),
  startTime: yup.string().required('Введите время начала проведения турнира'),
  title: yup
    .string()
    .required('Введите название турнира')
    .max(500, 'Название турнира не может быть больше 500 символов'),
  buyIn: yup
    .number()
    .typeError('Введите бай-ин')
    .required('Введите бай-ин')
    .positive('Бай-ин не может быть отрицательным'),
  screenshot: yup
    .object({
      id: yup.mixed().required('Скриншот обязателен'),
    })
    .required('Скриншот обязателен'),
  studentComment: yup
    .string()
    .optional()
    .max(500, 'Комментарий не может быть больше 500 символов'),
})
