import { GoogleSheets } from '@/services/api/google-sheets'
import { ExportUserInfoDto } from '@/services/data-contracts'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export type UseGoogleExportUserInfoPayload = {
  userId: number
  payload: ExportUserInfoDto
}

export const useGoogleExportUserInfo = (
  props?: UseMutationOptions<
    void,
    AxiosErrorResponse,
    UseGoogleExportUserInfoPayload
  >,
) => {
  return useMutation<void, AxiosErrorResponse, UseGoogleExportUserInfoPayload>(
    ['google-sheets', 'export-user-info'],
    async ({ userId, payload }: UseGoogleExportUserInfoPayload) => {
      const response = await GoogleSheets.exportUserInfo(userId, payload)
      return response.data
    },
    props,
  )
}
