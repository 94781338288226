import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { AdminUsersTotalTable } from '@/components/Containers/AdminUsersTotalTable/AdminUsersTotalTable'

export const AdminUsersTotalPage = () => {
  return (
    <DashboardLayout>
      <AdminUsersTotalTable />
    </DashboardLayout>
  )
}
