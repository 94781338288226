import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { FundsRequestEditDto, FundsRequestRo } from '@/services/data-contracts'
import { FundsRequests } from '@/services/api/funds-requests'
import { AxiosErrorResponse } from '@/types'

type UseEditFundsRequestPayload = {
  id: number;
} & FundsRequestEditDto;

export const useEditFundsRequest = (
  props?: UseMutationOptions<FundsRequestRo, AxiosErrorResponse, UseEditFundsRequestPayload>,
) => {
  const queryClient = useQueryClient()

  return useMutation<FundsRequestRo, AxiosErrorResponse, UseEditFundsRequestPayload>(
    ['funds-requests'],
    async ({ id, ...payload }: UseEditFundsRequestPayload) => {
      const response = await FundsRequests.edit(id, payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['funds-requests'])
        props?.onSuccess?.(...args)
      },
    })
}
