import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { RoomNetworks } from '@/services/api/room-networks.ts'
import { AxiosErrorResponse } from '@/types'

type UseDeleteRoomNetworkPayload = {
  id: number;
}

export const useDeleteRoomNetwork = (props?: UseMutationOptions<boolean, AxiosErrorResponse, UseDeleteRoomNetworkPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteRoomNetworkPayload>(
    ['room-networks'],
    async (payload: UseDeleteRoomNetworkPayload) => {
      const response = await RoomNetworks.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['rooms'])
        await queryClient.invalidateQueries(['room-networks'])
        props?.onSuccess?.(...args)
      },
    })
}
