import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import { DefaultLayout } from '@/layouts/DefaultLayout/DefaultLayout'

export const ErrorPage = () => {
  const navigate = useNavigate()

  return (
    <DefaultLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mx: 'auto',
          my: 'auto',
          width: '100%',
          maxWidth: '600px',
          minWidth: '300px',
        }}
      >
        <Typography variant="h2">
          Что-то пошло не так
        </Typography>
        <Typography variant="h6">Неизвестная ошибка</Typography>
        <Button onClick={() => () => navigate(-1)} variant="contained">Перейти назад</Button>
      </Box>
    </DefaultLayout>
  )
}
