import { useNavigate } from 'react-router-dom'

import { UsersTable } from '@/components/Containers/UsersTable/UsersTable'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { Box, Button, Grid } from '@mui/material'

export const UsersPage = () => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/cabinet/users/rooms')
  }
  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={15}>
          <Box mb={2} textAlign="right">
            <Button variant="contained" onClick={handleNavigate}>
              Пользователи и румы
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UsersTable />
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}
