import { FundsTransferCreateToFundsRequestDto } from '@/services/data-contracts'
import * as yup from 'yup'

export type CreateTransferToFundsRequestModalSchema = Omit<
  FundsTransferCreateToFundsRequestDto,
  'totalAmount'
> & {
  factAmount: number
}

export const createTransferToFundsRequestModalSchema = {
  generate: (amount: number) =>
    yup.object({
      factAmount: yup
        .number()
        .typeError('Введите фактическую сумму перевода')
        .positive('Введите положительное число')
        .required('Введите фактическую сумму перевода')
        .max(amount, `Максимум можно перевести ${amount}`),
      screenshot: yup
        .object({
          id: yup.mixed(),
        })
        .optional(),
      fundsRequest: yup.object({
        id: yup.number().required(),
      }),
      transferFee: yup
        .number()
        .transform((_, value) => {
          if (value.toString().includes(',')) {
            return +value.replace(/,/, '.')
          }
          return +value
        })
        .optional()
        .transform(value => (!value ? 0 : value)),
      comment: yup
        .string()
        .max(500, 'Комментарий не может быть больше 500 символов'),
    }),
}
