import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { ScheduledTournamentRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

import { ScheduledTournaments } from '@/services/api/scheduled-tournaments.ts'

export const useGetScheduledTournamentById = (id: number, props?: UseQueryOptions<ScheduledTournamentRo, AxiosErrorResponse>) => {
  return useQuery<ScheduledTournamentRo, AxiosErrorResponse>(
    ['scheduled-tournaments', id],
    async () => {
      const response = await ScheduledTournaments.getById(id)
      return response.data
    },
    props,
  )
}
