import { Avatar, AvatarProps } from '@mui/material'
import { stringToColor } from '@/utils/stringToColor'

type ColorAvatarProps = Omit<AvatarProps, 'src' | 'title'> & {
  src?: string | undefined
  title: string
  color?: string
}

export const ColorAvatar = ({ title, src, color, ...props }: ColorAvatarProps) => {
  return (
    <Avatar
      variant="square"
      title={title}
      imgProps={{ sx: { objectFit: 'contain', ...props?.imgProps?.sx } }}
      {...props}
      {...(src
          ? { src }
          : {
            sx: {
              backgroundColor: color || stringToColor(title),
              '&.MuiAvatar-colorDefault': {
                color: 'white',
              },
              ...props?.sx,
            },
          }
      )}
    >
      {title?.slice(0, 2)}
    </Avatar>
  )
}
