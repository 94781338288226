import { Cabinet } from '@/services/api/cabinet'
import {
  SetUserRoomSettingsDto,
  SetPlayerRoomSettingsRo,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

type UseSetPlayerRoomsSettingsPayload = {
  userId: number
} & SetUserRoomSettingsDto

export const useSetPlayerRoomsSettings = (
  props?: UseMutationOptions<
    SetPlayerRoomSettingsRo,
    AxiosErrorResponse,
    UseSetPlayerRoomsSettingsPayload
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    SetPlayerRoomSettingsRo,
    AxiosErrorResponse,
    UseSetPlayerRoomsSettingsPayload
  >(
    ['user', 'cabinet', 'admin', 'rooms-settings'],
    async ({ userId, ...payload }: UseSetPlayerRoomsSettingsPayload) => {
      const response = await Cabinet.setPlayerRoomsSettings(userId, payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        const [, variables] = args

        await queryClient.invalidateQueries([
          'user',
          'cabinet',
          'player',
          'rooms-settings',
          (variables as UseSetPlayerRoomsSettingsPayload).userId,
        ])
        await queryClient.invalidateQueries(['rooms-players-settings'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
