import { CustomSettings } from '@/services/api/custom-settings'
import { CustomSettingRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

type UseGetCustomSettingByNameParams = {
  name: string
}

export const useGetCustomSettingByName = (
  params: UseGetCustomSettingByNameParams,
  props?: UseQueryOptions<CustomSettingRo, AxiosErrorResponse>,
) => {
  return useQuery<CustomSettingRo, AxiosErrorResponse>(
    ['custom-settings', params.name],
    async () => {
      const response = await CustomSettings.getByName(params.name)
      return response.data
    },
    props,
  )
}
