export const makeFooterColumnFixed = ({
  columnWidth = 40,
  columnPosition,
  background,
}: {
  columnPosition: number
  columnWidth?: number
  background?: any
}) => {
  return {
    sx: {
      width: `${columnWidth}px`,
      whiteSpace: 'wrap',
      position: 'sticky',
      left: `${columnPosition * columnWidth * 4}px`,
      background: background,
      zIndex: 101 - columnPosition,
      borderBottom: 0,
    },
  }
}
