import React from 'react'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { UserRoleValue } from '@/constants'

interface WithRoleProps {
  roles: Array<UserRoleValue>
  children: React.ReactNode
}

export const WithRole = ({ roles, children }: WithRoleProps) => {
  const { currentUser, isError } = useCurrentUser()

  if (!roles.length) {
    return <>{children}</>
  }

  if (!currentUser?.role) {
    return null
  }

  if (isError) {
    return null
  }

  const hasValidRole = roles.includes(+currentUser.role as UserRoleValue)

  if (!hasValidRole) {
    return null
  }

  return <>{children}</>
}
