import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Users } from '@/services/api/users'
import { UsersCreateDto, UsersCreateRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useCreateUser = (props?: UseMutationOptions<UsersCreateRo, AxiosErrorResponse, UsersCreateDto>) => {
  const queryClient = useQueryClient()

  return useMutation<UsersCreateRo, AxiosErrorResponse, UsersCreateDto>(
    ['users'],
    async (params: UsersCreateDto) => {
      const response = await Users.create(params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['users'])
        props?.onSuccess?.(...args)
      },
    })
}
