import { Link } from 'react-router-dom'

import { CurrencyRate } from '@/components/Containers/CurrencyRate/CurrencyRate.tsx'
import { Logo } from '@/components/UI/Logo/Logo'
import { ThemeSwitcher } from '@/components/UI/ThemeSwitcher/ThemeSwitcher.tsx'
import { useTheme } from '@/context/ThemeContext.tsx'
import { DashboardNavigation } from '@/layouts/DashboardLayout/DashboardNavigation'
import { DashboardUser } from '@/layouts/DashboardLayout/DashboardUser'
import {
  AppBar,
  Box,
  Container,
  Divider,
  Toolbar,
  useMediaQuery,
} from '@mui/material'

export const DashboardHeader = () => {
  const { theme } = useTheme() ?? {}
  // @ts-ignore
  const lg = useMediaQuery(theme => theme.breakpoints.down('lg'))
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={
        theme === 'light'
          ? {
              backgroundColor: '#fff',
              color: 'text.primary',
              ...(lg && { overflowX: 'auto' }),
            }
          : { color: 'text.primary', ...(lg && { overflowX: 'auto' }) }
      }
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box mr={4}>
              <Link to="/cabinet">
                <Logo width={180} height={40} />
              </Link>
            </Box>
            <Box>
              <DashboardNavigation />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 0,
                ml: 'auto',
                alignItems: 'center',
              }}
            >
              <ThemeSwitcher />
              <CurrencyRate from="EUR" to="USD" view="sign-only" />
              <DashboardUser />
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Divider />
    </AppBar>
  )
}
