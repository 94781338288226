import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { ReviewRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'
import { Reviews } from '@/services/api/reviews'

export type UseGetReviewByIdPayload = {
  id: number;
}

export const useGetReviewById = (
  params: UseGetReviewByIdPayload,
  props?: UseQueryOptions<ReviewRo, AxiosErrorResponse>,
) => {
  return useQuery<ReviewRo, AxiosErrorResponse>(
    ['reviews', params],
    async () => {
      const response = await Reviews.getById(params.id)
      return response.data
    },
    props,
  )
}
