import { Box, Button, ButtonProps, Dialog, DialogActions, DialogProps, DialogTitle } from '@mui/material'

export interface ConfirmModalProps extends DialogProps {
  headerText?: string
  buttons?: {
    confirm?: ButtonProps
    cancel?: ButtonProps
  }
}

export const ConfirmModal = ({ headerText, buttons, ...props }: ConfirmModalProps) => {
  return (
    <Dialog {...props}>
      <Box p={1} maxWidth={400}>
        <DialogTitle sx={{ textAlign: 'center' }}>{headerText || 'Подтвердите действие'}</DialogTitle>
        <DialogActions>
          <Button variant="contained" color="primary" fullWidth {...buttons?.confirm}>
            {buttons?.confirm?.children || 'Подтвердить'}
          </Button>
          <Button variant="text" color="error" fullWidth{...buttons?.cancel}>
            {buttons?.cancel?.children || 'Отменить'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
