import { useFormContext } from 'react-hook-form'

import { PlayerRoomSettings } from '@/components/Containers/PlayerRoomSettings/PlayerRoomSettings'
import { PhotoUploader } from '@/components/Forms/PhotoUploader'
import { TextField } from '@/components/Forms/TextField'
import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useGetRoomById } from '@/services/hooks/rooms/useGetRoomById'
import { CurrencyHelper } from '@/utils/CurrencyHelper'
import {
  Box,
  Button,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  TextField as TextFieldMui,
  Typography,
} from '@mui/material'

import { CreateTransferModalProps } from './CreateTransferToFundsRequestModal'

interface CreateTransferModalContentProps
  extends Pick<CreateTransferModalProps, 'recipient' | 'amount'> {
  isSubmitting: boolean
}

export const CreateTransferToFundsRequestModalContent = ({
  recipient,
  amount,
  isSubmitting,
}: CreateTransferModalContentProps) => {
  const { setValue, clearErrors } = useFormContext()
  const { data: roomData, isFetching: isFetchingRoom } = useGetRoomById(
    recipient.room.id,
  )

  const room = roomData
  const isFetching = isFetchingRoom

  const currencySign = CurrencyHelper.getCurrencySign(
    room ? room.currency : null,
  )

  const handleFillAmount = () => {
    setValue('factAmount', amount)
    clearErrors('factAmount')
  }

  const isButtonDisabled = isSubmitting || isFetching

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Информация о получателе</Typography>
        </Grid>
        <WithLoader isLoading={isFetching}>
          <Grid item xs={12}>
            <TextFieldMui
              fullWidth
              disabled
              label="Требуется перевести"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySign}
                  </InputAdornment>
                ),
              }}
              value={amount}
            />
          </Grid>
          {room && (
            <Grid item xs={12}>
              <TextFieldMui
                label="Рум"
                fullWidth
                value={room.name}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ColorAvatar
                        src={room?.avatar?.location}
                        title={room.name}
                        sx={{ width: '25px', height: '25px', fontSize: '12px' }}
                      />
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PlayerRoomSettings
              userId={recipient.id}
              roomId={recipient.room.id}
            />
          </Grid>
        </WithLoader>
      </Grid>
      <Box my={2}>
        <Divider />
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h5">Заполните данные о переводе</Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'none' }}>
            <TextField
              label="ID запроса"
              name="fundsRequest.id"
              disabled={true}
            />
          </div>
          <TextField
            label="Сумма (факт)"
            name="factAmount"
            textFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySign}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" onClick={handleFillAmount}>
                      Вся сумма
                    </Button>
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            label="Размер комиссии"
            name="transferFee"
            textFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySign}
                  </InputAdornment>
                ),
              },
            }}
          />
          <PhotoUploader
            label="Скриншот (JPG, PNG)"
            name="screenshot.id"
            buttonProps={{ color: 'secondary' }}
          />
          <TextField
            label="Комментарий"
            name="comment"
            textFieldProps={{
              multiline: true,
              rows: 1,
            }}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isButtonDisabled}
          >
            Отправить
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  )
}
