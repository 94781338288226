import React, { createContext, useContext } from 'react'

import { Alert, AlertColor, Snackbar } from '@mui/material'

import { AxiosErrorResponse } from '@/types'

type ShowSnackBar = (
  text: string,
  typeColor: AlertColor,
  err?: AxiosErrorResponse,
) => void

type SnackBarContextActions = {
  showSnackBar: ShowSnackBar
}

const SnackBarContext = createContext({} as SnackBarContextActions)

interface SnackBarContextProviderProps {
  children: React.ReactNode
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<string>('')
  const [typeColor, setTypeColor] = React.useState<AlertColor>('info')

  const showSnackBar: ShowSnackBar = (text, color = 'info', err) => {
    const errorMessage = err?.response
      ? [
          `Ошибка: ${err?.response?.data?.message}.`,
          ...(err?.response?.data?.message !== err?.response?.data?.error &&
          err?.response?.data?.error
            ? [`("${err?.response?.data?.error}")`]
            : []),
        ].join(' ')
      : err?.message

    setMessage([text, ...(errorMessage ? [`(${errorMessage})`] : [])].join(' '))
    setTypeColor(color)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setTypeColor('info')
  }

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={{
          success: 2000,
          error: 7000,
          warning: 4000,
          info: 4000,
        }?.[typeColor] || 2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert severity={typeColor}>{message}</Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  )
}

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext)

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider')
  }

  return context
}

export { SnackBarProvider, useSnackBar }
