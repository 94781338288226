import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { RoomsTable } from '@/components/Containers/RoomsTable/RoomsTable'
import { RoomNetworksTable } from '@/components/Containers/RoomNetworksTable/RoomNetworksTable.tsx'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'

export const RoomsPage = () => {
  return (
    <DashboardLayout>
      <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="room-networks-content"
          id="room-networks"
        >
          Открыть список "Сети румов"
        </AccordionSummary>
        <AccordionDetails>
          <RoomNetworksTable />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Открыть список "Румы"
        </AccordionSummary>
        <AccordionDetails>
          <RoomsTable />
        </AccordionDetails>
      </Accordion>
    </DashboardLayout>
  )
}
