export const tournamentFlags = {
  T: {
    value: 'T',
    title: 'Turbo',
    style: {
      backgroundColor: '#CC3300',
      color: '#FFFFFF',
    },
  },
  ST: {
    value: 'H',
    title: 'Super Turbo',
    style: {
      backgroundColor: '#CC3300',
      color: '#FFFFFF',
    },
  },
  B: {
    value: 'KO',
    title: 'Bounty',
    style: {
      backgroundColor: '#0033FF',
      color: '#FFFFFF',
    },
  },
  '6MX': {
    value: '6',
    title: '6 max',
    style: {
      backgroundColor: '#33CC66',
      color: '#FFFFFF',
    },
  },
  D: {
    value: 'D',
    title: 'Deep Stack',
    style: {
      backgroundColor: '#CCCCCC',
      color: '#000000',
    },
  },
  R: {
    value: 'R',
    title: 'Rebuy',
    style: {
      backgroundColor: '#99CCFF',
      color: '#000000',
    },
  },
  K: {
    value: 'KL',
    title: 'Killer',
    style: {
      backgroundColor: '#FF9933',
      color: '#000000',
    },
  },
}
