import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar'
import {
  Box,
  Chip,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { SelectInputProps } from '@mui/material/Select/SelectInput'

export interface SelectProps {
  id?: string
  name: string
  label: string
  formItemProps?: FormControlProps
  options: SelectOption[]
  multiple?: boolean
  selectProps?: MuiSelectProps
  enableAvatar?: boolean
}

export type SelectOption = {
  id: string | number
  label: string
  disabled?: boolean
  tooltip?: string
  avatar?: string
}

export const Select = ({
  id,
  label,
  formItemProps,
  selectProps,
  multiple = false,
  name,
  options,
  enableAvatar,
}: SelectProps) => {
  const { watch, setValue, register, getFieldState, formState } =
    useFormContext()
  const { onBlur, name: registerName, ref } = register(name)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const realValue = watch(name as any)
  const field = getFieldState(name, formState)
  const isError = Boolean(field.error)

  const handleChange: SelectInputProps['onChange'] = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue(name, event.target.value as any)
    },
    [name, setValue],
  )

  const helperText = field.error?.message

  const optionsMap = useMemo(() => {
    return options.reduce<Record<SelectOption['id'], SelectOption>>(
      (acc, option) => {
        acc[option.id] = option
        return acc
      },
      {},
    )
  }, [options])

  return (
    <FormControl {...formItemProps} fullWidth error={isError}>
      <InputLabel shrink={true}>{label || ''}</InputLabel>
      <MuiSelect
        value={realValue || (multiple ? [] : '')}
        name={registerName}
        ref={ref}
        onBlur={onBlur}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        multiple={multiple}
        {...selectProps}
        aria-label={label}
        input={<OutlinedInput notched label={label} />}
        size={selectProps?.size || 'medium'}
        {...(multiple && {
          renderValue: (selected: number[]) => {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: number) => {
                  const option = optionsMap[value]

                  return (
                    <Chip
                      key={value}
                      label={option?.label || value}
                      avatar={
                        enableAvatar ? (
                          <ColorAvatar
                            src={option?.avatar}
                            title={option?.label}
                            variant="circular"
                          />
                        ) : undefined
                      }
                      variant="outlined"
                    />
                  )
                })}
              </Box>
            )
          },
        })}
      >
        {options.map(option => {
          const content = (
            <MenuItem
              key={option.id}
              value={option.id}
              disabled={option.disabled || false}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {enableAvatar && (
                  <ColorAvatar
                    variant="rounded"
                    src={option.avatar}
                    title={option.label}
                    sx={{
                      width: '20px',
                      height: '20px',
                      fontSize: '11px',
                    }}
                  />
                )}
                <Typography>{option.label}</Typography>
              </Box>
            </MenuItem>
          )

          if (option.tooltip) {
            return (
              <Tooltip title={option.tooltip} key={option.id}>
                {content}
              </Tooltip>
            )
          }

          return content
        })}
      </MuiSelect>
      {helperText && (
        <FormHelperText id={`${id || name}-text`} sx={{ ml: 0 }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
