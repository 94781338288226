import { Form } from '@/components/Forms/Form'
import {
  manuallyEnterTournamentSchema,
  ManuallyEnterTournamentSchema,
} from '@/components/Modals/CreateGameRequestModal/schema/manuallyEnterTournament.schema'
import { GameRequestCreateDto } from '@/services/data-contracts'
import { useCreateGameRequest } from '@/services/hooks/game-requests/useCreateGameRequest'
import { Alert, Button, DialogTitle, Divider } from '@mui/material'

import { AxiosErrorResponse } from '@/types'

import { ManuallyEnterTournamentContent } from './ManuallyEnterTournamentContent'

interface ManuallyEnterTournamentStepProps {
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
  onToggleStep?: () => void
}

/**
 * Шаг ручного заполнения запроса на игру
 * Выводится, если не удалось найти турнир среди нужных.
 */
export const ManuallyEnterTournamentStep = ({
  onSuccess,
  onError,
  onToggleStep,
}: ManuallyEnterTournamentStepProps) => {
  const createGameRequest = useCreateGameRequest({
    onSuccess,
    onError,
  })

  const handleSubmit = (data: ManuallyEnterTournamentSchema) => {
    createGameRequest.mutate({
      room: {
        id: +data.room.id,
      },
      buyIn: +data.buyIn,
      startDatetime: new Date([data.date, data.startTime].join(' ')),
      title: data.title,
      ...(data.studentComment && {
        studentComment: data.studentComment,
      }),
      ...(data.screenshot?.id && {
        screenshot: {
          id: +data.screenshot.id,
        },
      }),
    } as GameRequestCreateDto)
  }

  return (
    <>
      <DialogTitle>Cоздание нового запроса на игру</DialogTitle>
      <Divider />
      <Alert
        severity="info"
        action={
          <Button color="inherit" size="small" onClick={onToggleStep}>
            Назад
          </Button>
        }
      >
        Вернуться к выбору турнира из базы
      </Alert>
      <Divider />
      <Form<ManuallyEnterTournamentSchema>
        onSubmit={handleSubmit}
        validationSchema={manuallyEnterTournamentSchema}
      >
        <ManuallyEnterTournamentContent
          isSubmitting={createGameRequest.isLoading}
        />
      </Form>
    </>
  )
}
