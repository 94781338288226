export const prettifyTournamentName = (tournamentName: string) => {
  tournamentName = tournamentName.trim()

  if (tournamentName.startsWith('%')) {
    tournamentName = tournamentName.slice(1)
  }

  if (tournamentName.endsWith('%')) {
    tournamentName = tournamentName.slice(0, -1)
  }

  tournamentName = tournamentName.trim()

  return tournamentName;
}
