import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { RoomNetworks } from '@/services/api/room-networks.ts'
import { RoomNetworkEditDto, RoomNetworkRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

type UseEditRoomPayload = {
  id: number;
} & RoomNetworkEditDto

export const useEditRoomNetwork = (props?: UseMutationOptions<RoomNetworkRo, AxiosErrorResponse, UseEditRoomPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<RoomNetworkRo, AxiosErrorResponse, UseEditRoomPayload>(
    ['room-networks'],
    async (payload: UseEditRoomPayload) => {
      const { id, ...params } = payload
      const response = await RoomNetworks.patch(id, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['rooms'])
        await queryClient.invalidateQueries(['room-networks'])
        props?.onSuccess?.(...args)
      },
    })
}
