import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Tournaments } from '@/services/api/tournaments'
import { TournamentsControllerFindAllParams, TournamentsDataRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGetTournaments = (
  params?: TournamentsControllerFindAllParams,
  props?: UseQueryOptions<TournamentsDataRo, AxiosErrorResponse>,
) => {
  return useQuery<TournamentsDataRo, AxiosErrorResponse>(
    ['tournaments', params],
    async () => {
      const response = await Tournaments.get(params)
      return response.data
    },
    props,
  )
}
