import { PlayerRoomSettingsListRo } from '@/services/data-contracts.ts'
import { BalanceTreeRo } from '@/services/hooks/cabinet/player/useGetPlayerRoomsBalancesByToken.ts'

export const roomBalances = (
  roomsSettings: PlayerRoomSettingsListRo | undefined,
  playerRoomsBalances: BalanceTreeRo | undefined,
) => {
  if (!roomsSettings || !playerRoomsBalances) {
    return []
  }

  if (!roomsSettings?.data) {
    return []
  }

  return roomsSettings.data
    .filter(setting => setting.nickname || setting.transferInfo)
    .map(roomSetting => {
      const balances = playerRoomsBalances[roomSetting.room.id]

      return {
        room: {
          id: roomSetting.room.id,
          name: roomSetting.room.name,
          avatar: roomSetting.room?.avatar?.location,
          currency: roomSetting.room.currency,
        },
        amount: balances?.amount || '',
        settings: {
          nickname: roomSetting.nickname,
          transferInfo: roomSetting.transferInfo,
        },
      }
    })
}

export const maxRoomNameLength = (
  roomsSettings: PlayerRoomSettingsListRo | undefined,
) => {
  if (!roomsSettings) {
    return 0
  }

  return roomsSettings.data?.reduce((max, current) => {
    return current.room.name.length > max ? current.room.name.length : max
  }, 0)
}
