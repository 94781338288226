import { ScheduledTournaments } from '@/services/api/scheduled-tournaments'
import {
  ScheduledTournamentsControllerFindAllParams,
  ScheduledTournamentsDataRo,
} from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useGetScheduledTournaments = (
  params?: ScheduledTournamentsControllerFindAllParams,
  props?: UseQueryOptions<ScheduledTournamentsDataRo, AxiosErrorResponse>,
) => {
  return useQuery<ScheduledTournamentsDataRo, AxiosErrorResponse>(
    ['scheduled-tournaments', params],
    async () => {
      const response = await ScheduledTournaments.get(params)
      return response.data
    },
    props,
  )
}
