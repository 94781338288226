import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import {
  RoomModal,
  RoomModalProps,
} from '@/components/Modals/RoomModal/RoomModal'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle'
import { TableMultipleChip } from '@/components/Tables/columns/TableMultipleChip/TableMultipleChip'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import {
  TableSortItem,
  useTableSort,
} from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { AddEntityButton } from '@/components/Tables/toolbar/AddEntityButton'
import {
  RoomCategoryEnum,
  roomCategoryMap,
  RoomFeatureEnum,
  roomFeatureMap,
} from '@/constants'
import { useSnackBar } from '@/context/SnackbarContext'
import { RoomSingleRo } from '@/services/data-contracts'
import { useDeleteRoom } from '@/services/hooks/rooms/useDeleteRoom'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms'
import { stringToColor } from '@/utils/stringToColor'
import MUIDataTable from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

export const RoomsTable = () => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()
  const { defaultSort } = useGetSort()
  const tableSort = useTableSort(defaultSort as TableSortItem, item => {
    localStorage.setItem('roomSort', item.join(':'))
    return [item]
  })
  const { showSnackBar } = useSnackBar()
  const { showModal } = useModal()
  const { data: rooms, isFetching: isRoomsFetching } = useGetRooms({
    ...tablePagination.queryParams,
    ...tableSort.queryParams,
  })

  const [data, count] = rooms ? [rooms.data, rooms.count] : [[], 0]

  const deleteRoom = useDeleteRoom({
    onSuccess: () => showSnackBar('Рум успешно удален', 'success'),
    onError: () => showSnackBar('Ошибка при удалении рума', 'error'),
  })

  const handleCreateRoom = () => {
    const modal: ShowFnOutput<RoomModalProps> = showModal(RoomModal, {
      mode: 'create',
      roomId: undefined,
      onSuccess: () => {
        showSnackBar('Рум успешно создан', 'success')
        modal.hide()
      },
      onError: () => showSnackBar('Ошибка при создании рума', 'error'),
    })
  }

  const handleEditRoom = (row: RoomSingleRo) => {
    const modal: ShowFnOutput<RoomModalProps> = showModal(RoomModal, {
      roomId: row.id,
      mode: 'edit',
      onSuccess: () => {
        showSnackBar('Рум успешно обновлен', 'success')
        modal.hide()
      },
      onError: () => showSnackBar('Ошибка при редактировании рума', 'error'),
    })
  }

  const handleDeleteRoom = (row: RoomSingleRo) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить рум ${row.name}?`,
      buttons: {
        confirm: {
          onClick: () => {
            deleteRoom.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const { TableMenuColumn } = useMenu<RoomSingleRo>({
    data,
    options: [
      { id: 'edit', label: 'Редактировать', onClick: handleEditRoom },
      { id: 'delete', label: 'Удалить', onClick: handleDeleteRoom },
    ],
  })

  return (
    <MUIDataTable
      title="Румы"
      data={data}
      columns={[
        {
          name: 'id',
          label: 'ID',
        },
        {
          name: 'network.name',
          label: 'Сеть румов',
          options: {
            sort: false,
            customBodyRender: (value: string) => {
              if (!value) {
                return 'Не указана'
              }

              return value
            },
          },
        },
        {
          name: 'name',
          label: 'Рум',
          options: {
            customBodyRender: (value, meta) => {
              const row = data[meta.rowIndex]

              return (
                <TableColumn minWidth={70}>
                  <TableImageTitle title={value} src={row?.avatar?.location} />
                </TableColumn>
              )
            },
          },
        },
        {
          name: 'currency',
          label: 'Валюта',
        },
        {
          name: 'sort',
          label: 'Сортировка',
        },
        {
          name: 'category',
          label: 'Категория',
          options: {
            sort: false,
            customBodyRender: (value: RoomCategoryEnum[]) => {
              if (!value.length) {
                return 'Категории не указаны'
              }

              return (
                <TableMultipleChip
                  tableColumnProps={{ width: 400 }}
                  items={value.map((category: RoomCategoryEnum) => {
                    const label = roomCategoryMap[category] || category

                    return {
                      id: category,
                      label,
                      avatar: {
                        title: label.toUpperCase(),
                        color: stringToColor(label),
                      },
                    }
                  })}
                />
              )
            },
          },
        },
        {
          name: 'features',
          label: 'Фичи',
          options: {
            sort: false,
            customBodyRender: (value: RoomFeatureEnum[]) => {
              if (!value.length) {
                return 'Фичи не указаны'
              }

              return (
                <TableMultipleChip
                  tableColumnProps={{ width: 400 }}
                  items={value.map((category: RoomFeatureEnum) => {
                    const label = roomFeatureMap[category] || category

                    return {
                      id: category,
                      label,
                      avatar: {
                        title: label.toUpperCase(),
                        color: stringToColor(label),
                      },
                    }
                  })}
                />
              )
            },
          },
        },
        {
          name: '__actions__',
          label: ' ',
          options: {
            customBodyRenderLite: TableMenuColumn,
          },
        },
      ]}
      options={{
        ...tableDefault.options,
        ...tablePagination.options,
        ...tableSort.options,
        count,
        tableBodyHeight: '700px',
        textLabels: createTextLabels({
          isLoading: isRoomsFetching,
          textLabels: {
            body: {
              noMatch: 'Румов не найдено',
            },
          },
        }),
        customToolbar: () => <AddEntityButton onClick={handleCreateRoom} />,
      }}
      components={tableDefault.components}
    />
  )
}
