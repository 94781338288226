import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'

interface VisibleIfProps {
  names: string[]
  condition: (values: unknown[]) => boolean
  children: ReactElement
}

export const VisibleIf = ({ names, condition, children }: VisibleIfProps) => {
  const { watch } = useFormContext()

  const values = watch(names)

  return condition(values)
    ? children
    : null
}
