import { useState } from 'react'

import { TextSettingPreview } from '@/components/CustomSettings/templates/TextCustomSetting/TextSettingPreview'
import { Form } from '@/components/Forms/Form'
import { TextField } from '@/components/Forms/TextField'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { SMALL_INT_MAX_RANGE } from '@/constants'
import { CustomSettingRo } from '@/services/data-contracts'
import { useGetCustomSettingByName } from '@/services/hooks/custom-settings/useGetCustomSettingByName'
import { useSetCustomSetting } from '@/services/hooks/custom-settings/useSetCustomSetting'
import { Box, Button, Grid, Typography } from '@mui/material'
import * as yup from 'yup'

import {
  CustomSettingTypeEnum,
  TextSettingProps,
  TextSettingValues,
} from '@/components/CustomSettings/types'

const validationSchema = yup.object({
  value: yup
    .object({
      title: yup
        .string()
        .transform(value => (!value ? '' : value))
        .optional()
        .max(255, 'Не более 255 символов')
        .nullable(),
      content: yup
        .string()
        .transform(value => (!value ? '' : value))
        .optional()
        .max(SMALL_INT_MAX_RANGE, `Не более ${SMALL_INT_MAX_RANGE} символов`)
        .nullable(),
    })
    .typeError('Заполните значения')
    .required('Заполните значения'),
})

export const TextSetting = ({ name, title }: TextSettingProps) => {
  const [activated, setActivated] = useState(false)

  const { data, isLoading: isLoadingSetting } = useGetCustomSettingByName(
    { name },
    { enabled: activated },
  )
  const setCustomSetting = useSetCustomSetting()

  const handleSubmit = (value: TextSettingValues) => {
    setCustomSetting.mutate({
      name,
      value: {
        title: value.title,
        content: value.content,
        type: CustomSettingTypeEnum.Text,
      },
    })
  }

  const initialValue = (data as CustomSettingRo)?.value

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>{title}</Typography>
      </Grid>
      {activated ? (
        <WithLoader isLoading={isLoadingSetting}>
          <Grid item xs={12}>
            <Form<TextSettingValues>
              initialValues={initialValue}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <TextField
                disabled={setCustomSetting.isLoading}
                name="title"
                label="Заголовок"
                placeholder="Введите заголовок"
              />
              <TextField
                disabled={setCustomSetting.isLoading}
                name="content"
                label="Контент"
                placeholder="Введите контент"
                textFieldProps={{
                  multiline: true,
                  type: 'text',
                  rows: 10,
                }}
              />
              <TextSettingPreview />
              <Box mt={2}>
                <Button
                  disabled={setCustomSetting.isLoading}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Сохранить
                </Button>
              </Box>
            </Form>
          </Grid>
        </WithLoader>
      ) : (
        <Box mt={2}>
          <Button
            onClick={() => setActivated(true)}
            variant="contained"
            size="small"
          >
            Редактировать
          </Button>
        </Box>
      )}
    </Grid>
  )
}
