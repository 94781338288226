import { GoogleSheets } from '@/services/api/google-sheets'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export type UseGoogleExportProfit = {
  googleSheetId: string
}

export const useGoogleExportProfit = (
  props?: UseMutationOptions<void, AxiosErrorResponse, UseGoogleExportProfit>,
) => {
  return useMutation<void, AxiosErrorResponse, UseGoogleExportProfit>(
    ['google-sheets', 'export-profit'],
    async ({ googleSheetId }: UseGoogleExportProfit) => {
      const response = await GoogleSheets.exportProfit(googleSheetId)
      return response.data
    },
    props,
  )
}
