import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { GoogleSheets } from '@/services/api/google-sheets'
import { AxiosErrorResponse } from '@/types'

export const useGoogleCheckAlive = (props?: UseQueryOptions<boolean, AxiosErrorResponse>) => {
  return useQuery<boolean, AxiosErrorResponse>(
    ['google-sheets', 'authorize', 'check-alive'],
    async () => {
      const response = await GoogleSheets.checkAlive()
      return response.data
    },
    props,
  )
}
