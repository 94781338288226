import UsersRoomsSettingsTable from '@/components/Containers/UsersRoomsSettingsTable/UsersRoomsSettingsTable.tsx'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout.tsx'
import { Grid } from '@mui/material'

const UsersRoomsSettingsPage = () => {
  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={12}>
          <UsersRoomsSettingsTable />
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default UsersRoomsSettingsPage
