export const useGetSort = () => {
  const defaultSort = localStorage.getItem('roomSort')?.split(':') || [
    'sort',
    'ASC',
  ]

  const defaultSortString =
    localStorage.getItem('roomSort') || ['sort', 'ASC'].join(':')

  return { defaultSort, defaultSortString }
}
