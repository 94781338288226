import {
  RoomBalanceTreeRo,
  PlayerRoomSettingsRo,
  SetUserRoomBalanceDto,
  SetUserRoomSettingsDto,
  SetPlayerRoomSettingsRo,
  UserPlayerFundsRo,
  PlayersRoomsSettingsRo,
  UsersControllerGetParams,
  PlayerRoomSettingsListRo,
} from '@/services/data-contracts'
import { AxiosResponse } from 'axios'

import { Request } from '../Request'

export const Cabinet = {
  getPlayerRoomsBalancesByToken: async () => {
    return Request.get<void, AxiosResponse<RoomBalanceTreeRo>>(
      '/cabinet/player/rooms-balances/by-token',
    )
  },
  setPlayerRoomsBalancesByToken: async (params: SetUserRoomBalanceDto) => {
    return Request.post<
      SetUserRoomBalanceDto,
      AxiosResponse<RoomBalanceTreeRo>
    >('/cabinet/player/rooms-balances/by-token', params)
  },
  setPlayerRoomsBalancesById: async (
    userId: number,
    params: SetUserRoomBalanceDto,
  ) => {
    return Request.post<
      SetUserRoomBalanceDto,
      AxiosResponse<RoomBalanceTreeRo>
    >(`/cabinet/admin/rooms-balances/${userId}`, params)
  },
  getPlayerRoomSettings: async (userId: number) => {
    return Request.get<void, AxiosResponse<PlayerRoomSettingsRo>>(
      `/cabinet/player/rooms-settings/${userId}`,
    )
  },
  getPlayerRoomSettingsList: async (userId: number, params?: any) => {
    return Request.get<void, AxiosResponse<PlayerRoomSettingsListRo>>(
      `/cabinet/player/rooms-settings/${userId}/list`,
      { params },
    )
  },
  getPlayersRoomsSettings: async (params: UsersControllerGetParams) => {
    return Request.get<
      UsersControllerGetParams,
      AxiosResponse<PlayersRoomsSettingsRo>
    >(`/cabinet/player/rooms-settings`, { params })
  },
  setPlayerRoomsSettings: async (
    userId: number,
    params: SetUserRoomSettingsDto,
  ) => {
    return Request.post<
      SetUserRoomSettingsDto,
      AxiosResponse<SetPlayerRoomSettingsRo>
    >(`/cabinet/admin/rooms-settings/${userId}`, params)
  },

  getPlayerFundsById: async (userId: number) => {
    return Request.get<void, AxiosResponse<UserPlayerFundsRo>>(
      `/cabinet/admin/player-funds/${userId}`,
    )
  },

  getPlayerFundsByToken: async () => {
    return Request.get<void, AxiosResponse<UserPlayerFundsRo>>(
      `/cabinet/player/player-funds/by-token`,
    )
  },
}
