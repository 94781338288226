import { RoomSingleRo } from '@/services/data-contracts'
import { SelectOption } from '@/components/Forms/Select'

export const roomsToSelectOptions = (rooms?: Pick<RoomSingleRo, 'id' | 'name' | 'avatar'>[]): SelectOption[] => {
  if (!rooms?.length) {
    return []
  }

  return (rooms || []).map((room) => ({
    id: room.id,
    label: room.name,
    avatar: room.avatar?.location,
  }))
}
