import * as yup from 'yup'

export type ApproveGameRequestModalSchema = {
  moderatorComment?: string
}

export const approveGameRequestModalSchema = yup.object({
  moderatorComment: yup
    .string()
    .optional()
    .max(500, 'Комментарий не может быть больше 500 символов'),
})
