import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { UserRoleValue } from '@/constants'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'

interface PrivateRouteProps {
  roles: Array<UserRoleValue> | 'all'
  children: React.ReactNode
}

export const PrivateRoute = ({ children, roles }: PrivateRouteProps) => {
  const navigate = useNavigate()
  const { currentUser, isError, isLoading, isFetching } = useCurrentUser()

  if (isLoading || isFetching) {
    return (
      <Box sx={{ display: 'block', height: '100vh' }}>
        <WithLoader isLoading={isLoading || isFetching} fullHeight />
      </Box>
    )
  }

  if (!currentUser?.role || isError) {
    navigate('/login')
    return null
  }

  const hasValidRole = roles === 'all'
    ? true
    : roles.includes(+currentUser.role as UserRoleValue)

  if (!hasValidRole) {
    navigate('/login')
    return null
  }

  return <>{children}</>
}
