import { Cabinet } from '@/services/api/cabinet'
import {
  SetPlayerRoomBalancesRo,
  SetUserRoomBalanceDto,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useSetPlayerRoomBalances = (
  props?: UseMutationOptions<
    SetPlayerRoomBalancesRo,
    AxiosErrorResponse,
    SetUserRoomBalanceDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    SetPlayerRoomBalancesRo,
    AxiosErrorResponse,
    SetUserRoomBalanceDto
  >(
    ['user', 'cabinet', 'player', 'rooms-balances', 'by-token'],
    async (payload: SetUserRoomBalanceDto) => {
      const balances = payload.balances.map(balance => ({
        ...balance,
        amount: balance.amount === null ? 0 : balance.amount,
      }))

      const response = await Cabinet.setPlayerRoomsBalancesByToken({
        ...payload,
        balances,
      })

      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries([
          'user',
          'cabinet',
          'player',
          'rooms-balances',
        ])
        await queryClient.invalidateQueries(['funds-transfers', 'balances'])
        await queryClient.invalidateQueries([
          'user',
          'cabinet',
          'admin',
          'player-funds',
        ])
        await queryClient.invalidateQueries([
          'user',
          'cabinet',
          'player',
          'player-funds',
        ])
        props?.onSuccess?.(...args)
      },
    },
  )
}
