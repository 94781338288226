import * as yup from 'yup'
import {
  REVIEW_COMMENT_LOYALTY_FIELD,
  REVIEW_COMMENT_RECOMMENDATIONS_FIELD,
  REVIEW_COMMENT_TECHNICAL_FIELD,
  REVIEW_PROMOTION_OPINION_FIELD,
  REVIEW_SCORE_MENTAL_FIELD,
  REVIEW_SCORE_SELECT_FIELD,
  REVIEW_SCORE_TECHNICAL_FIELD,
} from '@/constants'

export type ReviewModalSchema = {
  name?: string;
  fields: Record<string, string>
  file?: {
    id: number;
  }
}

export const reviewModalSchema = yup.object({
  name: yup.string().optional().nullable(),
  fields: yup.object({
    [REVIEW_COMMENT_TECHNICAL_FIELD]: yup.string().required('Обязательное поле'),
    [REVIEW_COMMENT_LOYALTY_FIELD]: yup.string().required('Обязательное поле'),
    [REVIEW_COMMENT_RECOMMENDATIONS_FIELD]: yup.string().required('Обязательное поле'),
    [REVIEW_SCORE_TECHNICAL_FIELD]: yup.string().required('Обязательное поле'),
    [REVIEW_SCORE_MENTAL_FIELD]: yup.string().required('Обязательное поле'),
    [REVIEW_SCORE_SELECT_FIELD]: yup.string().required('Обязательное поле'),
    [REVIEW_PROMOTION_OPINION_FIELD]: yup.string().required('Обязательное поле'),
  }).required(),
  file: yup.object({
    id: yup.mixed().optional().nullable()
  }).optional().nullable()
})
