import {
  FundsTransferCreatePersonalDto,
  FundsTransferCreateProjectDto,
  FundsTransferCreateToFundsRequestDto,
  FundsTransferEditPersonalDto,
  FundsTransferEditProjectDto,
  FundsTransferRo,
  FundsTransfersControllerGetPersonalTransfersParams,
  FundsTransfersControllerGetPlayerProjectTransfersParams,
  FundsTransfersDataRo,
  PlayerBalancesRo,
  PlayersBalancesTotalRo,
} from '@/services/data-contracts'
import { AxiosResponse } from 'axios'

import { Request } from '../Request'

export const FundsTransfers = {
  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<FundsTransferRo>>(
      `/funds-transfers/${id}`,
    )
  },
  getBalancesByToken: async () => {
    return Request.get<void, AxiosResponse<PlayerBalancesRo>>(
      '/funds-transfers/balances/by-token',
    )
  },
  getPersonalTransfersByToken: async (
    params?: FundsTransfersControllerGetPersonalTransfersParams,
  ) => {
    return Request.get<
      FundsTransfersControllerGetPersonalTransfersParams,
      AxiosResponse<FundsTransfersDataRo>
    >('/funds-transfers/personal/by-token', { params })
  },
  getProjectTransfersByToken: async (
    params?: FundsTransfersControllerGetPlayerProjectTransfersParams,
  ) => {
    return Request.get<
      FundsTransfersControllerGetPlayerProjectTransfersParams,
      AxiosResponse<FundsTransfersDataRo>
    >('/funds-transfers/project/by-token', { params })
  },
  createPersonalTransfer: async (params: FundsTransferCreatePersonalDto) => {
    return Request.post<
      FundsTransferCreatePersonalDto,
      AxiosResponse<FundsTransferRo>
    >('/funds-transfers/personal', params)
  },
  editPersonalTransfer: async (
    id: number,
    params: FundsTransferEditPersonalDto,
  ) => {
    return Request.patch<
      FundsTransferEditPersonalDto,
      AxiosResponse<FundsTransferRo>
    >(`/funds-transfers/personal/${id}`, params)
  },
  createProjectTransfer: async (params: FundsTransferCreateProjectDto) => {
    return Request.post<
      FundsTransferCreateProjectDto,
      AxiosResponse<FundsTransferRo>
    >('/funds-transfers/project', params)
  },
  editProjectTransfer: async (
    id: number,
    params: FundsTransferEditProjectDto,
  ) => {
    return Request.patch<
      FundsTransferEditProjectDto,
      AxiosResponse<FundsTransferRo>
    >(`/funds-transfers/project/${id}`, params)
  },
  createToFundsRequestTransfer: async (
    params: FundsTransferCreateToFundsRequestDto,
  ) => {
    return Request.post<
      FundsTransferCreateToFundsRequestDto,
      AxiosResponse<FundsTransferRo>
    >('/funds-transfers/project/funds-request', params)
  },
  getAdminTotalPlayerBalances: async () => {
    return Request.get<void, AxiosResponse<PlayersBalancesTotalRo[]>>(
      '/funds-transfers/admin/total-players-balances',
    )
  },
  delete: async (id: number) => {
    return Request.delete(`/funds-transfers/${id}`)
  },
}
