import { GROUP_STYLE_PARAMS } from '@/components/Containers/UsersRoomsSettingsTable/constants.ts'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle.tsx'
import { Box, Typography } from '@mui/material'

interface RoomHeadCellProps {
  name: string
  location?: string
}

const RoomHeadCell = ({ name, location }: RoomHeadCellProps) => {
  return (
    <>
      <TableImageTitle title={name} src={location} />
      <Box display="flex" gap={GROUP_STYLE_PARAMS.gap} marginY="8px">
        <Box width={GROUP_STYLE_PARAMS.firstCell} textAlign="left">
          <Typography variant="body2">Никнейм</Typography>
        </Box>
        <Box width={GROUP_STYLE_PARAMS.secondCell} textAlign="left">
          <Typography variant="body2">Данные для перевода</Typography>
        </Box>
      </Box>
    </>
  )
}

export default RoomHeadCell
