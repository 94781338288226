import { GameRequests } from '@/services/api/game-requests'
import {
  GameRequestRo,
  GameRequestStageDeclinedPlayDto,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export type UseHandleStageDeclinedPlayGameRequestProps = {
  id: number
  timezone?: string
} & GameRequestStageDeclinedPlayDto

export const useHandleStageDeclinedPlayGameRequest = (
  props?: UseMutationOptions<
    GameRequestRo,
    AxiosErrorResponse,
    UseHandleStageDeclinedPlayGameRequestProps
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    GameRequestRo,
    AxiosErrorResponse,
    UseHandleStageDeclinedPlayGameRequestProps
  >(
    ['game-requests'],
    async (payload: UseHandleStageDeclinedPlayGameRequestProps) => {
      const { id, ...params } = payload
      const response = await GameRequests.handleStageDeclinedPlay(id, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries({
          exact: false,
          queryKey: ['game-requests'],
        })
        await queryClient.refetchQueries(['funds-transfers', 'personal'])
        await queryClient.refetchQueries(['funds-transfers', 'balances'])
        await queryClient.invalidateQueries(['user', 'cabinet'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
