import { PlayerRoomsSettings } from '@/components/Containers/PlayerRoomsSettings/PlayerRoomsSettings'
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from '@mui/material'

interface UserRoomSettingsProps extends DialogProps {
  userId: number
}

export const UserRoomSettings = ({
  userId,
  ...props
}: UserRoomSettingsProps) => {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...props}
    >
      <DialogTitle variant="h4">
        Настройки пользователя в румах. ID: {userId}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <PlayerRoomsSettings userId={userId} />
      </DialogContent>
    </Dialog>
  )
}
