import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const useRedirectIfLoggedIn = () => {
  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoadingCurrentUser) {
      currentUser
        ? navigate('/cabinet')
        : navigate('/login')
    }
  }, [navigate, currentUser, isLoadingCurrentUser])
}
