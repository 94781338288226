import { useFormContext } from 'react-hook-form'

import { PlayerRoomSettings } from '@/components/Containers/PlayerRoomSettings/PlayerRoomSettings'
import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { PhotoUploader } from '@/components/Forms/PhotoUploader'
import { Select } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import {
  ROOMS_LIMITATIONS,
  transferPurposePersonalSelectOptions,
} from '@/constants'
import { useGetPlayerRoomsSettingsList } from '@/services/hooks/cabinet/player/useGetPlayerRoomsSettingsList.ts'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { useGetUsers } from '@/services/hooks/users/useGetUsers'
import { CurrencyHelper } from '@/utils/CurrencyHelper'
import { usersToSelectOptions } from '@/utils/mappers/usersToSelectOptions'
import { RoleHelper } from '@/utils/RoleHelper'
import { Box, Button, DialogContent, InputAdornment } from '@mui/material'

export interface TransferModalContentProps {
  type: 'personal' | 'project'
  mode: 'create' | 'edit'
  hasFundsRequest: boolean
  isSubmitting?: boolean
}

export const TransferModalContent = ({
  type,
  mode,
  hasFundsRequest,
  isSubmitting,
}: TransferModalContentProps) => {
  const { watch } = useFormContext()

  const roomId = watch('room.id')
  const recipientId = watch('recipient.id')

  const roomCategoryFilter = {
    project: ROOMS_LIMITATIONS.PROJECT_TRANSFERS,
    personal: ROOMS_LIMITATIONS.PERSONAL_TRANSFERS,
  }

  const { currentUser, isFetching: isFetchingCurrentUser } = useCurrentUser()
  const { defaultSortString } = useGetSort()
  const { data: settings, isFetching: isRoomsFetching } =
    useGetPlayerRoomsSettingsList(
      {
        userId: currentUser?.id ?? 0,
        params: {
          'category.some': roomCategoryFilter[type] || undefined,
          sort: defaultSortString,
        },
      },
      { enabled: !!currentUser },
    )
  const { data: users, isFetching: isFetchingUsers } = useGetUsers({
    limit: 500,
    sort: 'username:ASC',
    'isKicked.equal': false,
  })
  const settingsFiltered = settings?.data.filter(
    setting => setting.transferInfo ?? setting.nickname,
  )
  const roomsSelectValues = (settingsFiltered ?? []).map(setting => ({
    id: setting.room.id,
    label: setting.room.name,
    avatar: setting.room.avatar?.location,
  }))
  const usersSelectValues = usersToSelectOptions(users ? users.data : [], {
    excludeCurrentUser: {
      enable: !RoleHelper.isAdmin(currentUser?.role),
      currentUserId: currentUser?.id || 0,
    },
  })
  const selectedRoom = settingsFiltered?.find(
    setting => setting.room.id === roomId,
  )
  const currencySign = CurrencyHelper.getCurrencySign(
    selectedRoom?.room.currency,
  )

  return (
    <DialogContent>
      <WithLoader
        isLoading={isFetchingUsers || isRoomsFetching || isFetchingCurrentUser}
      >
        <Box sx={{ width: '400px' }}>
          <Box mb={2}>
            <Select
              name="room.id"
              label="Рум"
              options={roomsSelectValues}
              enableAvatar
              selectProps={{
                disabled: mode === 'edit' && hasFundsRequest,
              }}
            />
          </Box>
          {RoleHelper.isAdmin(currentUser?.role) && (
            <Box mb={2}>
              <Select
                name="sender.id"
                label="Отправитель"
                options={usersSelectValues}
                enableAvatar={false}
              />
            </Box>
          )}
          {type === 'project' && (
            <Box>
              <Select
                name="recipient.id"
                label="Получатель"
                options={usersSelectValues}
                enableAvatar={false}
                selectProps={{
                  disabled: mode === 'edit' && hasFundsRequest,
                }}
              />
            </Box>
          )}
          {type === 'personal' && (
            <Box>
              <Select
                name="transferPurpose"
                label="Назначение перевода"
                options={transferPurposePersonalSelectOptions.map(
                  selectOption => {
                    return {
                      id: selectOption.id,
                      label: selectOption.label,
                    }
                  },
                )}
              />
            </Box>
          )}
          {roomId && recipientId && (
            <Box mt={2} mb={1}>
              <PlayerRoomSettings userId={recipientId} roomId={roomId} />
            </Box>
          )}
          <Box>
            <TextField
              disabled={mode === 'edit'}
              label="Сумма (факт)"
              name="factAmount"
              textFieldProps={{
                InputProps: {
                  startAdornment: currencySign && (
                    <InputAdornment position="start">
                      {currencySign}
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
          {type === 'project' && (
            <Box>
              <TextField
                disabled={mode === 'edit'}
                label="Размер комиссии"
                name="transferFee"
                textFieldProps={{
                  InputProps: {
                    startAdornment: currencySign && (
                      <InputAdornment position="start">
                        {currencySign}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Box>
          )}
          <Box>
            <PhotoUploader
              label="Скриншот (JPG, PNG)"
              name="screenshot.id"
              buttonProps={{ color: 'secondary' }}
            />
          </Box>
          <Box>
            <TextField
              label="Комментарий"
              name="comment"
              textFieldProps={{
                multiline: true,
                rows: 2,
              }}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? '...' : 'Сохранить'}
          </Button>
        </Box>
      </WithLoader>
    </DialogContent>
  )
}
