import { CUSTOM_SETTING_WIKI_URL } from '@/components/CustomSettings/constants.ts'
import { CustomSettingWrap } from '@/components/CustomSettings/templates/CustomSettingWrap.tsx'
import { TextSetting } from '@/components/CustomSettings/templates/TextCustomSetting/Text.setting.tsx'

export const WikiUrlSettingViewSetting = () => {
  return (
    <CustomSettingWrap>
      <TextSetting
        name={CUSTOM_SETTING_WIKI_URL}
        title={`Блок c указанием URL для WIKI"`}
      />
    </CustomSettingWrap>
  )
}
