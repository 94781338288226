import { AxiosResponse } from 'axios'

import { Request } from '../Request'


import { CurrencyRateControllerGetTodayRatesParams, CurrencyRateControllerHistoryRatesParams, CurrencyRateHistoryDataRo } from '@/services/data-contracts.ts'

export const CurrencyRate = {
  getTodayRate: async (params: CurrencyRateControllerGetTodayRatesParams) => {
    return Request.get<CurrencyRateControllerGetTodayRatesParams, AxiosResponse<number>>(
      '/currency-rate/today-rates',
      { params }
    )
  },

  getHistoryRates(params: CurrencyRateControllerHistoryRatesParams) {
    return Request.get<CurrencyRateControllerGetTodayRatesParams, AxiosResponse<CurrencyRateHistoryDataRo>>(
      '/currency-rate/history-rates',
      { params }
    )
  }
}
