import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'

import { ExportUserInfo } from './components/ExportUserInfo'
import { UserGoogleSheetsSettings } from './components/UserGoogleSheetsSettings'

export interface UserGoogleSheetsSettingsModal extends DialogProps {
  userId: number
}

export const UserGoogleSheetsSettingsModal = ({
  userId,
  ...props
}: UserGoogleSheetsSettingsModal) => {
  return (
    <Dialog {...props} maxWidth="sm">
      <Box minWidth={600}>
        <DialogTitle>Google sheets. ID пользователя: {userId}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="h4">Настройки</Typography>
          </Box>
          <UserGoogleSheetsSettings userId={userId} />
        </DialogContent>
        <Divider />
        <DialogContent>
          <Box mb={3}>
            <Typography variant="h4">Действия</Typography>
          </Box>
          <ExportUserInfo userId={userId} />
        </DialogContent>
      </Box>
    </Dialog>
  )
}
