import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { RoomNetworksDataRo, RoomsControllerFindAllParams } from '@/services/data-contracts'
import { RoomNetworks } from '@/services/api/room-networks.ts'
import { AxiosErrorResponse } from '@/types'

export const useGetRoomNetworks = (
  params?: RoomsControllerFindAllParams,
  props?: UseQueryOptions<RoomNetworksDataRo, AxiosErrorResponse>,
) => {
  return useQuery<RoomNetworksDataRo, AxiosErrorResponse>(
    ['room-networks', params],
    async () => {
      const response = await RoomNetworks.get(params)
      return response.data
    },
    props,
  )
}
