import { GameRequests } from '@/services/api/game-requests'
import {
  GameRequestRo,
  GameRequestStageApprovalDto,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export type UseHandleStageApprovalGameRequestProps = {
  id: number
} & GameRequestStageApprovalDto

export const useHandleStageApprovalGameRequest = (
  props?: UseMutationOptions<
    GameRequestRo,
    AxiosErrorResponse,
    UseHandleStageApprovalGameRequestProps
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    GameRequestRo,
    AxiosErrorResponse,
    UseHandleStageApprovalGameRequestProps
  >(
    ['game-requests'],
    async (payload: UseHandleStageApprovalGameRequestProps) => {
      const { id, ...params } = payload
      const response = await GameRequests.handleStageApproval(id, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['game-requests'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
