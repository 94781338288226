import { AxiosResponse } from 'axios'

import { Request } from '../Request'
import { UsersControllerGetParams, UsersCreateDto, UsersCreateRo, UsersDataRo, UsersEditDto, UsersEditRo, UsersRo } from '@/services/data-contracts'

export const Users = {
  get: async (params: UsersControllerGetParams) => {
    return Request.get<UsersControllerGetParams, AxiosResponse<UsersDataRo>>('/users', { params })
  },
  create: async (params: UsersCreateDto) => {
    return Request.post<UsersCreateDto, AxiosResponse<UsersCreateRo>>('/users', params)
  },
  getByToken: async () => {
    return Request.get<void, AxiosResponse<UsersRo>>('/users/by-token')
  },
  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<UsersRo>>(`/users/${id}`)
  },
  getByUserName: async (username: string) => {
    return Request.post<void, AxiosResponse<boolean>>(`/users/${username}/by-username`)
  },
  edit: async (id: number, params: UsersEditDto) => {
    return Request.patch<UsersEditDto, AxiosResponse<UsersEditRo>>(`/users/${id}`, params)
  },
  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/users/${id}`)
  },

}
