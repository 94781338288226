import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { UserReports } from '@/services/api/user-reports'
import { UserReportRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export type UseGetUserReportByIdResponse = Omit<UserReportRo, 'fields'> & {
  fields: Record<string, unknown>
}

export const useGetUserReportById = (id: number, props?: UseQueryOptions<UserReportRo, AxiosErrorResponse, UseGetUserReportByIdResponse>) => {
  return useQuery<UserReportRo, AxiosErrorResponse, UseGetUserReportByIdResponse>(
    ['user-reports', id],
    async () => {
      const response = await UserReports.getById(id)
      return response.data
    },
    props,
  )
}
