import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { RoomNetworkCreateDto, RoomNetworkRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'
import { RoomNetworks } from '@/services/api/room-networks.ts'

export const useCreateRoomNetwork = (props?: UseMutationOptions<RoomNetworkRo, AxiosErrorResponse, RoomNetworkCreateDto>) => {
  const queryClient = useQueryClient()

  return useMutation<RoomNetworkRo, AxiosErrorResponse, RoomNetworkCreateDto>(
    ['room-networks'],
    async (payload: RoomNetworkCreateDto) => {
      const response = await RoomNetworks.create(payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['rooms'])
        await queryClient.invalidateQueries(['room-networks'])
        props?.onSuccess?.(...args)
      },
    })
}
