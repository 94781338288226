import { FundsRequestsInfoSetting } from '@/components/CustomSettings/settings/FundsRequestsInfo/FundsRequestsInfo.setting'
import { OtherRoomsTableSetting } from '@/components/CustomSettings/settings/OtherRoomsTable/OtherRoomsTable.setting'
import { TournamentsRulesSetting } from '@/components/CustomSettings/settings/TournamentsRules/TournamentsRules.setting'
import { WikiUrlSettingViewSetting } from '@/components/CustomSettings/settings/WikiUrlSetting/WikiUrlSettingView.setting.tsx'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { Grid } from '@mui/material'

export const CustomSettingsPage = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TournamentsRulesSetting />
        </Grid>
        <Grid item xs={6}>
          <OtherRoomsTableSetting />
        </Grid>
        <Grid item xs={6}>
          <FundsRequestsInfoSetting />
        </Grid>
        <Grid item xs={6}>
          <WikiUrlSettingViewSetting />
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}
