import { ChangeEventHandler, useId, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { usePasteImage } from '@/components/Forms/hooks/usePasteImage.tsx'
import { useSnackBar } from '@/context/SnackbarContext'
import { useUpload } from '@/services/hooks/uploads/useUpload'
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  CircularProgress,
} from '@mui/material'

import { FormItem, FormItemChildrenProps } from './FormItem'

export interface PhotoUploaderProps extends Partial<FormItemChildrenProps> {
  name: string
  buttonProps?: ButtonProps
  disabled?: boolean
}

export const PhotoUploader = ({
  disabled,
  label,
  formItemProps,
  buttonProps,
  name,
}: PhotoUploaderProps) => {
  const [image, setImage] = useState<string | null>(null)
  const id = useId()
  const { setValue } = useFormContext()
  const { showSnackBar } = useSnackBar()
  const uploadMutation = useUpload({
    onSuccess: data => {
      showSnackBar('Файл загружен', 'success')
      setValue(name, data.id)
      setImage(data.location)
    },
    onError: error => {
      showSnackBar(`Ошибка при загрузке файла`, 'error', error)
      setValue(name, undefined)
      setImage(null)
    },
  })
  const { handleDropOver, handleDrop } = usePasteImage(uploadMutation)

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = () => {
    const input = document.getElementById(id) as HTMLInputElement
    const file = input?.files?.[0]

    if (file) {
      uploadMutation.mutate({ file })
    }
  }

  const isButtonDisabled = disabled || uploadMutation.isLoading

  return (
    <FormItem
      fullWidth
      {...formItemProps}
      name={name || ''}
      label={label || ''}
      labelPosition="none"
    >
      {() => (
        <Box my={1}>
          <Button
            fullWidth
            variant="contained"
            type="button"
            {...buttonProps}
            disabled={isButtonDisabled}
            onDrop={handleDrop}
            onDragOver={handleDropOver}
            startIcon={
              (uploadMutation.isLoading && <CircularProgress size={30} />) ||
              (image && (
                <Avatar
                  variant="rounded"
                  src={image}
                  sx={{ width: '30px', height: '30px' }}
                  imgProps={{ sx: { objectFit: 'contain' } }}
                />
              ))
            }
            sx={{ position: 'relative', ...buttonProps?.sx }}
          >
            {label || 'Загрузить'}
            <input
              disabled={isButtonDisabled}
              id={id}
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                fontSize: 1,
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
              onChange={handleFileChange}
              type="file"
            />
          </Button>
        </Box>
      )}
    </FormItem>
  )
}
