import { Box, CircularProgress } from '@mui/material'
import { useRedirectIfLoggedIn } from '@/hooks/useRedirectIfLoggedIn'

export const MainPage = () => {
  useRedirectIfLoggedIn()

  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={40} />
    </Box>
  )
}
