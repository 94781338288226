import { Alert, Box, TextField as TextFieldMui } from '@mui/material'

import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { CopyToClipboardAdornment } from '@/components/Utils/CopyToClipboardAdornment/CopyToClipboardAdornment.tsx'
import { useGetPlayerRoomsSettings } from '@/services/hooks/cabinet/player/useGetPlayerRoomsSettings'

export interface PlayerRoomSettingsProps {
  userId: number
  roomId: number
}

export const PlayerRoomSettings = ({
  userId,
  roomId,
}: PlayerRoomSettingsProps) => {
  const {
    data: settingsData,
    isFetching: isFetchingSettings,
    isError,
    isSuccess,
  } = useGetPlayerRoomsSettings({
    userId,
  })

  const settings = settingsData ? settingsData.data[roomId] : null

  return (
    <WithLoader isLoading={isFetchingSettings}>
      {isError && (
        <Alert severity="error">
          Не удалось загрузить данные для перевода для пользователя. UserID:{' '}
          {userId}. RoomID: {roomId}
        </Alert>
      )}
      {isSuccess && !settings && (
        <Alert severity="warning">
          У пользователя нет настроек для данного рума. UserID: {userId}.
          RoomID: {roomId}
        </Alert>
      )}
      {isSuccess && !!settings && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }} mb={2}>
            <TextFieldMui
              label="Никнейм на руме"
              fullWidth
              value={settings.nickname}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <TextFieldMui
              label="Данные для перевода"
              fullWidth
              value={settings.transferInfo}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <CopyToClipboardAdornment position="end" valueToCopy={settings.transferInfo} />
              }}
              disabled
            />
          </Box>
        </Box>
      )}
    </WithLoader>
  )
}
