import { UserContracts } from '@/services/api/user-contracts'
import {
  UserContractCreateDto,
  UserContractCreateRo,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useCreateUserContract = (
  props?: UseMutationOptions<
    UserContractCreateRo,
    AxiosErrorResponse,
    UserContractCreateDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    UserContractCreateRo,
    AxiosErrorResponse,
    UserContractCreateDto
  >(
    ['user-contracts'],
    async (params: UserContractCreateDto) => {
      const response = await UserContracts.create(params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-contracts'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
