import { useFormContext } from 'react-hook-form'
import { Typography } from '@mui/material'
import { ReactNode } from 'react'

interface GameRequestPlayResultProps {
  buyIn: number;
  title: string;
}

export const GameRequestPlayResult = ({ buyIn, title }: GameRequestPlayResultProps) => {
  const { watch } = useFormContext()

  const prize = watch('prize')
  const entries = watch('entries')

  const isValidPrize = Boolean(prize && +prize >= 0);
  const isValidEntries = Boolean(entries && entries >= 1);

  const createAnswer = (text: ReactNode, color = 'grey') => (
    <Typography color={color} my={1} variant="body1">{text}</Typography>
  )

  if (!isValidPrize && !isValidEntries) {
    return createAnswer('Введите призовые и кол-во входов')
  }

  if (!isValidPrize && isValidEntries) {
    return createAnswer('Введите призовые')
  }

  if (isValidPrize && !isValidEntries) {
    return createAnswer('Введите кол-во входов')
  }

  const lostMoney = entries * buyIn;
  const result = prize - lostMoney;

  return createAnswer((
    <>
      <span>От вашего имени будет создан личный перевод.</span>
      <ul>
        <li>На сумму: <b>{result}</b></li>
        <li>Турнир: {title}</li>
        <li>Buy-In: {buyIn}</li>
      </ul>
    </>
  ), 'black')
}
