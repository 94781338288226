import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import { UserGroupModal } from '@/components/Modals/UserGroupModal/UserGroupModal'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableMultipleChip } from '@/components/Tables/columns/TableMultipleChip/TableMultipleChip'
import { TableUser } from '@/components/Tables/columns/TableUser/TableUser'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { AddEntityButton } from '@/components/Tables/toolbar/AddEntityButton'
import { useSnackBar } from '@/context/SnackbarContext'
import { UserGroupSingle } from '@/services/data-contracts'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { useDeleteUserGroup } from '@/services/hooks/user-groups/useDeleteUserGroup'
import { useGetUserGroups } from '@/services/hooks/user-groups/useGetUserGroups'
import { rankToColor } from '@/utils/rankToColor'
import { RoleHelper } from '@/utils/RoleHelper'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'

export const UserGroupsTable = () => {
  const tableDefault = useTableDefaultOptions();
  const tablePagination = useTablePagination();

  const { showModal } = useModal()
  const { showSnackBar } = useSnackBar()
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  const canViewAllGroups = RoleHelper.isAdmin(currentUser?.role)
  const canAdd = RoleHelper.isAdmin(currentUser?.role)
  const canEdit = RoleHelper.isAdmin(currentUser?.role)
  const canDelete = RoleHelper.isAdmin(currentUser?.role)

  const { data: userGroups, isLoading } = useGetUserGroups({
    ...tablePagination.queryParams,
    ...(!canViewAllGroups &&
      currentUser && {
        'coach.equal': +currentUser.id,
      }),
  })

  const deleteUserGroup = useDeleteUserGroup({
    onSuccess: () =>
      showSnackBar('Группа пользователей успешно удалена', 'success'),
    onError: () =>
      showSnackBar('Ошибка при удалении группы пользователей', 'error'),
  })

  const [data, count] = userGroups
    ? [userGroups.data, userGroups.count]
    : [[], 0]

  const handleOpenUserGroupCreateModal = () => {
    const modal = showModal(UserGroupModal, {
      mode: 'create',
      userGroupId: undefined,
      onSuccess: () => {
        showSnackBar('Успешно создана группа пользователей', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при создании группы пользователей', 'error')
      },
    })
  }

  const handleOpenUserGroupEditModal = (row: UserGroupSingle) => {
    const modal = showModal(UserGroupModal, {
      mode: 'edit',
      userGroupId: row.id,
      onSuccess: () => {
        showSnackBar('Успешно отредактирована группа пользователей', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при редактировании группы пользователей', 'error')
      },
    })
  }

  const handleDeleteUserGroup = (row: UserGroupSingle) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить группу "${row.name}" ?`,
      buttons: {
        confirm: {
          onClick: () => {
            deleteUserGroup.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const { TableMenuColumn } = useMenu<UserGroupSingle>({
    data,
    options: [
      ...(canEdit
        ? [
            {
              id: 'edit',
              label: 'Редактировать',
              onClick: handleOpenUserGroupEditModal,
            },
          ]
        : []),
      ...(canDelete
        ? [{ id: 'delete', label: 'Удалить', onClick: handleDeleteUserGroup }]
        : []),
    ],
  })

  return (
    <MUIDataTable
      title="Группы пользователей"
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            customBodyRender: (value: string) => (
              <TableColumn width={20}>{value}</TableColumn>
            ),
          },
        },
        {
          name: 'createdAt',
          label: 'Создана',
          options: {
            customBodyRender: TableCreatedAt,
          },
        },
        {
          name: 'name',
          label: 'Название',
          options: {
            customBodyRender: (value: string) => (
              <TableColumn width={120}>
                <Typography variant="body2">{value}</Typography>
              </TableColumn>
            ),
          },
        },
        {
          name: 'coach.username',
          label: 'Тренер',
          options: {
            customBodyRender: TableUser,
          },
        },
        {
          name: 'students',
          label: 'Игроки',
          options: {
            customBodyRender: (users: UserGroupSingle['students']) =>
              users?.length ? (
                <TableMultipleChip
                  items={users.map(user => ({
                    id: user.id,
                    label: user.username,
                    avatar: {
                      color: rankToColor(user.rank),
                      title: String(user.rank),
                    },
                  }))}
                  onClick={id => navigate(`/cabinet/reviews/user/${id}`)}
                />
              ) : (
                'Игроков нет'
              ),
          },
        },
        {
          name: '__avg_rank',
          label: 'Средний ранг группы',
          options: {
            customBodyRender: (_, meta) => {
              const row = data[meta.rowIndex]

              const avg: number = row.students?.length
                ? row.students.reduce((acc, student) => acc + student.rank, 0) /
                  row.students.length
                : 0

              const avgRank = Math.floor(avg);

              return (
                <TableColumn width={60}>
                  <Box
                    border={`1px solid ${rankToColor(avgRank)}`}
                    borderRadius={1}
                    textAlign="center"
                    p={1}
                  >
                    <Typography variant="body2" fontWeight={600}>
                      {avg.toFixed(2)}
                    </Typography>
                  </Box>
                </TableColumn>
              )
            },
          },
        },
        {
          name: '__actions__',
          label: ' ',
          options: {
            customBodyRenderLite: TableMenuColumn,
          },
        },
      ]}
      data={data}
      options={{
        ...tableDefault.options,
        ...tablePagination.options,
        count,
        tableBodyHeight: '700px',
        textLabels: createTextLabels({
          isLoading: isLoading,
          textLabels: {
            body: {
              noMatch: 'Групп пользователей не найдено',
            },
          },
        }),
        ...(canAdd && {
          customToolbar: () => (
            <AddEntityButton onClick={handleOpenUserGroupCreateModal} />
          ),
        }),
      }}
      components={tableDefault.components}
    />
  )
}
