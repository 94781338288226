import { SelectOption } from '@/components/Forms/Select'
import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter'
import { countryCodes } from '@/constants/generated/country-codes'

export const countryCodesSelectOptions: SelectOption[] = [
  {
    id: '',
    label: 'Не выбрано',
  },
  ...Object.values(countryCodes).map(row => ({
    id: row.code,
    label: row.name,
    avatar: row.src,
  })),
]

export const countryCodesMultipleSelectOptions: MultipleSelectOption[] =
  Object.values(countryCodes).map(row => ({
    id: row.code,
    label: row.name,
    avatar: {
      src: row.src,
      alt: row.name,
    },
  }))
