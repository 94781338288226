import { getCustomSettingOtherRoomsName } from '@/components/CustomSettings/constants'
import { TextView } from '@/components/CustomSettings/templates/TextCustomSetting/Text.view'

type OtherRoomsTableViewProps = {
  rank?: number
}

export const OtherRoomsTableView = ({ rank }: OtherRoomsTableViewProps) => {
  if (typeof rank !== 'number') {
    return null
  }

  return <TextView name={getCustomSettingOtherRoomsName(rank)} />
}
