import { RouterProvider } from 'react-router-dom'

import { SnackBarProvider } from '@/context/SnackbarContext'
import { ThemeProvider } from '@/context/ThemeContext.tsx'
import { router } from '@/router/router'
import { CssBaseline } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ModalProvider from 'mui-modal-provider'

import './app.module.scss'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000, // 5 min
    },
  },
})

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SnackBarProvider>
          <ModalProvider>
            <CssBaseline />
            <RouterProvider router={router} />
          </ModalProvider>
        </SnackBarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
