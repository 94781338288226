import {
  ScheduledTournamentRo,
  ScheduledTournamentsControllerFindAllParams,
  ScheduledTournamentsDataRo,
} from '@/services/data-contracts'
import { Request } from '@/services/Request'
import { AxiosResponse } from 'axios'

export const ScheduledTournaments = {
  get: async (params?: ScheduledTournamentsControllerFindAllParams) => {
    return Request.get<
      ScheduledTournamentsControllerFindAllParams,
      AxiosResponse<ScheduledTournamentsDataRo>
    >('/scheduled-tournaments', { params })
  },
  getById: async (id: number) => {
    return Request.get<undefined, AxiosResponse<ScheduledTournamentRo>
      >(`/scheduled-tournaments/${id}`)
  }
}
