import { CustomSettings } from '@/services/api/custom-settings'
import { CustomSettingSetDto, CustomSettingRo } from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useSetCustomSetting = (
  props?: UseMutationOptions<
    CustomSettingRo,
    AxiosErrorResponse,
    CustomSettingSetDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<CustomSettingRo, AxiosErrorResponse, CustomSettingSetDto>(
    ['custom-settings'],
    async (payload: CustomSettingSetDto) => {
      const response = await CustomSettings.set(payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['custom-settings'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
