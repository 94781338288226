import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { UserGroups } from '@/services/api/user-groups'
import { UserGroupRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGetUserGroupById = (id: number, props?: UseQueryOptions<UserGroupRo, AxiosErrorResponse>) => {
  return useQuery<UserGroupRo, AxiosErrorResponse>(
    ['user-groups', id],
    async () => {
      const response = await UserGroups.getById(id)
      return response.data
    },
    props,
  )
}
