import {
  CustomSettingRo,
  CustomSettingsControllerFindParams,
  CustomSettingsDataRo,
  CustomSettingSetDto,
} from '@/services/data-contracts'
import { AxiosResponse } from 'axios'

import { Request } from '../Request'

export const CustomSettings = {
  set: async (params: CustomSettingSetDto) => {
    return Request.post<CustomSettingSetDto, AxiosResponse<CustomSettingRo>>(
      `/custom-settings`,
      params,
    )
  },
  get: async (params?: CustomSettingsControllerFindParams) => {
    return Request.get<
      CustomSettingsControllerFindParams,
      AxiosResponse<CustomSettingsDataRo>
    >(`/custom-settings`, { params })
  },
  getByName: async (name: string) => {
    return Request.get<void, AxiosResponse<CustomSettingRo>>(
      `/custom-settings/${name}`,
    )
  },
  deleteById: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(
      `/custom-settings/${id}`,
    )
  },
  deleteByName: async (name: string) => {
    return Request.delete<void, AxiosResponse<boolean>>(
      `/custom-settings/by-name/${name}`,
    )
  },
}
