import { useState } from 'react'

import { Box, InputAdornment, TextField, Typography } from '@mui/material'

import styles from './CurrencyRateTable.module.scss'
import { CurrencyHelper } from '@/utils/CurrencyHelper.ts'

interface CurrencyRateTableCalculationCellProps {
  fromCurrency: string;
  toCurrency: string;
  currencyRate: number;
  view: 'full' | 'sign-only'
}

const MIN_VALUE = -9999999;
const MAX_VALUE = 9999999;

export const CurrencyRateTableCalculationCell = ({ fromCurrency, toCurrency, currencyRate, view = 'full' }: CurrencyRateTableCalculationCellProps) => {
  const [amount, setAmount] = useState<string>('');

  const calculated = ((+amount || 0) * currencyRate);

  const currencyView = {
    from: view === 'full' ? fromCurrency : CurrencyHelper.getCurrencySign(fromCurrency),
    to: view === 'full' ? toCurrency : CurrencyHelper.getCurrencySign(toCurrency),
  };

  return (
    <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
      <TextField
        value={amount}
        className={styles.currencyInput}
        size="small"
        sx={{ maxWidth: 120 }}
        type="number"
        onChange={(event) => {
          const { value } = event.currentTarget;

          if (+value > MAX_VALUE || +value < MIN_VALUE) {
            return;
          }

          setAmount(value)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {currencyView.from}
            </InputAdornment>
          )
        }}
      />
      <Typography variant="h4" component="span">=</Typography>
      <Typography>{calculated.toFixed(2)} {currencyView.to}</Typography>
    </Box>
  )
}
