import { useMemo } from 'react'

import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { Form } from '@/components/Forms/Form'
import { Select } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import {
  roomNetworkModalSchema,
  RoomNetworkModalSchema,
} from '@/components/Modals/RoomNetworkModal/schema/roomNetworkModal.schema.ts'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useCreateRoomNetwork } from '@/services/hooks/room-networks/useCreateRoomNetwork.ts'
import { useEditRoomNetwork } from '@/services/hooks/room-networks/useEditRoomNetwork.ts'
import { useGetRoomNetworkById } from '@/services/hooks/room-networks/useGetRoomNetworkById.ts'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms.ts'
import { AxiosErrorResponse } from '@/types.ts'
import { roomsToSelectOptions } from '@/utils/mappers/roomsToSelectOptions.ts'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material'

interface RoomCreateModalProps {
  mode: 'create'
  roomId: undefined
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

interface RoomEditModalProps {
  mode: 'edit'
  roomId: number
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

export type RoomModalProps = (RoomCreateModalProps | RoomEditModalProps) &
  Omit<DialogProps, 'onError' | 'onSuccess'>

export const RoomNetworkModal = ({
  mode,
  roomId: roomNetworkId,
  onSuccess,
  onError,
  ...props
}: RoomModalProps) => {
  const titleMap: Record<'create' | 'edit', string> = {
    create: 'Создание сети румов',
    edit: `Редактирование сети румов. ID: ${roomNetworkId}`,
  }
  const { defaultSortString } = useGetSort()
  const { data: roomNetwork, isFetching: isRoomNetworkFetching } =
    useGetRoomNetworkById(roomNetworkId || 0, {
      enabled: Boolean(mode === 'edit' && roomNetworkId),
    })

  const { data: roomsResponse, isFetching: isRoomsFetching } = useGetRooms({
    sort: defaultSortString,
  })

  const createRoomNetwork = useCreateRoomNetwork({ onSuccess, onError })
  const editRoomNetwork = useEditRoomNetwork({ onSuccess, onError })

  const isButtonDisabled =
    createRoomNetwork.isLoading ||
    editRoomNetwork.isLoading ||
    isRoomNetworkFetching ||
    isRoomsFetching

  const handleSubmit = (data: RoomNetworkModalSchema) => {
    const payload = {
      name: data.name,
      rooms: (data?.rooms || []).map(roomId => ({
        id: +roomId,
      })),
    }

    if (mode === 'create') {
      return createRoomNetwork.mutate(payload)
    }

    if (mode === 'edit') {
      return editRoomNetwork.mutate({
        id: roomNetworkId,
        ...payload,
      })
    }

    return null
  }

  const validationSchema = useMemo(() => roomNetworkModalSchema.generate(), [])
  const initialValues: RoomNetworkModalSchema | undefined = useMemo(() => {
    if (mode !== 'edit' || !roomNetwork) {
      return undefined
    }

    return {
      name: roomNetwork.name,
      rooms: (roomNetwork.rooms || []).map(room => room.id),
    }
  }, [mode, roomNetwork])

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...props}
    >
      <DialogTitle variant="h4">{titleMap[mode]}</DialogTitle>
      <Divider />
      <WithLoader isLoading={isRoomNetworkFetching}>
        <Form<RoomNetworkModalSchema>
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField name="name" label="Название" />
              </Grid>
              <Grid item xs={12}>
                <Select
                  multiple
                  name="rooms"
                  label="Привязанные румы"
                  enableAvatar={true}
                  options={roomsToSelectOptions(roomsResponse?.data || [])}
                />
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={isButtonDisabled}
              >
                Сохранить
              </Button>
            </Box>
          </DialogContent>
        </Form>
      </WithLoader>
    </Dialog>
  )
}
