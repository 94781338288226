import React from 'react'
import { DeepPartial, FieldValues, FormProvider, SubmitHandler, useForm, UseFormProps } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'

import { FormItemProps } from './FormItem'

export type FormChildrenElement = React.ReactElement<FormItemProps>;

export type ValidationSchemaItems<T> = Record<keyof T, Yup.AnySchema | string>;
export type ValidationSchemaObject<T> = Yup.ObjectSchema<ValidationSchemaItems<T>>;

export interface FormProps<T extends FieldValues> {
  children: FormChildrenElement | FormChildrenElement[];
  onSubmit?: SubmitHandler<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: any;
  initialValues?: DeepPartial<T>;
  mode?: UseFormProps['mode'];
  reValidateMode?: UseFormProps['reValidateMode'];
}

export function Form<TFieldsValues extends FieldValues>(props: FormProps<TFieldsValues>): JSX.Element {
  const { children, onSubmit, validationSchema, initialValues, reValidateMode = 'onChange', mode } = props

  const form = useForm<TFieldsValues>({
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
    ...(initialValues && { defaultValues: initialValues }),
    mode,
    reValidateMode,
  } as UseFormProps<TFieldsValues>)

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        noValidate
        {...onSubmit && { onSubmit: form.handleSubmit(onSubmit) }}
      >
        {children}
      </Box>
    </FormProvider>
  )
}
