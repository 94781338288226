import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { GoogleSheets } from '@/services/api/google-sheets'
import { GoogleSheetsUserRo, SetUserGoogleSheetsSettingsDto } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export type UseGoogleSetUserSettingsPayload = {
  userId: number;
} & SetUserGoogleSheetsSettingsDto;

export const useGoogleSetUserSettings = (
  props: UseMutationOptions<GoogleSheetsUserRo, AxiosErrorResponse, UseGoogleSetUserSettingsPayload>,
) => {
  const queryClient = useQueryClient()

  return useMutation<GoogleSheetsUserRo, AxiosErrorResponse, UseGoogleSetUserSettingsPayload>(
    ['google-sheets', 'settings', 'user'],
    async (payload: UseGoogleSetUserSettingsPayload) => {
      const { userId, ...params } = payload
      const response = await GoogleSheets.setUserSettings(userId, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(['google-sheets', 'settings', 'user', variables.userId])
        props?.onSuccess?.(data, variables, context)
      },
    })
}
