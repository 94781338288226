import * as yup from 'yup'

export interface LoginFormValues {
  username: string
  password: string
  isRememberMe: boolean
}

export const loginFormSchema = yup.object({
  username: yup.string().required('Необходимо указать логин'),
  password: yup.string().required('Необходимо указать пароль'),
  isRememberMe: yup.boolean(),
})
