import { useMemo } from 'react'

import {
  maxRoomNameLength,
  roomBalances,
} from '@/components/Containers/PlayerRoomsBalances/util.ts'
import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { ROOMS_LIMITATIONS } from '@/constants'
import { useSnackBar } from '@/context/SnackbarContext'
import { useGetPlayerRoomsBalancesByToken } from '@/services/hooks/cabinet/player/useGetPlayerRoomsBalancesByToken'
import { useGetPlayerRoomsSettingsList } from '@/services/hooks/cabinet/player/useGetPlayerRoomsSettingsList.ts'
import { useSetPlayerRoomBalances } from '@/services/hooks/cabinet/player/useSetPlayerRoomsBalancesByToken'
import { useCurrentUser } from '@/services/hooks/useCurrentUser.ts'
import { CurrencyHelper } from '@/utils/CurrencyHelper'
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'

const SYMBOL_WIDTH = 8
const ROOM_AVATAR_SIZE = 24

export const PlayerRoomBalances = () => {
  const { currentUser } = useCurrentUser()
  const { showSnackBar } = useSnackBar()
  const { defaultSortString } = useGetSort()
  const { data: roomsSettingsGeneral, isLoading: isRoomsGeneralLoading } =
    useGetPlayerRoomsSettingsList({
      userId: currentUser?.id ?? 0,
      params: {
        'category.some': ROOMS_LIMITATIONS.CABINET_BALANCES[0],
        sort: defaultSortString,
      },
    })
  const { data: roomsSettingsOther, isLoading: isRoomsOtherLoading } =
    useGetPlayerRoomsSettingsList({
      userId: currentUser?.id ?? 0,
      params: {
        'category.some': ROOMS_LIMITATIONS.CABINET_BALANCES[1],
        sort: defaultSortString,
      },
    })

  const {
    data: playerRoomsBalances,
    isLoading: isPlayerRoomBalancesLoading,
    dataUpdatedAt,
  } = useGetPlayerRoomsBalancesByToken({ refetchOnMount: 'always' })
  const setPlayerRoomBalances = useSetPlayerRoomBalances({
    onSuccess: () => showSnackBar('Успешно сохранено', 'success'),
    onError: () => showSnackBar('Не удалось сохранить', 'error'),
  })

  const handleBlur = (roomId: number, value: string) => {
    void setPlayerRoomBalances.mutate({
      balances: [
        {
          room: {
            id: roomId,
          },
          amount: +value || 0,
        },
      ],
    })
  }

  const roomsBalancesGeneral = useMemo(
    () => roomBalances(roomsSettingsGeneral, playerRoomsBalances),
    [playerRoomsBalances, roomsSettingsGeneral],
  )
  const roomsBalancesOther = useMemo(
    () => roomBalances(roomsSettingsOther, playerRoomsBalances),
    [playerRoomsBalances, roomsSettingsOther],
  )

  const maxRoomNameGeneralLength = useMemo(
    () => maxRoomNameLength(roomsSettingsGeneral),
    [roomsSettingsGeneral],
  )
  const maxRoomNameOtherLength = useMemo(
    () => maxRoomNameLength(roomsSettingsOther),
    [roomsSettingsOther],
  )

  return (
    <Box>
      <WithLoader
        isLoading={
          isRoomsGeneralLoading ||
          isPlayerRoomBalancesLoading ||
          isRoomsOtherLoading
        }
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }} mb={3}>
          <Typography>Актуальные балансы</Typography>
        </Box>
        <Grid container gap="64px">
          <Grid item xs={12} lg={5}>
            <Grid container spacing={1} key={dataUpdatedAt}>
              {(roomsBalancesGeneral || []).map(
                ({ room, amount, settings }) => (
                  <Grid item xs={12} key={room.id}>
                    <Paper>
                      <Box
                        width="100%"
                        maxWidth="100%"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: 2,
                        }}
                        p={1}
                        px={2}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            minWidth:
                              maxRoomNameGeneralLength * SYMBOL_WIDTH +
                              ROOM_AVATAR_SIZE,
                          }}
                        >
                          <ColorAvatar
                            title={room.name}
                            src={room.avatar}
                            sx={{
                              width: ROOM_AVATAR_SIZE,
                              height: ROOM_AVATAR_SIZE,
                            }}
                          />
                          <Typography
                            title={room.name}
                            variant="body2"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {room.name}
                          </Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box>
                          <FormControl>
                            <TextField
                              label={`Баланс (${room.currency})`}
                              defaultValue={amount}
                              fullWidth
                              placeholder={`0,00`}
                              size="small"
                              inputProps={{
                                type: 'number',
                              }}
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                sx: {
                                  minWidth: 40,
                                  maxWidth: 100,
                                },
                                onBlur: event =>
                                  handleBlur(room.id, event.target.value),
                                ...(room.currency && {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {CurrencyHelper.getCurrencySign(
                                        room.currency,
                                      )}
                                    </InputAdornment>
                                  ),
                                }),
                              }}
                            />
                          </FormControl>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ flex: 1 }}>
                          <FormControl fullWidth>
                            <TextField
                              title={settings.nickname}
                              disabled={true}
                              label="Никнейм"
                              value={settings.nickname}
                              fullWidth
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="standard"
                            />
                          </FormControl>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ flex: 1 }}>
                          <FormControl fullWidth>
                            <TextField
                              title={settings.transferInfo}
                              disabled={true}
                              label="Данные для перевода"
                              value={settings.transferInfo}
                              fullWidth
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="standard"
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ),
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Grid container spacing={1} key={dataUpdatedAt}>
              {(roomsBalancesOther || []).map(({ room, amount }) => (
                <Grid item xs={12} key={room.id}>
                  <Paper>
                    <Box
                      width="100%"
                      maxWidth="100%"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 2,
                      }}
                      p={1}
                      px={2}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          minWidth:
                            maxRoomNameOtherLength * SYMBOL_WIDTH +
                            ROOM_AVATAR_SIZE,
                        }}
                      >
                        <ColorAvatar
                          title={room.name}
                          src={room.avatar}
                          sx={{
                            width: ROOM_AVATAR_SIZE,
                            height: ROOM_AVATAR_SIZE,
                          }}
                        />
                        <Typography
                          title={room.name}
                          variant="body2"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {room.name}
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box>
                        <FormControl>
                          <TextField
                            label={`Баланс (${room.currency})`}
                            defaultValue={amount}
                            fullWidth
                            placeholder={`0,00`}
                            size="small"
                            inputProps={{
                              type: 'number',
                            }}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              sx: {
                                minWidth: 40,
                                maxWidth: '100%',
                              },
                              onBlur: event =>
                                handleBlur(room.id, event.target.value),
                              ...(room.currency && {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {CurrencyHelper.getCurrencySign(
                                      room.currency,
                                    )}
                                  </InputAdornment>
                                ),
                              }),
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </WithLoader>
    </Box>
  )
}
