import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { Typography } from '@mui/material'
import { format, formatDistance } from 'date-fns'
import { ru as dateFnsRuLocale } from 'date-fns/locale'

export const TableCreatedAt = (value: string) => {
  try {
    const currentDate = new Date()
    const rowDate = new Date(value)

    const date = format(rowDate, 'dd.MM.yyyy HH:mm')
    const ago = formatDistance(rowDate, currentDate, {
      locale: dateFnsRuLocale,
      addSuffix: true,
    })

    return (
      <TableColumn minWidth={120}>
        <Typography variant="body2">{date}</Typography>
        <Typography variant="caption" color="secondary">
          ({ago})
        </Typography>
      </TableColumn>
    )
  } catch (e) {
    return '-'
  }
}
