import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { GameRequestCreateDto, GameRequestCreateRo } from '@/services/data-contracts'
import { GameRequests } from '@/services/api/game-requests'
import { AxiosErrorResponse } from '@/types'

export const useCreateGameRequest = (props?: UseMutationOptions<GameRequestCreateRo, AxiosErrorResponse, GameRequestCreateDto>) => {
  const queryClient = useQueryClient()

  return useMutation<GameRequestCreateRo, AxiosErrorResponse, GameRequestCreateDto>(
    ['game-requests'],
    async (payload: GameRequestCreateDto) => {
      const response = await GameRequests.create(payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['game-requests'])
        props?.onSuccess?.(...args)
      },
    })
}
