import { CUSTOM_SETTING_FUNDS_REQUESTS_INFO } from '@/components/CustomSettings/constants'
import { CustomSettingWrap } from '@/components/CustomSettings/templates/CustomSettingWrap'
import { TextSetting } from '@/components/CustomSettings/templates/TextCustomSetting/Text.setting'

export const FundsRequestsInfoSetting = () => (
  <CustomSettingWrap>
    <TextSetting
      name={CUSTOM_SETTING_FUNDS_REQUESTS_INFO}
      title={`Блок рядом с запросами на перевод`}
    />
  </CustomSettingWrap>
)
