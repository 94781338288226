import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter.tsx'

export const buildOptions = (options: MultipleSelectOption[]) => {
  const optionsAdapter: MultipleSelectOption[] = []

  for (let i = 0; i < options.length; i++) {
    if (options[i].network) {
      const findOptionByNetwork = optionsAdapter.find(
        option => option?.network?.id === options[i]?.network?.id,
      )
      if (findOptionByNetwork) {
        continue
      }
    }
    optionsAdapter.push({
      ...options[i],
      id: options[i]?.network?.id
        ? `network_${options[i]?.network?.id}`
        : `room_${options[i].id}`,
      label: options[i]?.network?.name || options[i].label,
      avatar: options[i].avatar,
    })
  }

  return optionsAdapter
}

export const buildOptionByIds = (
  roomIds: number[],
  options: MultipleSelectOption[],
) => {
  const filteredOptions = options.filter(
    option => roomIds.indexOf(option.id as number) !== -1,
  )

  return buildOptions(filteredOptions)
}
