import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar'
import { RoomRo } from '@/services/data-contracts.ts'
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { MUIDataTableColumn, MUIDataTableState } from 'mui-datatables'

export type MultipleSelectOption = {
  id: string | number
  label: string
  avatar?: {
    color?: string
    src?: string
    alt: string
  }
  network?: RoomRo['network']
}

export type MultipleSelectProps = {
  enableAvatar?: boolean
}

export const createMultipleSelectFilter = (
  options: MultipleSelectOption[],
  initialProps: MultipleSelectProps = {},
) => {
  const props: MultipleSelectProps = {
    enableAvatar: false,
    ...initialProps,
  }

  return {
    display: (
      filterList: MUIDataTableState['filterList'],
      onChange: (
        val: string | string[],
        index: number,
        column: MUIDataTableColumn,
      ) => void,
      index: number,
      column: MUIDataTableColumn,
    ) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const value = filterList[index] as any
      const label: string = column.label || column.name

      return (
        <FormControl>
          <InputLabel shrink>{label}</InputLabel>
          <Select
            size="small"
            multiple
            value={value}
            renderValue={selected =>
              selected
                .map((id: number) => {
                  const candidate = options.find(option => option.id === id)
                  return candidate?.label || ''
                })
                .join(',')
            }
            onChange={event => {
              filterList[index] = event.target.value
              onChange(filterList[index], index, column)
            }}
            input={<OutlinedInput notched label={label} />}
          >
            {options.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox color="primary" checked={value.includes(option.id)} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {props.enableAvatar && option.avatar && (
                    <ColorAvatar
                      color={option?.avatar?.color}
                      variant="rounded"
                      src={option?.avatar?.src}
                      title={option.avatar.alt}
                      sx={{ width: '30px', height: '30px', fontSize: '12px' }}
                    />
                  )}
                  <ListItemText primary={option.label} />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
  }
}
