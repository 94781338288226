import { FundsRequests } from '@/services/api/funds-requests'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

type UseDeleteFundRequestPayload = {
  id: number
}

export const useDeleteFundRequest = (
  props?: UseMutationOptions<
    boolean,
    AxiosErrorResponse,
    UseDeleteFundRequestPayload
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteFundRequestPayload>(
    ['funds-requests'],
    async ({ id }: UseDeleteFundRequestPayload) => {
      const response = await FundsRequests.delete(id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['funds-requests'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
