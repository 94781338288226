import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'
import { GameRequests } from '@/services/api/game-requests.ts'

type UseDeleteGameRequestPayload = {
  id: number
}

export const useDeleteGameRequest = (
  props?: UseMutationOptions<
    boolean,
    AxiosErrorResponse,
    UseDeleteGameRequestPayload
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteGameRequestPayload>(
    ['game-requests'],
    async ({ id }: UseDeleteGameRequestPayload) => {
      const response = await GameRequests.delete(id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['game-requests'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
