import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { Typography } from '@mui/material'

export const TableUser = (value?: string | null) => {
  if (!value) {
    return 'Не указан'
  }

  return (
    <TableColumn minWidth={70}>
      <Typography variant="body2">{value}</Typography>
    </TableColumn>
  )
}
