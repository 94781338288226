import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControl, FormControlProps, FormHelperText, InputLabel } from '@mui/material'

type RenderComponent = React.ReactElement;
type RenderCallback = (params: {
  name: string;
  label: string;
  ref: React.Ref<HTMLElement>;
  onBlur: React.FocusEventHandler;
  onChange: React.ChangeEventHandler;
}) => React.ReactElement;
type RenderCallbackBinded = () => React.ReactElement;

export interface FormItemProps extends Omit<FormControlProps, 'children'> {
  id?: string;
  name: string;
  label?: string;
  labelPosition: 'in-component' | 'in-input-label' | 'none'
  top?: string;
  bottom?: string;
  children: RenderComponent | RenderCallback;
}

export interface FormItemChildrenProps {
  name: string;
  label?: string;
  formItemProps?: Omit<FormItemProps, 'children' | 'name'>;
}

export const FormItem = ({ children, name, label, labelPosition, id, ...props }: FormItemProps) => {
  const { register, formState, getFieldState } = useFormContext()

  const { onBlur, onChange, name: registerName, ref } = register(name)

  const field = getFieldState(name, formState)
  const isError = Boolean(field.error)

  const reactHookFormProps = {
    'aria-label': label,
    onBlur,
    onChange,
    id,
    'name': registerName,
    ref,
    ...(labelPosition === 'in-component' && { label }),
    error: isError,
  }

  const renderComponent: RenderComponent | null = children && 'props' in children
    ? React.cloneElement(children, { ...children.props, ...reactHookFormProps })
    : <></>

  const renderCallback: RenderCallbackBinded | null = typeof children === 'function'
    ? children.bind(null, reactHookFormProps as React.ReactElement['props'])
    : null

  const helperText = field.error?.message ? field.error.message : props?.bottom

  return (
    <FormControl {...props} error={isError}>
      {labelPosition === 'in-input-label' && (
        <InputLabel shrink={true}>{label || ''}</InputLabel>
      )}
      {renderCallback ? renderCallback() : renderComponent}
      {helperText && <FormHelperText id={`${id || name}-text`} sx={{ ml: 0 }}>{helperText}</FormHelperText>}
    </FormControl>
  )
}
