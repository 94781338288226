import { memo, useMemo, useState } from 'react'

import { GROUP_STYLE_PARAMS } from '@/components/Containers/UsersRoomsSettingsTable/constants.ts'
import { Form } from '@/components/Forms/Form.tsx'
import { TextField } from '@/components/Forms/TextField.tsx'
import { useSnackBar } from '@/context/SnackbarContext.tsx'
import {
  PlayerRoomsSettingsRo,
  RoomSingleRo,
} from '@/services/data-contracts.ts'
import { useSetPlayerRoomsSettings } from '@/services/hooks/cabinet/admin/useSetPlayerRoomsSettings.tsx'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Box, ButtonGroup, IconButton } from '@mui/material'

export type THandleSubmit = (data: {
  nickname?: string
  transferInfo?: string
}) => void

export interface RoomCellProps {
  row: PlayerRoomsSettingsRo
  roomInfo: RoomSingleRo
}

const RoomCell = ({ roomInfo, row }: RoomCellProps) => {
  const { showSnackBar } = useSnackBar()
  const playerRoomInfo = row?.rooms[roomInfo.id]
  const { nickname, transferInfo } = playerRoomInfo ?? {}
  const [disabled, setDisabled] = useState(true)
  const setPlayerRoomsSettings = useSetPlayerRoomsSettings({
    onSuccess: () =>
      showSnackBar('Настройки румов успешно сохранены', 'success'),
    onError: () =>
      showSnackBar('Не удалось сохранить настройки румов', 'error'),
  })
  const roomsArray = useMemo(() => Object.values(row?.rooms), [row?.rooms])

  const handleSubmit: THandleSubmit = formData => {
    const settings = roomsArray
      .filter(room => room.room.id !== playerRoomInfo?.room?.id)
      .map(({ nickname, transferInfo, room }) => ({
        nickname,
        transferInfo,
        room: {
          id: room.id,
          name: room?.name,
          avatar: room.avatar.location,
        },
      }))

    setPlayerRoomsSettings.mutate({
      userId: row.id,
      settings: [
        ...settings,
        {
          room: {
            id: roomInfo.id,
            // @ts-ignore
            name: roomInfo.name ?? '',
            avatar: roomInfo.avatar.location,
          },
          ...formData,
        },
      ],
    })
  }

  if (disabled) {
    return (
      <Box
        display="flex"
        onDoubleClick={() => setDisabled(false)}
        gap={GROUP_STYLE_PARAMS.gap}
      >
        <Box width={GROUP_STYLE_PARAMS.firstCell}>
          {nickname || 'Нет данных'}
        </Box>
        <Box width={GROUP_STYLE_PARAMS.secondCell}>
          {transferInfo || 'Нет данных'}
        </Box>
      </Box>
    )
  }

  return (
    <Box position="relative" display="flex">
      <Form
        onSubmit={data => {
          handleSubmit?.(data)
          setDisabled(true)
        }}
        initialValues={{
          nickname,
          transferInfo,
        }}
      >
        <Box display="flex" gap={GROUP_STYLE_PARAMS.gap}>
          <Box width={GROUP_STYLE_PARAMS.firstCell}>
            <TextField
              label="Никнейм"
              name="nickname"
              textFieldProps={{
                variant: 'outlined',
                size: 'small',
              }}
              placeholder="Введите никнейм"
            />
          </Box>
          <Box width={GROUP_STYLE_PARAMS.secondCell}>
            <TextField
              label="Данные для перевода"
              name="transferInfo"
              textFieldProps={{
                variant: 'outlined',
                size: 'small',
              }}
              placeholder="Введите данные для перевода"
            />
          </Box>
        </Box>
        <ButtonGroup size="small">
          <IconButton color="success" type="submit">
            <DoneIcon />
          </IconButton>
          <IconButton color="error" onClick={() => setDisabled(true)}>
            <CloseIcon />
          </IconButton>
        </ButtonGroup>
      </Form>
    </Box>
  )
}

export default memo(RoomCell)
