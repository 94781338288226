import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { UserReportCreateDto, UserReportCreateRo } from '@/services/data-contracts'
import { UserReports } from '@/services/api/user-reports'
import { AxiosErrorResponse } from '@/types'

export const useCreateUserReport = (props?: UseMutationOptions<UserReportCreateRo, AxiosErrorResponse, UserReportCreateDto>) => {
  const queryClient = useQueryClient()

  return useMutation<UserReportCreateRo, AxiosErrorResponse, UserReportCreateDto>(
    ['user-reports'],
    async (params: UserReportCreateDto) => {
      const response = await UserReports.create(params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-reports'])
        props?.onSuccess?.(...args)
      },
    })
}
