import * as yup from 'yup'

export type FundsRequestsFormSchema = {
  room: {
    id: number
  }
  amount: number
}

export const fundsRequestsFormSchema = yup.object({
  room: yup.object({
    id: yup.number(),
  }),
  amount: yup.number().typeError('Обязательное поле').required('Обязательное поле'),
})
