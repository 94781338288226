import { Box } from '@mui/material'

import { DefaultLayout } from '@/layouts/DefaultLayout/DefaultLayout'
import { LoginForm } from '@/components/Containers/LoginForm/LoginForm'

export const LoginPage = () => {
  return (
    <DefaultLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mx: 'auto',
          my: 'auto',
          rowGap: '12px',
          maxWidth: '600px',
          minWidth: '300px',
        }}
      >
        <LoginForm />
      </Box>
    </DefaultLayout>
  )
}
