import { SelectOption } from '@/components/Forms/Select'
import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter'

export type UserRoleValue = 1 | 2 | 4 | 7

export const UserRole: Record<string, UserRoleValue> = {
  player: 1,
  coach: 2,
  selector: 4,
  admin: 7,
}

export const UserRoleMap: Record<keyof typeof UserRole, string> = {
  [UserRole.player]: 'Игрок',
  [UserRole.coach]: 'Тренер',
  [UserRole.selector]: 'Селектор',
  [UserRole.admin]: 'Админ',
}

export enum RoomCategoryEnum {
  PAYMENT_SYSTEM = 'payment_system',
  ROOM = 'room',
  INTERNAL_TRANSFER = 'internal_transfer',
  BONUS = 'bonus',
  TICKET = 'ticket',
  TECHNICAL = 'technical',
  TRANSIT = 'transit',
  OTHER = 'other',
}

export enum RoomFeatureEnum {
  // Обязательная модерация запросов на игру для игроков из РФ
  MODERATE_FUNDS_REQUESTS_FOR_PLAYERS_FROM_RUSSIA = 'moderate_funds_requests_for_players_from_russia',

  // Обязательная модерация запросов на игру для игроков из Беларуси
  MODERATE_FUNDS_REQUESTS_FOR_PLAYERS_FROM_BELARUS = 'moderate_funds_requests_for_players_from_belarus',
}

export enum FundsRequestStatusEnum {
  PENDING = 'pending',
  AUTO_PENDING = 'auto_pending',
  AUTO_APPROVED = 'auto_approved',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export const getFundsRequestStatusTranslate = (
  status: FundsRequestStatusEnum,
  isAdmin: boolean,
): string => {
  const translateMap: Record<FundsRequestStatusEnum, string> = {
    [FundsRequestStatusEnum.PENDING]: isAdmin
      ? 'На модерации'
      : 'Ожидает подтверждения админа',
    [FundsRequestStatusEnum.AUTO_PENDING]: isAdmin
      ? 'На модерации (автоматически)'
      : 'Ожидает подтверждения админа',
    [FundsRequestStatusEnum.APPROVED]: isAdmin ? 'Прошел модерацию' : 'Активен',
    [FundsRequestStatusEnum.AUTO_APPROVED]: isAdmin
      ? 'Прошел модерацию (автоматически)'
      : 'Активен',
    [FundsRequestStatusEnum.DECLINED]: isAdmin
      ? 'Не прошел модерацию'
      : 'Не активен',
  }

  return translateMap[status]
}

export const roomCategoryMap: Record<RoomCategoryEnum, string> = {
  [RoomCategoryEnum.PAYMENT_SYSTEM]: 'Платежная система',
  [RoomCategoryEnum.ROOM]: 'Рум',
  [RoomCategoryEnum.INTERNAL_TRANSFER]: 'Внутренние переводы',
  [RoomCategoryEnum.BONUS]: 'Бонус',
  [RoomCategoryEnum.TICKET]: 'Тикет',
  [RoomCategoryEnum.TECHNICAL]: 'Технический',
  [RoomCategoryEnum.TRANSIT]: 'Транзит',
  [RoomCategoryEnum.OTHER]: 'Другое',
}

export const roomFeatureMap: Record<RoomFeatureEnum, string> = {
  [RoomFeatureEnum.MODERATE_FUNDS_REQUESTS_FOR_PLAYERS_FROM_RUSSIA]:
    'Игроки из РФ - модерация запросов на средства',
  [RoomFeatureEnum.MODERATE_FUNDS_REQUESTS_FOR_PLAYERS_FROM_BELARUS]:
    'Игроки из Беларуси - модерация запросов на средства',
}

export const ROOMS_LIMITATIONS = {
  PROJECT_TRANSFERS: [
    RoomCategoryEnum.INTERNAL_TRANSFER,
    RoomCategoryEnum.PAYMENT_SYSTEM,
    RoomCategoryEnum.OTHER,
  ],
  PERSONAL_TRANSFERS: [
    RoomCategoryEnum.ROOM,
    RoomCategoryEnum.INTERNAL_TRANSFER,
    RoomCategoryEnum.PAYMENT_SYSTEM,
    RoomCategoryEnum.OTHER,
  ],
  FUNDS_REQUESTS: [
    RoomCategoryEnum.INTERNAL_TRANSFER,
    RoomCategoryEnum.PAYMENT_SYSTEM,
  ],
  TOURNAMENTS: [RoomCategoryEnum.ROOM],
  ADMIN_USER_TOTAL_TABLE: [
    RoomCategoryEnum.ROOM,
    RoomCategoryEnum.PAYMENT_SYSTEM,
    RoomCategoryEnum.BONUS,
    RoomCategoryEnum.TICKET,
    RoomCategoryEnum.TRANSIT,
    RoomCategoryEnum.TECHNICAL,
  ],
  USER_ROOMS_BALANCES: [
    RoomCategoryEnum.PAYMENT_SYSTEM,
    RoomCategoryEnum.ROOM,
    RoomCategoryEnum.BONUS,
    RoomCategoryEnum.TICKET,
    RoomCategoryEnum.TRANSIT,
    RoomCategoryEnum.OTHER,
    RoomCategoryEnum.TECHNICAL,
  ],
  CABINET_BALANCES: [
    [RoomCategoryEnum.PAYMENT_SYSTEM, RoomCategoryEnum.ROOM],
    [
      RoomCategoryEnum.BONUS,
      RoomCategoryEnum.TICKET,
      RoomCategoryEnum.TRANSIT,
      RoomCategoryEnum.OTHER,
      RoomCategoryEnum.TECHNICAL,
    ],
  ],
}

export const roomCategorySelectOptions = Object.entries(roomCategoryMap).map(
  ([id, label]) => ({
    id,
    label,
  }),
)

export const roomFeaturesSelectOptions = Object.entries(roomFeatureMap).map(
  ([id, label]) => ({
    id,
    label,
  }),
)

export enum TransferPurposeEnum {
  TRANSFER_BETWEEN_PLAYERS = 'transfer_between_players',
  TRANSFER_TO_FUNDS_REQUEST = 'transfer_to_funds_request',
  FUNDS_DEPOSIT = 'funds_deposit',
  FUNDS_WITHDRAWAL = 'funds_withdrawal',
  PLAY_BY_YOURSELF_FUNDS = 'play_by_yourself_funds',
  TRANSFER_TO_FRIEND = 'transfer_to_friend',
  TRANSFER_FROM_FRIEND = 'transfer_from_friend',
  PAYMENT = 'payment',
}

export const transferPurposeMap: Record<string, string> = {
  [TransferPurposeEnum.TRANSFER_BETWEEN_PLAYERS]: 'Перевод между игроками',
  [TransferPurposeEnum.TRANSFER_TO_FUNDS_REQUEST]: 'Запрос на перевод',

  [TransferPurposeEnum.FUNDS_DEPOSIT]: 'Депозит',
  [TransferPurposeEnum.FUNDS_WITHDRAWAL]: 'Вывод',
  [TransferPurposeEnum.PLAY_BY_YOURSELF_FUNDS]: 'Игра от себя',
  [TransferPurposeEnum.TRANSFER_TO_FRIEND]: 'Перевод другу',
  [TransferPurposeEnum.TRANSFER_FROM_FRIEND]: 'Перевод от друга',
  [TransferPurposeEnum.PAYMENT]: 'Оплата',
}

export const transferPurposeProjectSelectOptions = [
  {
    id: TransferPurposeEnum.TRANSFER_BETWEEN_PLAYERS,
    label: transferPurposeMap[TransferPurposeEnum.TRANSFER_BETWEEN_PLAYERS],
  },
  {
    id: TransferPurposeEnum.TRANSFER_TO_FUNDS_REQUEST,
    label: transferPurposeMap[TransferPurposeEnum.TRANSFER_TO_FUNDS_REQUEST],
  },
]

export const transferPurposePersonalSelectOptions = [
  {
    id: TransferPurposeEnum.FUNDS_DEPOSIT,
    label: transferPurposeMap[TransferPurposeEnum.FUNDS_DEPOSIT],
  },
  {
    id: TransferPurposeEnum.FUNDS_WITHDRAWAL,
    label: transferPurposeMap[TransferPurposeEnum.FUNDS_WITHDRAWAL],
  },
  {
    id: TransferPurposeEnum.PLAY_BY_YOURSELF_FUNDS,
    label: transferPurposeMap[TransferPurposeEnum.PLAY_BY_YOURSELF_FUNDS],
  },
  {
    id: TransferPurposeEnum.TRANSFER_TO_FRIEND,
    label: transferPurposeMap[TransferPurposeEnum.TRANSFER_TO_FRIEND],
  },
  {
    id: TransferPurposeEnum.TRANSFER_FROM_FRIEND,
    label: transferPurposeMap[TransferPurposeEnum.TRANSFER_FROM_FRIEND],
  },
  {
    id: TransferPurposeEnum.PAYMENT,
    label: transferPurposeMap[TransferPurposeEnum.PAYMENT],
  },
]

export const gameRequestsStatusMap = {
  pending: 'На рассмотрении',
  declined: 'От себя',
  declined_played: 'От себя (сыгран)',
  declined_not_played: 'От себя (не сыгран)',
  approved: 'В пакет',
}

export const gameRequestsStatusMultipleSelectOptions: MultipleSelectOption[] = [
  { id: 'pending', label: gameRequestsStatusMap.pending },
  { id: 'declined', label: gameRequestsStatusMap.declined },
  { id: 'declined_played', label: gameRequestsStatusMap.declined_played },
  {
    id: 'declined_not_played',
    label: gameRequestsStatusMap.declined_not_played,
  },
  { id: 'approved', label: gameRequestsStatusMap.approved },
]

export const USER_REPORT_HANDS_COUNT_FIELD = 'hands_count'
export const USER_REPORT_EVBB_100_MONTH_FIELD = 'evbb_100_month'
export const USER_REPORT_BB_100_MONTH_FIELD = 'bb_100_month'
export const USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD =
  'evbb_100_month_with_filter'
export const USER_REPORT_EVBB_100_100K_HANDS_FIELD = 'evbb_100_100k_hands'
export const USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD =
  'evbb_100_100k_hands_with_filter'
export const USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD =
  'evbb_100_100k_hands_in_late'

export const userReportFieldMap: Record<string, string> = {
  [USER_REPORT_HANDS_COUNT_FIELD]: 'Количество рук за месяц',
  [USER_REPORT_EVBB_100_MONTH_FIELD]: 'EVbb/100 за месяц',
  [USER_REPORT_BB_100_MONTH_FIELD]: 'bb/100 за месяц',
  [USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD]:
    'EVbb/100 за месяц с фильтром',
  [USER_REPORT_EVBB_100_100K_HANDS_FIELD]: 'EVbb/100 на 100к рук',
  [USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD]:
    'EVbb/100 на 100к рук с фильтром',
  [USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD]:
    'EVbb/100 на 100к рук в лейтах',
}

export const REVIEW_COMMENT_TECHNICAL_FIELD = 'comment_technical'
export const REVIEW_COMMENT_LOYALTY_FIELD = 'comment_loyalty'
export const REVIEW_COMMENT_RECOMMENDATIONS_FIELD = 'comment_recommendations'
export const REVIEW_SCORE_TECHNICAL_FIELD = 'score_technical'
export const REVIEW_SCORE_MENTAL_FIELD = 'score_mental'
export const REVIEW_SCORE_SELECT_FIELD = 'score_select'
export const REVIEW_PROMOTION_OPINION_FIELD = 'promotion_opinion'
export const REVIEW_USER_RANK_HIDDEN_FIELD = 'user_rank'

export const reviewFieldMap: Record<string, string> = {
  [REVIEW_COMMENT_TECHNICAL_FIELD]: 'Техническая часть игрока',
  [REVIEW_COMMENT_LOYALTY_FIELD]:
    'Отношение к тренерам/активность отношение к проекту',
  [REVIEW_COMMENT_RECOMMENDATIONS_FIELD]: 'Общие рекомендации',
  [REVIEW_SCORE_TECHNICAL_FIELD]: 'Оценка технической части',
  [REVIEW_SCORE_MENTAL_FIELD]: 'Оценка ментала',
  [REVIEW_SCORE_SELECT_FIELD]: 'Оценка селекта',
  [REVIEW_PROMOTION_OPINION_FIELD]: 'Мнение о повышении',
  [REVIEW_USER_RANK_HIDDEN_FIELD]: 'Ранг игрока на тот момент',
}

export const reviewScoreSelectOptions: SelectOption[] = [
  { id: 'Отрицательная', label: 'Отрицательная' },
  { id: 'Нейтральная', label: 'Нейтральная' },
  { id: 'Положительная', label: 'Положительная' },
]

export const reviewPromotionOpinionSelectOptions: SelectOption[] = [
  { id: 'Повысить', label: 'Повысить' },
  { id: 'Оставить', label: 'Оставить' },
  { id: 'Понизить', label: 'Понизить' },
  { id: 'Кикнуть', label: 'Кикнуть' },
]

export const SMALL_INT_MIN_RANGE = -32768
export const SMALL_INT_MAX_RANGE = 32767

export const dayOfWeekTranslatedMap: Record<number, string> = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
} as const

export const dayOfWeekSelectOptions = Object.entries(
  dayOfWeekTranslatedMap,
).map(([id, label]) => ({
  id: +id,
  label,
}))
