import { useEffect, useState } from 'react'

import { tournamentFlags } from '@/components/Containers/TournamentRulesTable/constants.ts'
import { buildOptionByIds } from '@/components/Containers/TournamentRulesTable/utils/buildOptions.ts'
import { createMultipleSelectWithNetworkFilter } from '@/components/Containers/TournamentRulesTable/utils/createMultipleSelectWithNetworkFilter.tsx'
import { prettifyTournamentName } from '@/components/Containers/TournamentRulesTable/utils/prettifyTournamentName.ts'
import {
  TOURNAMENT_COLOR_DARK_MAP,
  TOURNAMENT_COLOR_MAP,
  TOURNAMENT_COLOR_SELECT_OPTIONS,
} from '@/components/Containers/TournamentsTable/constants.ts'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn.tsx'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle.tsx'
import { createMultipleSelectFilter } from '@/components/Tables/filters/createMultipleSelectFilter.tsx'
import { createRangeFilter } from '@/components/Tables/filters/createRangeFilter.tsx'
import { useGetRoomsSelectValues } from '@/components/Tables/hooks/useGetRoomsSelectValues.ts'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels.tsx'
import {
  dayOfWeekSelectOptions,
  dayOfWeekTranslatedMap,
  ROOMS_LIMITATIONS,
} from '@/constants.ts'
import { useTheme } from '@/context/ThemeContext.tsx'
import { TournamentRulesControllerFindAllParams } from '@/services/data-contracts.ts'
import { useGetFilterByName } from '@/services/hooks/tournament-filters/useGetFilterByName.ts'
import { useSetFilter } from '@/services/hooks/tournament-filters/useSetFilter.ts'
import { useGetTournamentRules } from '@/services/hooks/tournament-rules/useGetTournamentRules.ts'
import { Box, Button, Typography } from '@mui/material'
import { formatDistance } from 'date-fns'
import { ru as dateFnsRuLocale } from 'date-fns/locale'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

interface TournamentRulesTableProps {
  rank?: number
}

const FILTERS_DEFAULT: TournamentRulesControllerFindAllParams = {
  'startTime.from': undefined,
  'startTime.to': undefined,
  'buyIn.from': undefined,
  'buyIn.to': undefined,
  'room.in': undefined,
  'color.in': undefined,
  'timezone.equal': USER_TIMEZONE,
}

export const TournamentRulesTable = ({ rank }: TournamentRulesTableProps) => {
  const { theme } = useTheme() ?? {}
  const [filters, setFilters] =
    useState<TournamentRulesControllerFindAllParams>(FILTERS_DEFAULT)
  const [canSave, setCanSave] = useState(false)
  const saveFilter = useSetFilter()
  const { data: savedFilter } = useGetFilterByName({ name: 'rules' })
  const tableSort = useTableSort(
    [
      ['dayOfWeek', 'ASC'],
      ['startTime', 'ASC'],
    ],
    ([changedColumn, direction]) => {
      switch (changedColumn) {
        case 'dayOfWeek':
          return [
            [changedColumn, direction],
            ['startTime', direction],
          ]
        case 'startTime':
          return [
            [changedColumn, direction],
            ['dayOfWeek', direction],
          ]
        default:
          return [[changedColumn, direction]]
      }
    },
  )
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()

  const { data: tournaments, isFetching: isTournamentsFetching } =
    useGetTournamentRules({
      ...tablePagination.queryParams,
      ...tableSort.queryParams,
      ...(rank !== undefined && {
        'ranks.some': [rank],
      }),
      ...filters,
    })

  const { isRoomsFetching, roomsSelectValues } = useGetRoomsSelectValues({
    category: ROOMS_LIMITATIONS.TOURNAMENTS,
  })

  const handleFilterConfirm: MUIDataTableOptions['onFilterConfirm'] =
    filterList => {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const [dayOfWeek, startTime, room, title, buyIn, color] = filterList

      tablePagination.helpers.resetPage()
      const roomGroup = room.reduce((acc, room) => {
        if (room.startsWith('room')) {
          acc.push(+room.replace('room_', ''))
        } else {
          const networkId = +room.replace('network_', '')
          const roomIds = roomsSelectValues.reduce((acc, room) => {
            if (room?.network?.id === networkId) {
              acc.push(room.id as number)
            }
            return acc
          }, [] as number[])
          acc = [...acc, ...roomIds]
        }
        return acc
      }, [] as number[])

      setFilters({
        'title.like': title?.[0] ? title?.[0] : undefined,
        'dayOfWeek.in': dayOfWeek ? (dayOfWeek || []).map(Number) : undefined,
        'startTime.from': startTime?.[0] ? startTime?.[0] : undefined,
        'startTime.to': startTime?.[1] ? startTime[1] : undefined,
        'buyIn.from': buyIn?.[0] ? +buyIn[0] : undefined,
        'buyIn.to': buyIn?.[1] ? +buyIn[1] : undefined,
        'room.in': room ? roomGroup || [] : undefined,
        'color.in': color ? (color || []).map(Number) : undefined,
        'timezone.equal': USER_TIMEZONE,
      })
    }

  const [data, count] = tournaments
    ? [tournaments.data, tournaments.count]
    : [[], 0]

  const isFetching = isRoomsFetching || isTournamentsFetching

  useEffect(() => {
    if (canSave) {
      saveFilter.mutate({ name: 'rules', value: filters })
      setCanSave(false)
    }
  }, [canSave, filters, saveFilter])

  useEffect(() => {
    if (savedFilter) {
      setFilters({
        ...(savedFilter?.value as typeof filters),
        'timezone.equal': USER_TIMEZONE,
      })
    }
  }, [savedFilter])

  return (
    <MUIDataTable
      title={
        <>
          <Typography variant="h6">Регулярные турниры</Typography>
          <Typography variant="caption">
            Ваш часовой пояс: {USER_TIMEZONE}
          </Typography>
        </>
      }
      columns={[
        {
          name: 'dayOfWeek',
          label: 'День',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            filter: true,
            // @ts-ignore
            filterType: 'custom',
            filterOptions: {
              ...createMultipleSelectFilter(dayOfWeekSelectOptions, {
                enableAvatar: false,
              }),
              fullWidth: true,
            },
            // @ts-ignore
            filterList: filters['dayOfWeek.in'] ?? [],
            customBodyRender: (value: number) => {
              return (
                <TableColumn>
                  {dayOfWeekTranslatedMap?.[value] || 'Не указан'}
                </TableColumn>
              )
            },
          },
        },
        {
          name: 'startTime',
          label: 'Время',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            filter: true,
            filterType: 'custom',
            filterOptions: createRangeFilter({
              type: 'time',
            }),
            filterList: [
              filters?.['startTime.from'] ? filters?.['startTime.from'] : '',
              filters?.['startTime.to'] ? filters?.['startTime.to'] : '',
            ],
            customBodyRender: (_: string, tableMeta) => {
              const row = data[tableMeta.rowIndex]
              const time = (() => {
                const formatted = {
                  startTime: row.startTime,
                  endTime: row.endTime,
                  distance: row.avDurationSeconds
                    ? formatDistance(0, row.avDurationSeconds * 1000, {
                        includeSeconds: true,
                        locale: dateFnsRuLocale,
                      })
                    : null,
                }

                if (!formatted.endTime) {
                  return formatted.startTime
                }

                return (
                  <>
                    {formatted.startTime}-{formatted.endTime}
                    {formatted.distance && (
                      <>
                        <br />({formatted.distance})
                      </>
                    )}
                  </>
                )
              })()

              return <TableColumn>{time}</TableColumn>
            },
          },
        },
        {
          name: 'room.name',
          label: 'Рум',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            customBodyRender: (_: string, tableMeta) => {
              const row = data[tableMeta.rowIndex]

              const roomAvatar = row.room?.avatar?.location
              const roomName = row.room?.network?.name || row.room?.name

              return (
                <TableColumn
                  minWidth={70}
                  maxWidth={100}
                  minHeight={46.5}
                  sx={{ alignItems: 'center', display: 'flex' }}
                >
                  <TableImageTitle title={roomName} src={roomAvatar} />
                </TableColumn>
              )
            },
            filter: true,
            filterType: 'custom',
            filterOptions: {
              ...createMultipleSelectWithNetworkFilter(roomsSelectValues, {
                enableAvatar: true,
              }),
              fullWidth: true,
            },
            // @ts-ignore
            filterList: buildOptionByIds(
              (filters['room.in'] ?? []).map(item => item),
              roomsSelectValues,
            ).map(item => item.id),
          },
        },
        {
          name: 'title',
          label: 'Турнир',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            filter: true,
            filterType: 'custom',
            filterOptions: createRangeFilter({
              type: 'text',
              showSingleFilter: true,
              inputProps: {
                single: {
                  placeholder: 'Поиск по названию',
                },
              },
            }),
            filterList: [filters?.['title.like'] ?? ''],
            customBodyRenderLite: dataIndex => {
              const row = data?.[dataIndex]
              return (
                <TableColumn maxWidth={260}>
                  <Box display="flex" alignItems="center" gap="4px">
                    <Typography>
                      {prettifyTournamentName(row?.title as string) ||
                        'Не указан'}
                    </Typography>
                    <Box display="flex" gap="4px" marginLeft="4px">
                      {row?.flags.map((flag, index) => {
                        const flagObject =
                          tournamentFlags[flag as keyof typeof tournamentFlags]
                        if (!flagObject?.value) {
                          return null
                        }

                        return (
                          <Box
                            style={flagObject?.style}
                            padding="3px"
                            borderRadius="2px"
                            title={flagObject?.title}
                            key={index}
                          >
                            {flagObject?.value}
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                </TableColumn>
              )
            },
          },
        },
        {
          name: 'buyIn',
          label: 'BI',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            customBodyRender: value => (
              <TableColumn minWidth={30} maxWidth={30}>
                {value || 'Не указан'}
              </TableColumn>
            ),
            filterType: 'custom',
            filterOptions: createRangeFilter({
              type: 'number',

              inputProps: {
                from: {
                  placeholder: 'От',
                },
                to: {
                  placeholder: 'До',
                },
              },
            }),
            filterList: [
              filters?.['buyIn.from']?.toString() || '',
              filters?.['buyIn.to']?.toString() || '',
            ],
          },
        },
        {
          name: 'color',
          label: 'Надежность турнира',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            display: false,
            filterType: 'custom',
            filterOptions: {
              ...createMultipleSelectFilter(
                TOURNAMENT_COLOR_SELECT_OPTIONS.map(item => ({
                  ...item,
                  id: +item.id,
                })),
                {
                  enableAvatar: true,
                },
              ),
              fullWidth: true,
            },
            // @ts-ignore
            filterList: filters['color.in'] ?? [],
          },
        },
      ]}
      data={data}
      options={{
        ...tableDefault.options,
        ...tablePagination.options,
        ...tableSort.options,
        count,
        tableBodyHeight: '500px',
        filter: true,
        confirmFilters: false,
        onFilterConfirm: handleFilterConfirm,
        customFilterDialogFooter: (filterList, applyNewFilters) => {
          return (
            <Box display="flex" gap="16px" alignItems="center">
              {tableDefault.options?.customFilterDialogFooter?.(
                filterList,
                applyNewFilters,
              )}
              <Box marginTop="24px">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleFilterConfirm(filterList)
                    applyNewFilters?.(filterList)
                    setCanSave(true)
                  }}
                >
                  Сохранить и применить
                </Button>
              </Box>
            </Box>
          )
        },
        onFilterChange: (_changedColumn, filterList, type) => {
          if (type === 'reset') {
            handleFilterConfirm(filterList)
          }
        },
        textLabels: createTextLabels({
          isLoading: isFetching,
          textLabels: {
            body: {
              noMatch: 'Регулярных турниров не найдено',
            },
          },
        }),

        setRowProps: (_, idx) => {
          const row = data[idx]
          const key = row.color as keyof typeof TOURNAMENT_COLOR_MAP
          return {
            style: {
              background:
                theme === 'light'
                  ? TOURNAMENT_COLOR_MAP[key] || undefined
                  : TOURNAMENT_COLOR_DARK_MAP[key] || undefined,
            },
          }
        },
      }}
      components={{
        ...tableDefault.components,
      }}
    />
  )
}
