import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'
import { UserReports } from '@/services/api/user-reports'

type UseDeleteUserReportPayload = {
  id: number;
}

export const useDeleteUserReport = (props?: UseMutationOptions<boolean, AxiosErrorResponse, UseDeleteUserReportPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteUserReportPayload>(
    ['user-reports'],
    async (payload: UseDeleteUserReportPayload) => {
      const response = await UserReports.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-reports'])
        props?.onSuccess?.(...args)
      },
    })
}
