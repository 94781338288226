import { Request } from '../Request'
import { AxiosResponse } from 'axios'
import { RoomNetworkCreateDto, RoomNetworkRo, RoomNetworkEditDto, RoomNetworksDataRo, RoomNetworksControllerGetParams } from '@/services/data-contracts'

export const RoomNetworks = {
  create: async (params: RoomNetworkCreateDto) => {
    return Request.post<RoomNetworkCreateDto, AxiosResponse<RoomNetworkRo>>('/room-networks', params)
  },
  get: async (params?: RoomNetworksControllerGetParams) => {
    return Request.get<RoomNetworksControllerGetParams, AxiosResponse<RoomNetworksDataRo>>('/room-networks', { params })
  },
  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<RoomNetworkRo>>(`/room-networks/${id}`)
  },
  patch: async (id: number, params: RoomNetworkEditDto) => {
    return Request.patch<RoomNetworkEditDto, AxiosResponse<RoomNetworkRo>>(`/room-networks/${id}`, params)
  },
  delete: async (id: number) => {
    return Request.delete<undefined, AxiosResponse<boolean>>(`/room-networks/${id}`)
  },
}
