import { SMALL_INT_MAX_RANGE, SMALL_INT_MIN_RANGE } from '@/constants'
import * as yup from 'yup'

import { RoomCategory, RoomCurrency, RoomFeature } from '@/types'

export type RoomAvatar = {
  id: number
}

export type RoomModalSchema = {
  name: string
  category: RoomCategory[]
  features: RoomFeature[]
  currency: RoomCurrency
  sort: number
  avatar?: RoomAvatar
}

export const roomModalSchema = {
  generate: () => {
    return yup.object().shape({
      name: yup
        .string()
        .required('Обязательное поле')
        .max(255, 'Максимальная длина имени 255 символов'),
      currency: yup
        .string()
        .oneOf(['USD', 'EUR'], 'Выберите корректную валюту')
        .required(),
      sort: yup
        .number()
        .required('Заполните поле')
        .integer('Должно быть целым числом')
        .min(SMALL_INT_MIN_RANGE)
        .max(SMALL_INT_MAX_RANGE),
      avatar: yup
        .object({
          id: yup.number().optional().nullable(),
        })
        .optional(),
    })
  },
}
