import { Form } from '@/components/Forms/Form'
import {
  CreateTransferToFundsRequestModalSchema,
  createTransferToFundsRequestModalSchema,
} from '@/components/Modals/CreateTransferToFundsRequestModal/schema/createTransferToFundsRequestModalSchema'
import { useCreateToFundsRequestTransfer } from '@/services/hooks/funds-transfers/useCreateToFundsRequestTransfer'
import { Dialog, DialogProps, DialogTitle, Divider } from '@mui/material'

import { AxiosErrorResponse } from '@/types'

import { CreateTransferToFundsRequestModalContent } from './CreateTransferToFundsRequestModalContent'

export interface CreateTransferModalProps
  extends Omit<DialogProps, 'onError' | 'onSuccess'> {
  onSuccess: () => void
  onError: (error?: AxiosErrorResponse) => void
  fundsRequest: {
    id: number
  }
  recipient: {
    id: number
    room: {
      id: number
    }
  }
  amount: number
}

export const CreateTransferToFundsRequestModal = ({
  recipient,
  amount,
  fundsRequest,
  onSuccess,
  onError,
  ...props
}: CreateTransferModalProps) => {
  const createToFundsRequestTransfer = useCreateToFundsRequestTransfer({
    onSuccess,
    onError,
  })

  const handleSubmit = (data: CreateTransferToFundsRequestModalSchema) => {
    const { factAmount, transferFee, ...payload } = data

    createToFundsRequestTransfer.mutate({
      ...payload,
      transferFee,
      totalAmount: factAmount + (transferFee || 0),
      screenshot: data.screenshot?.id ? data.screenshot : null,
    })
  }

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...props}
    >
      <DialogTitle variant="h4">
        Создание перевода по запросу (ID: {fundsRequest.id})
      </DialogTitle>
      <Divider />
      <Form<CreateTransferToFundsRequestModalSchema>
        onSubmit={handleSubmit}
        initialValues={{ fundsRequest: { id: fundsRequest.id } }}
        validationSchema={createTransferToFundsRequestModalSchema.generate(
          amount,
        )}
      >
        <CreateTransferToFundsRequestModalContent
          recipient={recipient}
          amount={amount}
          isSubmitting={createToFundsRequestTransfer.isLoading}
          {...props}
        />
      </Form>
    </Dialog>
  )
}
