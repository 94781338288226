import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { FundsRequestCreateDto, FundsRequestRo } from '@/services/data-contracts'
import { FundsRequests } from '@/services/api/funds-requests'
import { AxiosErrorResponse } from '@/types'

export const useCreateFundRequest = (
  props?: UseMutationOptions<FundsRequestRo, AxiosErrorResponse, FundsRequestCreateDto>,
) => {
  const queryClient = useQueryClient()

  return useMutation<FundsRequestRo, AxiosErrorResponse, FundsRequestCreateDto>(
    ['funds-requests'],
    async (payload: FundsRequestCreateDto) => {
      const response = await FundsRequests.create(payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['funds-requests'])
        props?.onSuccess?.(...args)
      },
    })
}
