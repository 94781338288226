import qs from 'qs'
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios'

export const createAxiosInstance = () => axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  header: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => qs.stringify(params),
} as CreateAxiosDefaults)

export const Request: AxiosInstance = createAxiosInstance()
