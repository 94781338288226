import { GameRequestsTable } from '@/components/Containers/GameRequestsTable/GameRequestsTable'
import { TournamentsTable } from '@/components/Containers/TournamentsTable/TournamentsTable'
import { TournamentRulesTable } from '@/components/Containers/TournamentRulesTable/TournamentRulesTable'
import { OtherRoomsTableView } from '@/components/CustomSettings/settings/OtherRoomsTable/OtherRoomsTable.view'
import { TournamentsRulesView } from '@/components/CustomSettings/settings/TournamentsRules/TournamentsRules.view'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { RoleHelper } from '@/utils/RoleHelper'
import { Box, Grid } from '@mui/material'

export const TournamentsPage = () => {
  const { currentUser } = useCurrentUser()

  const filterRank = RoleHelper.isPlayer(currentUser?.role)
    ? currentUser?.rank
    : undefined

  const isPlayer = RoleHelper.isPlayer(currentUser?.role)

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TournamentRulesTable rank={filterRank} />

          {isPlayer && (
            <Box mt={2}>
              <OtherRoomsTableView rank={currentUser?.rank} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TournamentsTable type="serie" rank={filterRank} />
          {isPlayer && (
            <Box mt={2}>
              <TournamentsRulesView />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <GameRequestsTable />
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}
