import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { FundsRequestsControllerGetFundsRequestsParams, FundsRequestsDataRo } from '@/services/data-contracts'
import { FundsRequests } from '@/services/api/funds-requests'
import { AxiosErrorResponse } from '@/types'

export const useGetFundsRequests = (
  params?: FundsRequestsControllerGetFundsRequestsParams,
  props?: UseQueryOptions<FundsRequestsDataRo, AxiosErrorResponse>,
) => {
  return useQuery<FundsRequestsDataRo, AxiosErrorResponse>(
    ['funds-requests', params],
    async () => {
      const response = await FundsRequests.get(params)
      return response.data
    },
    props,
  )
}
