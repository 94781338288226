import { memo, ReactNode } from 'react'

import { Box, BoxProps, Tooltip, Typography } from '@mui/material'

export interface TableColumnProps extends BoxProps {
  disableTooltip?: boolean
  children: ReactNode | string | number
}

export const TableColumn = memo(
  ({ children, disableTooltip = true, ...boxProps }: TableColumnProps) => {
    const typographyJSX = (
      <Typography variant="body2">{children || '-'}</Typography>
    )

    return (
      <Box {...boxProps}>
        {typeof children === 'string' || typeof children === 'number' ? (
          disableTooltip ? (
            typographyJSX
          ) : (
            <Tooltip title={children}>{typographyJSX}</Tooltip>
          )
        ) : (
          children
        )}
      </Box>
    )
  },
)
