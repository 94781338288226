import { TournamentFilters } from '@/services/api/tournament-filters.ts'
import {
  TournamentFiltersSetDto,
  TournamentFiltersSetRo,
} from '@/services/data-contracts.ts'
import { AxiosErrorResponse } from '@/types.ts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const useSetFilter = (
  props?: UseMutationOptions<
    TournamentFiltersSetRo,
    AxiosErrorResponse,
    TournamentFiltersSetDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    TournamentFiltersSetRo,
    AxiosErrorResponse,
    TournamentFiltersSetDto
  >(
    ['tournament-filters'],
    async (payload: TournamentFiltersSetDto) => {
      const response = await TournamentFilters.set(payload)

      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries([
          'tournament-filters',
          args[0].name,
        ])
        props?.onSuccess?.(...args)
      },
    },
  )
}
