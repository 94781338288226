import * as yup from 'yup'
import { SetUserRoomSettingsDto } from '@/services/data-contracts'

export type PlayerRoomsSettingsValues = SetUserRoomSettingsDto

export const playerRoomsSettingsSchema = yup.object({
  settings: yup.array(yup.object({
    transferInfo: yup.string().optional(),
    nickname: yup.string().optional().max(255, 'Максимум 255 символов'),
  })),
})
