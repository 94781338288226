import React, { createContext, useContext, useEffect, useState } from 'react'

import { maxevTheme } from '@/config/@mui/theme/maxev-theme.ts'
import { ThemeProvider as MUIThemeProvider } from '@mui/material'

type TTheme = 'dark' | 'light'

const ThemeContext = createContext<{
  theme?: TTheme
  setTheme?: (theme: TTheme) => void
}>({ theme: 'light' })

const useTheme = () => useContext(ThemeContext)

const ThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  const localStorageTheme = localStorage.getItem('theme') as TTheme
  const [theme, setTheme] = useState<'dark' | 'light'>(
    localStorageTheme ?? 'light',
  )
  const currentTheme = maxevTheme(theme)

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <MUIThemeProvider theme={currentTheme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, useTheme }
