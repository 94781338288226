export type CountryAlphaCode = string
export type CountryImageSrc = string
export type CountryName = string

export const countryCodes: Record<
  CountryAlphaCode,
  { name: CountryName; src: CountryImageSrc; code: CountryAlphaCode }
> = {
  AU: {
    name: `Австралия`,
    code: `AU`,
    src: `/assets/country-codes/AU.png`,
  },
  AT: {
    name: `Австрия`,
    code: `AT`,
    src: `/assets/country-codes/AT.png`,
  },
  AZ: {
    name: `Азербайджан`,
    code: `AZ`,
    src: `/assets/country-codes/AZ.png`,
  },
  AX: {
    name: `Аландские острова`,
    code: `AX`,
    src: `/assets/country-codes/AX.png`,
  },
  AL: {
    name: `Албания`,
    code: `AL`,
    src: `/assets/country-codes/AL.png`,
  },
  DZ: {
    name: `Алжир`,
    code: `DZ`,
    src: `/assets/country-codes/DZ.png`,
  },
  VI: {
    name: `Виргинские Острова (США)`,
    code: `VI`,
    src: `/assets/country-codes/VI.png`,
  },
  AS: {
    name: `Американское Самоа`,
    code: `AS`,
    src: `/assets/country-codes/AS.png`,
  },
  AI: {
    name: `Ангилья`,
    code: `AI`,
    src: `/assets/country-codes/AI.png`,
  },
  AO: {
    name: `Ангола`,
    code: `AO`,
    src: `/assets/country-codes/AO.png`,
  },
  AD: {
    name: `Андорра`,
    code: `AD`,
    src: `/assets/country-codes/AD.png`,
  },
  AQ: {
    name: `Антарктика`,
    code: `AQ`,
    src: `/assets/country-codes/AQ.png`,
  },
  AG: {
    name: `Антигуа и Барбуда`,
    code: `AG`,
    src: `/assets/country-codes/AG.png`,
  },
  AR: {
    name: `Аргентина`,
    code: `AR`,
    src: `/assets/country-codes/AR.png`,
  },
  AM: {
    name: `Армения`,
    code: `AM`,
    src: `/assets/country-codes/AM.png`,
  },
  AW: {
    name: `Аруба`,
    code: `AW`,
    src: `/assets/country-codes/AW.png`,
  },
  AF: {
    name: `Афганистан`,
    code: `AF`,
    src: `/assets/country-codes/AF.png`,
  },
  BS: {
    name: `Багамские Острова`,
    code: `BS`,
    src: `/assets/country-codes/BS.png`,
  },
  BD: {
    name: `Бангладеш`,
    code: `BD`,
    src: `/assets/country-codes/BD.png`,
  },
  BB: {
    name: `Барбадос`,
    code: `BB`,
    src: `/assets/country-codes/BB.png`,
  },
  BH: {
    name: `Бахрейн`,
    code: `BH`,
    src: `/assets/country-codes/BH.png`,
  },
  BZ: {
    name: `Белиз`,
    code: `BZ`,
    src: `/assets/country-codes/BZ.png`,
  },
  BY: {
    name: `Беларусь`,
    code: `BY`,
    src: `/assets/country-codes/BY.png`,
  },
  BE: {
    name: `Бельгия`,
    code: `BE`,
    src: `/assets/country-codes/BE.png`,
  },
  BJ: {
    name: `Бенин`,
    code: `BJ`,
    src: `/assets/country-codes/BJ.png`,
  },
  BM: {
    name: `Бермуды`,
    code: `BM`,
    src: `/assets/country-codes/BM.png`,
  },
  BG: {
    name: `Болгария`,
    code: `BG`,
    src: `/assets/country-codes/BG.png`,
  },
  BO: {
    name: `Боливия`,
    code: `BO`,
    src: `/assets/country-codes/BO.png`,
  },
  BQ: {
    name: `Бонайре, Синт-Эстатиус и Саба`,
    code: `BQ`,
    src: `/assets/country-codes/BQ.png`,
  },
  BA: {
    name: `Босния и Герцеговина`,
    code: `BA`,
    src: `/assets/country-codes/BA.png`,
  },
  BW: {
    name: `Ботсвана`,
    code: `BW`,
    src: `/assets/country-codes/BW.png`,
  },
  BR: {
    name: `Бразилия`,
    code: `BR`,
    src: `/assets/country-codes/BR.png`,
  },
  IO: {
    name: `Британская Территория в Индийском Океане`,
    code: `IO`,
    src: `/assets/country-codes/IO.png`,
  },
  VG: {
    name: `Виргинские Острова (Великобритания)`,
    code: `VG`,
    src: `/assets/country-codes/VG.png`,
  },
  BN: {
    name: `Бруней`,
    code: `BN`,
    src: `/assets/country-codes/BN.png`,
  },
  BF: {
    name: `Буркина-Фасо`,
    code: `BF`,
    src: `/assets/country-codes/BF.png`,
  },
  BI: {
    name: `Бурунди`,
    code: `BI`,
    src: `/assets/country-codes/BI.png`,
  },
  BT: {
    name: `Бутан`,
    code: `BT`,
    src: `/assets/country-codes/BT.png`,
  },
  VU: {
    name: `Вануату`,
    code: `VU`,
    src: `/assets/country-codes/VU.png`,
  },
  VA: {
    name: `Ватикан`,
    code: `VA`,
    src: `/assets/country-codes/VA.png`,
  },
  GB: {
    name: `Великобритания`,
    code: `GB`,
    src: `/assets/country-codes/GB.png`,
  },
  HU: {
    name: `Венгрия`,
    code: `HU`,
    src: `/assets/country-codes/HU.png`,
  },
  VE: {
    name: `Венесуэла`,
    code: `VE`,
    src: `/assets/country-codes/VE.png`,
  },
  UM: {
    name: `Внешние малые острова США`,
    code: `UM`,
    src: `/assets/country-codes/UM.png`,
  },
  TL: {
    name: `Восточный Тимор`,
    code: `TL`,
    src: `/assets/country-codes/TL.png`,
  },
  VN: {
    name: `Вьетнам`,
    code: `VN`,
    src: `/assets/country-codes/VN.png`,
  },
  GA: {
    name: `Габон`,
    code: `GA`,
    src: `/assets/country-codes/GA.png`,
  },
  HT: {
    name: `Гаити`,
    code: `HT`,
    src: `/assets/country-codes/HT.png`,
  },
  GY: {
    name: `Гайана`,
    code: `GY`,
    src: `/assets/country-codes/GY.png`,
  },
  GM: {
    name: `Гамбия`,
    code: `GM`,
    src: `/assets/country-codes/GM.png`,
  },
  GH: {
    name: `Гана`,
    code: `GH`,
    src: `/assets/country-codes/GH.png`,
  },
  GP: {
    name: `Гваделупа`,
    code: `GP`,
    src: `/assets/country-codes/GP.png`,
  },
  GT: {
    name: `Гватемала`,
    code: `GT`,
    src: `/assets/country-codes/GT.png`,
  },
  GF: {
    name: `Гвиана`,
    code: `GF`,
    src: `/assets/country-codes/GF.png`,
  },
  GN: {
    name: `Гвинея`,
    code: `GN`,
    src: `/assets/country-codes/GN.png`,
  },
  GW: {
    name: `Гвинея-Бисау`,
    code: `GW`,
    src: `/assets/country-codes/GW.png`,
  },
  DE: {
    name: `Германия`,
    code: `DE`,
    src: `/assets/country-codes/DE.png`,
  },
  GG: {
    name: `Гернси`,
    code: `GG`,
    src: `/assets/country-codes/GG.png`,
  },
  GI: {
    name: `Гибралтар`,
    code: `GI`,
    src: `/assets/country-codes/GI.png`,
  },
  HN: {
    name: `Гондурас`,
    code: `HN`,
    src: `/assets/country-codes/HN.png`,
  },
  HK: {
    name: `Гонконг`,
    code: `HK`,
    src: `/assets/country-codes/HK.png`,
  },
  GD: {
    name: `Гренада`,
    code: `GD`,
    src: `/assets/country-codes/GD.png`,
  },
  GL: {
    name: `Гренландия`,
    code: `GL`,
    src: `/assets/country-codes/GL.png`,
  },
  GR: {
    name: `Греция`,
    code: `GR`,
    src: `/assets/country-codes/GR.png`,
  },
  GE: {
    name: `Грузия`,
    code: `GE`,
    src: `/assets/country-codes/GE.png`,
  },
  GU: {
    name: `Гуам`,
    code: `GU`,
    src: `/assets/country-codes/GU.png`,
  },
  DK: {
    name: `Дания`,
    code: `DK`,
    src: `/assets/country-codes/DK.png`,
  },
  JE: {
    name: `Джерси`,
    code: `JE`,
    src: `/assets/country-codes/JE.png`,
  },
  DJ: {
    name: `Джибути`,
    code: `DJ`,
    src: `/assets/country-codes/DJ.png`,
  },
  DM: {
    name: `Доминика`,
    code: `DM`,
    src: `/assets/country-codes/DM.png`,
  },
  DO: {
    name: `Доминиканская Республика`,
    code: `DO`,
    src: `/assets/country-codes/DO.png`,
  },
  CD: {
    name: `ДР Конго`,
    code: `CD`,
    src: `/assets/country-codes/CD.png`,
  },
  EU: {
    name: `Европейский союз`,
    code: `EU`,
    src: `/assets/country-codes/EU.png`,
  },
  EG: {
    name: `Египет`,
    code: `EG`,
    src: `/assets/country-codes/EG.png`,
  },
  ZM: {
    name: `Замбия`,
    code: `ZM`,
    src: `/assets/country-codes/ZM.png`,
  },
  EH: {
    name: `САДР`,
    code: `EH`,
    src: `/assets/country-codes/EH.png`,
  },
  ZW: {
    name: `Зимбабве`,
    code: `ZW`,
    src: `/assets/country-codes/ZW.png`,
  },
  IL: {
    name: `Израиль`,
    code: `IL`,
    src: `/assets/country-codes/IL.png`,
  },
  IN: {
    name: `Индия`,
    code: `IN`,
    src: `/assets/country-codes/IN.png`,
  },
  ID: {
    name: `Индонезия`,
    code: `ID`,
    src: `/assets/country-codes/ID.png`,
  },
  JO: {
    name: `Иордания`,
    code: `JO`,
    src: `/assets/country-codes/JO.png`,
  },
  IQ: {
    name: `Ирак`,
    code: `IQ`,
    src: `/assets/country-codes/IQ.png`,
  },
  IR: {
    name: `Иран`,
    code: `IR`,
    src: `/assets/country-codes/IR.png`,
  },
  IE: {
    name: `Ирландия`,
    code: `IE`,
    src: `/assets/country-codes/IE.png`,
  },
  IS: {
    name: `Исландия`,
    code: `IS`,
    src: `/assets/country-codes/IS.png`,
  },
  ES: {
    name: `Испания`,
    code: `ES`,
    src: `/assets/country-codes/ES.png`,
  },
  IT: {
    name: `Италия`,
    code: `IT`,
    src: `/assets/country-codes/IT.png`,
  },
  YE: {
    name: `Йемен`,
    code: `YE`,
    src: `/assets/country-codes/YE.png`,
  },
  CV: {
    name: `Кабо-Верде`,
    code: `CV`,
    src: `/assets/country-codes/CV.png`,
  },
  KZ: {
    name: `Казахстан`,
    code: `KZ`,
    src: `/assets/country-codes/KZ.png`,
  },
  KY: {
    name: `Острова Кайман`,
    code: `KY`,
    src: `/assets/country-codes/KY.png`,
  },
  KH: {
    name: `Камбоджа`,
    code: `KH`,
    src: `/assets/country-codes/KH.png`,
  },
  CM: {
    name: `Камерун`,
    code: `CM`,
    src: `/assets/country-codes/CM.png`,
  },
  CA: {
    name: `Канада`,
    code: `CA`,
    src: `/assets/country-codes/CA.png`,
  },
  QA: {
    name: `Катар`,
    code: `QA`,
    src: `/assets/country-codes/QA.png`,
  },
  KE: {
    name: `Кения`,
    code: `KE`,
    src: `/assets/country-codes/KE.png`,
  },
  CY: {
    name: `Кипр`,
    code: `CY`,
    src: `/assets/country-codes/CY.png`,
  },
  KG: {
    name: `Кыргызстан`,
    code: `KG`,
    src: `/assets/country-codes/KG.png`,
  },
  KI: {
    name: `Кирибати`,
    code: `KI`,
    src: `/assets/country-codes/KI.png`,
  },
  TW: {
    name: `Китайская Республика`,
    code: `TW`,
    src: `/assets/country-codes/TW.png`,
  },
  KP: {
    name: `КНДР`,
    code: `KP`,
    src: `/assets/country-codes/KP.png`,
  },
  CN: {
    name: `Китай`,
    code: `CN`,
    src: `/assets/country-codes/CN.png`,
  },
  CC: {
    name: `Кокосовые острова`,
    code: `CC`,
    src: `/assets/country-codes/CC.png`,
  },
  CO: {
    name: `Колумбия`,
    code: `CO`,
    src: `/assets/country-codes/CO.png`,
  },
  KM: {
    name: `Коморы`,
    code: `KM`,
    src: `/assets/country-codes/KM.png`,
  },
  CR: {
    name: `Коста-Рика`,
    code: `CR`,
    src: `/assets/country-codes/CR.png`,
  },
  CI: {
    name: `Кот-д’Ивуар`,
    code: `CI`,
    src: `/assets/country-codes/CI.png`,
  },
  CU: {
    name: `Куба`,
    code: `CU`,
    src: `/assets/country-codes/CU.png`,
  },
  KW: {
    name: `Кувейт`,
    code: `KW`,
    src: `/assets/country-codes/KW.png`,
  },
  CW: {
    name: `Кюрасао`,
    code: `CW`,
    src: `/assets/country-codes/CW.png`,
  },
  LA: {
    name: `Лаос`,
    code: `LA`,
    src: `/assets/country-codes/LA.png`,
  },
  LV: {
    name: `Латвия`,
    code: `LV`,
    src: `/assets/country-codes/LV.png`,
  },
  LS: {
    name: `Лесото`,
    code: `LS`,
    src: `/assets/country-codes/LS.png`,
  },
  LR: {
    name: `Либерия`,
    code: `LR`,
    src: `/assets/country-codes/LR.png`,
  },
  LB: {
    name: `Ливан`,
    code: `LB`,
    src: `/assets/country-codes/LB.png`,
  },
  LY: {
    name: `Ливия`,
    code: `LY`,
    src: `/assets/country-codes/LY.png`,
  },
  LT: {
    name: `Литва`,
    code: `LT`,
    src: `/assets/country-codes/LT.png`,
  },
  LI: {
    name: `Лихтенштейн`,
    code: `LI`,
    src: `/assets/country-codes/LI.png`,
  },
  LU: {
    name: `Люксембург`,
    code: `LU`,
    src: `/assets/country-codes/LU.png`,
  },
  MU: {
    name: `Маврикий`,
    code: `MU`,
    src: `/assets/country-codes/MU.png`,
  },
  MR: {
    name: `Мавритания`,
    code: `MR`,
    src: `/assets/country-codes/MR.png`,
  },
  MG: {
    name: `Мадагаскар`,
    code: `MG`,
    src: `/assets/country-codes/MG.png`,
  },
  YT: {
    name: `Майотта`,
    code: `YT`,
    src: `/assets/country-codes/YT.png`,
  },
  MO: {
    name: `Макао`,
    code: `MO`,
    src: `/assets/country-codes/MO.png`,
  },
  MK: {
    name: `Северная Македония`,
    code: `MK`,
    src: `/assets/country-codes/MK.png`,
  },
  MW: {
    name: `Малави`,
    code: `MW`,
    src: `/assets/country-codes/MW.png`,
  },
  MY: {
    name: `Малайзия`,
    code: `MY`,
    src: `/assets/country-codes/MY.png`,
  },
  ML: {
    name: `Мали`,
    code: `ML`,
    src: `/assets/country-codes/ML.png`,
  },
  MV: {
    name: `Мальдивы`,
    code: `MV`,
    src: `/assets/country-codes/MV.png`,
  },
  MT: {
    name: `Мальта`,
    code: `MT`,
    src: `/assets/country-codes/MT.png`,
  },
  MA: {
    name: `Марокко`,
    code: `MA`,
    src: `/assets/country-codes/MA.png`,
  },
  MQ: {
    name: `Мартиника`,
    code: `MQ`,
    src: `/assets/country-codes/MQ.png`,
  },
  MH: {
    name: `Маршалловы Острова`,
    code: `MH`,
    src: `/assets/country-codes/MH.png`,
  },
  MX: {
    name: `Мексика`,
    code: `MX`,
    src: `/assets/country-codes/MX.png`,
  },
  FM: {
    name: `Микронезия`,
    code: `FM`,
    src: `/assets/country-codes/FM.png`,
  },
  MZ: {
    name: `Мозамбик`,
    code: `MZ`,
    src: `/assets/country-codes/MZ.png`,
  },
  MD: {
    name: `Молдова`,
    code: `MD`,
    src: `/assets/country-codes/MD.png`,
  },
  MC: {
    name: `Монако`,
    code: `MC`,
    src: `/assets/country-codes/MC.png`,
  },
  MN: {
    name: `Монголия`,
    code: `MN`,
    src: `/assets/country-codes/MN.png`,
  },
  MS: {
    name: `Монтсеррат`,
    code: `MS`,
    src: `/assets/country-codes/MS.png`,
  },
  MM: {
    name: `Мьянма`,
    code: `MM`,
    src: `/assets/country-codes/MM.png`,
  },
  NA: {
    name: `Намибия`,
    code: `NA`,
    src: `/assets/country-codes/NA.png`,
  },
  NR: {
    name: `Науру`,
    code: `NR`,
    src: `/assets/country-codes/NR.png`,
  },
  NP: {
    name: `Непал`,
    code: `NP`,
    src: `/assets/country-codes/NP.png`,
  },
  NE: {
    name: `Нигер`,
    code: `NE`,
    src: `/assets/country-codes/NE.png`,
  },
  NG: {
    name: `Нигерия`,
    code: `NG`,
    src: `/assets/country-codes/NG.png`,
  },
  NL: {
    name: `Нидерланды`,
    code: `NL`,
    src: `/assets/country-codes/NL.png`,
  },
  NI: {
    name: `Никарагуа`,
    code: `NI`,
    src: `/assets/country-codes/NI.png`,
  },
  NU: {
    name: `Ниуэ`,
    code: `NU`,
    src: `/assets/country-codes/NU.png`,
  },
  NZ: {
    name: `Новая Зеландия`,
    code: `NZ`,
    src: `/assets/country-codes/NZ.png`,
  },
  NC: {
    name: `Новая Каледония`,
    code: `NC`,
    src: `/assets/country-codes/NC.png`,
  },
  NO: {
    name: `Норвегия`,
    code: `NO`,
    src: `/assets/country-codes/NO.png`,
  },
  AE: {
    name: `ОАЭ`,
    code: `AE`,
    src: `/assets/country-codes/AE.png`,
  },
  OM: {
    name: `Оман`,
    code: `OM`,
    src: `/assets/country-codes/OM.png`,
  },
  BV: {
    name: `Остров Буве`,
    code: `BV`,
    src: `/assets/country-codes/BV.png`,
  },
  IM: {
    name: `Остров Мэн`,
    code: `IM`,
    src: `/assets/country-codes/IM.png`,
  },
  CK: {
    name: `Острова Кука`,
    code: `CK`,
    src: `/assets/country-codes/CK.png`,
  },
  NF: {
    name: `Остров Норфолк`,
    code: `NF`,
    src: `/assets/country-codes/NF.png`,
  },
  CX: {
    name: `Остров Рождества`,
    code: `CX`,
    src: `/assets/country-codes/CX.png`,
  },
  PN: {
    name: `Острова Питкэрн`,
    code: `PN`,
    src: `/assets/country-codes/PN.png`,
  },
  SH: {
    name: `Остров Святой Елены`,
    code: `SH`,
    src: `/assets/country-codes/SH.png`,
  },
  PK: {
    name: `Пакистан`,
    code: `PK`,
    src: `/assets/country-codes/PK.png`,
  },
  PW: {
    name: `Палау`,
    code: `PW`,
    src: `/assets/country-codes/PW.png`,
  },
  PS: {
    name: `Государство Палестина`,
    code: `PS`,
    src: `/assets/country-codes/PS.png`,
  },
  PA: {
    name: `Панама`,
    code: `PA`,
    src: `/assets/country-codes/PA.png`,
  },
  PG: {
    name: `Папуа — Новая Гвинея`,
    code: `PG`,
    src: `/assets/country-codes/PG.png`,
  },
  PY: {
    name: `Парагвай`,
    code: `PY`,
    src: `/assets/country-codes/PY.png`,
  },
  PE: {
    name: `Перу`,
    code: `PE`,
    src: `/assets/country-codes/PE.png`,
  },
  PL: {
    name: `Польша`,
    code: `PL`,
    src: `/assets/country-codes/PL.png`,
  },
  PT: {
    name: `Португалия`,
    code: `PT`,
    src: `/assets/country-codes/PT.png`,
  },
  PR: {
    name: `Пуэрто-Рико`,
    code: `PR`,
    src: `/assets/country-codes/PR.png`,
  },
  CG: {
    name: `Республика Конго`,
    code: `CG`,
    src: `/assets/country-codes/CG.png`,
  },
  KR: {
    name: `Республика Корея`,
    code: `KR`,
    src: `/assets/country-codes/KR.png`,
  },
  RE: {
    name: `Реюньон`,
    code: `RE`,
    src: `/assets/country-codes/RE.png`,
  },
  RU: {
    name: `Россия`,
    code: `RU`,
    src: `/assets/country-codes/RU.png`,
  },
  RW: {
    name: `Руанда`,
    code: `RW`,
    src: `/assets/country-codes/RW.png`,
  },
  RO: {
    name: `Румыния`,
    code: `RO`,
    src: `/assets/country-codes/RO.png`,
  },
  SV: {
    name: `Сальвадор`,
    code: `SV`,
    src: `/assets/country-codes/SV.png`,
  },
  WS: {
    name: `Самоа`,
    code: `WS`,
    src: `/assets/country-codes/WS.png`,
  },
  SM: {
    name: `Сан-Марино`,
    code: `SM`,
    src: `/assets/country-codes/SM.png`,
  },
  ST: {
    name: `Сан-Томе и Принсипи`,
    code: `ST`,
    src: `/assets/country-codes/ST.png`,
  },
  SA: {
    name: `Саудовская Аравия`,
    code: `SA`,
    src: `/assets/country-codes/SA.png`,
  },
  SZ: {
    name: `Эсватини`,
    code: `SZ`,
    src: `/assets/country-codes/SZ.png`,
  },
  MP: {
    name: `Северные Марианские Острова`,
    code: `MP`,
    src: `/assets/country-codes/MP.png`,
  },
  SC: {
    name: `Сейшельские Острова`,
    code: `SC`,
    src: `/assets/country-codes/SC.png`,
  },
  BL: {
    name: `Сен-Бартелеми`,
    code: `BL`,
    src: `/assets/country-codes/BL.png`,
  },
  MF: {
    name: `Сен-Мартен`,
    code: `MF`,
    src: `/assets/country-codes/MF.png`,
  },
  PM: {
    name: `Сен-Пьер и Микелон`,
    code: `PM`,
    src: `/assets/country-codes/PM.png`,
  },
  SN: {
    name: `Сенегал`,
    code: `SN`,
    src: `/assets/country-codes/SN.png`,
  },
  VC: {
    name: `Сент-Винсент и Гренадины`,
    code: `VC`,
    src: `/assets/country-codes/VC.png`,
  },
  KN: {
    name: `Сент-Китс и Невис`,
    code: `KN`,
    src: `/assets/country-codes/KN.png`,
  },
  LC: {
    name: `Сент-Люсия`,
    code: `LC`,
    src: `/assets/country-codes/LC.png`,
  },
  RS: {
    name: `Сербия`,
    code: `RS`,
    src: `/assets/country-codes/RS.png`,
  },
  SG: {
    name: `Сингапур`,
    code: `SG`,
    src: `/assets/country-codes/SG.png`,
  },
  SX: {
    name: `Синт-Мартен`,
    code: `SX`,
    src: `/assets/country-codes/SX.png`,
  },
  SY: {
    name: `Сирия`,
    code: `SY`,
    src: `/assets/country-codes/SY.png`,
  },
  SK: {
    name: `Словакия`,
    code: `SK`,
    src: `/assets/country-codes/SK.png`,
  },
  SI: {
    name: `Словения`,
    code: `SI`,
    src: `/assets/country-codes/SI.png`,
  },
  SB: {
    name: `Соломоновы Острова`,
    code: `SB`,
    src: `/assets/country-codes/SB.png`,
  },
  SO: {
    name: `Сомали`,
    code: `SO`,
    src: `/assets/country-codes/SO.png`,
  },
  SD: {
    name: `Судан`,
    code: `SD`,
    src: `/assets/country-codes/SD.png`,
  },
  SR: {
    name: `Суринам`,
    code: `SR`,
    src: `/assets/country-codes/SR.png`,
  },
  US: {
    name: `США`,
    code: `US`,
    src: `/assets/country-codes/US.png`,
  },
  SL: {
    name: `Сьерра-Леоне`,
    code: `SL`,
    src: `/assets/country-codes/SL.png`,
  },
  TJ: {
    name: `Таджикистан`,
    code: `TJ`,
    src: `/assets/country-codes/TJ.png`,
  },
  TH: {
    name: `Таиланд`,
    code: `TH`,
    src: `/assets/country-codes/TH.png`,
  },
  TZ: {
    name: `Танзания`,
    code: `TZ`,
    src: `/assets/country-codes/TZ.png`,
  },
  TC: {
    name: `Теркс и Кайкос`,
    code: `TC`,
    src: `/assets/country-codes/TC.png`,
  },
  TG: {
    name: `Того`,
    code: `TG`,
    src: `/assets/country-codes/TG.png`,
  },
  TK: {
    name: `Токелау`,
    code: `TK`,
    src: `/assets/country-codes/TK.png`,
  },
  TO: {
    name: `Тонга`,
    code: `TO`,
    src: `/assets/country-codes/TO.png`,
  },
  TT: {
    name: `Тринидад и Тобаго`,
    code: `TT`,
    src: `/assets/country-codes/TT.png`,
  },
  TV: {
    name: `Тувалу`,
    code: `TV`,
    src: `/assets/country-codes/TV.png`,
  },
  TN: {
    name: `Тунис`,
    code: `TN`,
    src: `/assets/country-codes/TN.png`,
  },
  TM: {
    name: `Туркменистан`,
    code: `TM`,
    src: `/assets/country-codes/TM.png`,
  },
  TR: {
    name: `Турция`,
    code: `TR`,
    src: `/assets/country-codes/TR.png`,
  },
  UG: {
    name: `Уганда`,
    code: `UG`,
    src: `/assets/country-codes/UG.png`,
  },
  UZ: {
    name: `Узбекистан`,
    code: `UZ`,
    src: `/assets/country-codes/UZ.png`,
  },
  UA: {
    name: `Украина`,
    code: `UA`,
    src: `/assets/country-codes/UA.png`,
  },
  WF: {
    name: `Уоллис и Футуна`,
    code: `WF`,
    src: `/assets/country-codes/WF.png`,
  },
  UY: {
    name: `Уругвай`,
    code: `UY`,
    src: `/assets/country-codes/UY.png`,
  },
  FO: {
    name: `Фарерские острова`,
    code: `FO`,
    src: `/assets/country-codes/FO.png`,
  },
  FJ: {
    name: `Фиджи`,
    code: `FJ`,
    src: `/assets/country-codes/FJ.png`,
  },
  PH: {
    name: `Филиппины`,
    code: `PH`,
    src: `/assets/country-codes/PH.png`,
  },
  FI: {
    name: `Финляндия`,
    code: `FI`,
    src: `/assets/country-codes/FI.png`,
  },
  FK: {
    name: `Фолклендские острова`,
    code: `FK`,
    src: `/assets/country-codes/FK.png`,
  },
  FR: {
    name: `Франция`,
    code: `FR`,
    src: `/assets/country-codes/FR.png`,
  },
  PF: {
    name: `Французская Полинезия`,
    code: `PF`,
    src: `/assets/country-codes/PF.png`,
  },
  TF: {
    name: `Французские Южные и Антарктические территории`,
    code: `TF`,
    src: `/assets/country-codes/TF.png`,
  },
  HM: {
    name: `Херд и Макдональд`,
    code: `HM`,
    src: `/assets/country-codes/HM.png`,
  },
  HR: {
    name: `Хорватия`,
    code: `HR`,
    src: `/assets/country-codes/HR.png`,
  },
  CF: {
    name: `ЦАР`,
    code: `CF`,
    src: `/assets/country-codes/CF.png`,
  },
  TD: {
    name: `Чад`,
    code: `TD`,
    src: `/assets/country-codes/TD.png`,
  },
  ME: {
    name: `Черногория`,
    code: `ME`,
    src: `/assets/country-codes/ME.png`,
  },
  CZ: {
    name: `Чехия`,
    code: `CZ`,
    src: `/assets/country-codes/CZ.png`,
  },
  CL: {
    name: `Чили`,
    code: `CL`,
    src: `/assets/country-codes/CL.png`,
  },
  CH: {
    name: `Швейцария`,
    code: `CH`,
    src: `/assets/country-codes/CH.png`,
  },
  SE: {
    name: `Швеция`,
    code: `SE`,
    src: `/assets/country-codes/SE.png`,
  },
  SJ: {
    name: `Шпицберген и Ян-Майен`,
    code: `SJ`,
    src: `/assets/country-codes/SJ.png`,
  },
  LK: {
    name: `Шри-Ланка`,
    code: `LK`,
    src: `/assets/country-codes/LK.png`,
  },
  EC: {
    name: `Эквадор`,
    code: `EC`,
    src: `/assets/country-codes/EC.png`,
  },
  GQ: {
    name: `Экваториальная Гвинея`,
    code: `GQ`,
    src: `/assets/country-codes/GQ.png`,
  },
  ER: {
    name: `Эритрея`,
    code: `ER`,
    src: `/assets/country-codes/ER.png`,
  },
  EE: {
    name: `Эстония`,
    code: `EE`,
    src: `/assets/country-codes/EE.png`,
  },
  ET: {
    name: `Эфиопия`,
    code: `ET`,
    src: `/assets/country-codes/ET.png`,
  },
  ZA: {
    name: `ЮАР`,
    code: `ZA`,
    src: `/assets/country-codes/ZA.png`,
  },
  GS: {
    name: `Южная Георгия и Южные Сандвичевы Острова`,
    code: `GS`,
    src: `/assets/country-codes/GS.png`,
  },
  SS: {
    name: `Южный Судан`,
    code: `SS`,
    src: `/assets/country-codes/SS.png`,
  },
  JM: {
    name: `Ямайка`,
    code: `JM`,
    src: `/assets/country-codes/JM.png`,
  },
  JP: {
    name: `Япония`,
    code: `JP`,
    src: `/assets/country-codes/JP.png`,
  }
}
