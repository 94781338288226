import { UserContracts } from '@/services/api/user-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

type UseDeleteUserContractPayload = {
  id: number
}

export const useDeleteUserContract = (
  props?: UseMutationOptions<
    boolean,
    AxiosErrorResponse,
    UseDeleteUserContractPayload
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteUserContractPayload>(
    ['user-contracts'],
    async (payload: UseDeleteUserContractPayload) => {
      const response = await UserContracts.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-contracts'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
