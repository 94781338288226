import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'

import { FormItem, FormItemChildrenProps } from './FormItem'

export interface TextFieldProps extends Partial<FormItemChildrenProps> {
  textFieldProps?: MuiTextFieldProps
  placeholder?: string
  type?: HTMLInputElement['type']
  disabled?: boolean
}

export const TextField = ({
  disabled,
  label,
  formItemProps,
  textFieldProps,
  name,
  placeholder,
  type,
}: TextFieldProps) => {
  return (
    <FormItem
      fullWidth
      {...formItemProps}
      name={name || ''}
      label={label || ''}
      labelPosition="in-component"
    >
      <MuiTextField
        margin="normal"
        fullWidth
        variant="outlined"
        {...textFieldProps}
        aria-label={label}
        type={type}
        {...(placeholder && {
          placeholder,
        })}
        InputProps={{
          disabled,
          ...textFieldProps?.InputProps,
        }}
        InputLabelProps={{
          shrink: true,
          ...textFieldProps?.InputLabelProps,
        }}
      />
    </FormItem>
  )
}
