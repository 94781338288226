import { useState } from 'react'

import { MUIDataTableOptions } from 'mui-datatables'

export type TableSortItem = [string, 'ASC' | 'DESC' | 'asc' | 'desc']
type TableSortRule = TableSortItem[]

export const useTableSort = (
  defaultSort: TableSortItem | TableSortRule,
  customSort?: (item: TableSortItem) => TableSortRule,
) => {
  function isTableSortRule(
    defaultSort: TableSortItem | TableSortRule,
  ): defaultSort is TableSortRule {
    return Array.isArray(defaultSort) && Array.isArray(defaultSort?.[0])
  }

  const [sort, setSort] = useState<TableSortRule>(
    isTableSortRule(defaultSort) ? defaultSort : [defaultSort],
  )

  const handleSortChange: MUIDataTableOptions['onColumnSortChange'] = (
    changedColumn,
    direction,
  ) => {
    if (customSort) {
      const customSortValues = customSort([changedColumn, direction])
      setSort(customSortValues)

      return
    }

    setSort([[changedColumn, direction]])
  }

  return {
    queryParams: {
      ...(sort?.length && {
        sort: sort
          .map(([column, direction]) => `${column}:${direction.toUpperCase()}`)
          .join(','),
      }),
    },
    options: {
      sort: true,
      onColumnSortChange: handleSortChange,
    },
  }
}
