import { useMemo } from 'react'

import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter.tsx'
import { RoomCategoryEnum } from '@/constants.ts'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms.ts'
import { roomsToMultipleSelectOptions } from '@/utils/mappers/roomsToMultipleSelectOptions.ts'

type UseGetRoomsSelectValuesProps = {
  category?: RoomCategoryEnum[]
}

export const useGetRoomsSelectValues = (
  props?: UseGetRoomsSelectValuesProps,
) => {
  const { category } = props || {}
  const { defaultSortString } = useGetSort()

  const { data: rooms, isFetching: isRoomsFetching } = useGetRooms({
    limit: 500,
    sort: defaultSortString,
    'category.some': category || undefined,
  })

  const roomsSelectValues = useMemo<MultipleSelectOption[]>(
    () => roomsToMultipleSelectOptions(rooms ? rooms.data : []),
    [rooms],
  )

  return {
    isRoomsFetching,
    roomsSelectValues,
  }
}
