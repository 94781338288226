import { CurrencyRateControllerGetTodayRatesParams } from '@/services/data-contracts.ts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosErrorResponse } from '@/types.ts'
import { CurrencyRate } from '@/services/api/currency-rate.ts'

export const useGetTodayRate = (
  params: CurrencyRateControllerGetTodayRatesParams,
  props?: UseQueryOptions<number, AxiosErrorResponse>,
) => {
  return useQuery<number, AxiosErrorResponse>(
    ['currency-rate', 'today-rate', params],
    async () => {
      const response = await CurrencyRate.getTodayRate(params)
      return response.data
    },
    props,
  )
}
