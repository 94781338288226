import { useState } from 'react'

import { Form } from '@/components/Forms/Form'
import {
  CreateTransferModalSchema,
  CreateTransferModalSchemaPersonal,
  CreateTransferModalSchemaProject,
  EditTransferModalSchema,
  transferModalSchema,
} from '@/components/Modals/TransferModal/schema/createTransferModal.schema'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import {
  FundsTransferCreatePersonalDto,
  FundsTransferCreateProjectDto,
  FundsTransferEditPersonalDto,
  FundsTransferEditProjectDto,
  FundsTransferRo,
} from '@/services/data-contracts'
import { useCreatePersonalTransfer } from '@/services/hooks/funds-transfers/useCreatePersonalFundsTransfer'
import { useCreateProjectTransfer } from '@/services/hooks/funds-transfers/useCreateProjectFundsTransfer'
import { useEditPersonalFundsTransfer } from '@/services/hooks/funds-transfers/useEditPersonalFundsTransfer'
import { useEditProjectFundsTransfer } from '@/services/hooks/funds-transfers/useEditProjectFundsTransfer'
import { useGetFundsTransferById } from '@/services/hooks/funds-transfers/useGetFundsTransferById'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { RoleHelper } from '@/utils/RoleHelper'
import { Alert, Dialog, DialogProps, DialogTitle } from '@mui/material'

import { AxiosErrorResponse } from '@/types'

import { TransferModalContent } from './TransferModalContent'

interface CreateTransferModalProps
  extends Omit<DialogProps, 'onError' | 'onSuccess'> {
  mode: 'create'
  fundsTransferId: undefined
  type: 'project' | 'personal'
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

interface EditTransferModalProps
  extends Omit<DialogProps, 'onError' | 'onSuccess'> {
  mode: 'edit'
  fundsTransferId: number
  type: 'project' | 'personal'
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

export type TransferModalProps =
  | CreateTransferModalProps
  | EditTransferModalProps

export const TransferModal = ({
  mode,
  type,
  fundsTransferId,
  onSuccess,
  onError,
  ...props
}: TransferModalProps) => {
  const [customError, setCustomError] = useState<string>('')
  const { currentUser } = useCurrentUser()

  const { data: initialEditData, isLoading: isLoadingInitialEditData } =
    useGetFundsTransferById(fundsTransferId || 0, {
      enabled: !!fundsTransferId && mode === 'edit',
      onError,
    })

  const header = {
    create: {
      project: 'Создание перевода внутри проекта',
      personal: 'Создание личного перевода',
    },
    edit: {
      project: `Редактирование перевода внутри проекта (ID: ${fundsTransferId})`,
      personal: `Редактирование личного перевода (ID: ${fundsTransferId})`,
    },
  }

  const createProjectTransferMutation = useCreateProjectTransfer({
    onSuccess,
    onError,
  })
  const createPersonalTransferMutation = useCreatePersonalTransfer({
    onSuccess,
    onError,
  })
  const editProjectTransferMutation = useEditProjectFundsTransfer(
    fundsTransferId || 0,
    {
      onSuccess,
      onError,
    },
  )
  const editPersonalTransferMutation = useEditPersonalFundsTransfer(
    fundsTransferId || 0,
    {
      onSuccess,
      onError,
    },
  )

  const handleCreateProjectTransfer = (
    data: CreateTransferModalSchemaProject,
  ) => {
    const payload: FundsTransferCreateProjectDto = {
      ...data,
      totalAmount: data.factAmount + (data.transferFee || 0),
    }

    if (!currentUser) {
      return
    }

    if (!payload.recipient) {
      return
    }

    const senderId = payload.sender ? +payload.sender.id : +currentUser.id || 0

    const checkCurrentUser = {
      isAdmin: RoleHelper.isAdmin(currentUser.role),
      isSender: senderId === +currentUser.id,
      isRecipient: +payload.recipient.id === +currentUser.id,
    }

    if (checkCurrentUser.isSender && checkCurrentUser.isRecipient) {
      setCustomError('Нельзя отправлять перевод самому себе')
      return
    }

    if (!checkCurrentUser.isAdmin) {
      if (!checkCurrentUser.isSender && !checkCurrentUser.isRecipient) {
        setCustomError(
          'Необходимо выбрать получателем или отправителем самого себя',
        )
        return
      }
    }

    createProjectTransferMutation.mutate({
      room: {
        id: +payload.room.id,
      },
      totalAmount: +Math.abs(payload.totalAmount),
      transferFee: payload.transferFee ? +payload.transferFee : 0,
      transferPurpose: 'transfer_between_players',
      comment: payload.comment || '',
      sender: {
        id: senderId,
      },
      recipient: {
        id: +payload.recipient.id || +currentUser.id,
      },
      ...(payload.screenshot?.id &&
        ({
          screenshot: {
            id: +payload.screenshot.id,
          },
        } as FundsTransferCreateProjectDto)),
    })
  }

  const handleCreatePersonalTransfer = (
    data: CreateTransferModalSchemaPersonal,
  ) => {
    const payload: FundsTransferCreatePersonalDto = {
      ...data,
      totalAmount: Number(data.factAmount + (data.transferFee || 0)),
    }

    createPersonalTransferMutation.mutate({
      sender: {
        id: payload.sender?.id || currentUser?.id || 0,
      },
      room: {
        id: +payload.room.id,
      },
      totalAmount: payload.totalAmount,
      transferFee: payload.transferFee ? +payload.transferFee : 0,
      transferPurpose: payload.transferPurpose,
      comment: payload.comment || '',
      ...(payload.screenshot?.id &&
        ({
          screenshot: {
            id: +payload.screenshot.id,
          },
        } as FundsTransferCreatePersonalDto)),
    })
  }

  const handleEditProjectTransfer = (data: FundsTransferEditProjectDto) => {
    const payload: FundsTransferEditProjectDto = {
      comment: data.comment,
      transferPurpose: data.transferPurpose,
      ...(data?.screenshot?.id && {
        screenshot: {
          id: +data.screenshot.id,
        },
      }),
      ...(data?.sender?.id && {
        sender: {
          id: +data.sender.id,
        },
      }),
      ...(data?.recipient?.id && {
        recipient: {
          id: +data.recipient.id,
        },
      }),
      ...(data?.room?.id && {
        room: {
          id: +data.room.id,
        },
      }),
    }

    editProjectTransferMutation.mutate(payload)
  }

  const handleEditPersonalTransfer = (data: FundsTransferEditPersonalDto) => {
    const payload: FundsTransferEditPersonalDto = {
      comment: data.comment,
      transferPurpose: data.transferPurpose,
      ...(data?.screenshot?.id && {
        screenshot: {
          id: +data.screenshot.id,
        },
      }),
      ...(data?.sender?.id && {
        sender: {
          id: +data.sender.id,
        },
      }),
      ...(data?.room?.id && {
        room: {
          id: +data.room.id,
        },
      }),
    }

    editPersonalTransferMutation.mutate(payload)
  }

  const handleSubmit = (
    data: CreateTransferModalSchema | EditTransferModalSchema,
  ) => {
    setCustomError('')

    const handlers = {
      create: {
        project: () =>
          handleCreateProjectTransfer(data as CreateTransferModalSchemaProject),
        personal: () =>
          handleCreatePersonalTransfer(
            data as CreateTransferModalSchemaPersonal,
          ),
      },
      edit: {
        project: () =>
          handleEditProjectTransfer(data as EditTransferModalSchema),
        personal: () =>
          handleEditPersonalTransfer(data as EditTransferModalSchema),
      },
    }

    return handlers[mode][type]()
  }

  const validationSchema = transferModalSchema.generate({
    type,
  })

  const isSubmitting = [
    createProjectTransferMutation,
    createPersonalTransferMutation,
    editProjectTransferMutation,
    editPersonalTransferMutation,
  ].some(mutation => mutation.isLoading)

  const initialValues = initialEditData as FundsTransferRo

  return (
    <Dialog maxWidth="xs" {...props}>
      <WithLoader isLoading={mode === 'edit' && isLoadingInitialEditData}>
        <DialogTitle>{header[mode][type]}</DialogTitle>
        {customError && <Alert severity="error">{customError}</Alert>}
        <Form<CreateTransferModalSchema | EditTransferModalSchema>
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={
            {
              ...initialValues,
              ...(type === 'personal' &&
                mode === 'edit' && {
                  sender: initialValues?.sender || initialValues?.recipient,
                }),
            } as EditTransferModalSchema
          }
        >
          <TransferModalContent
            isSubmitting={isSubmitting}
            type={type}
            mode={mode}
            hasFundsRequest={!!initialValues?.fundsRequest?.id}
          />
        </Form>
      </WithLoader>
    </Dialog>
  )
}
