import { Box, BoxProps } from '@mui/material'

import logo from '@/assets/images/maxev-logo.png'

interface LogoProps extends BoxProps {
  width: number;
  height: number;
}

export const Logo = ({ width, height, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <img width={width} height={height} src={logo} alt="MaxEV logo" />
    </Box>
  )
}
