import { UsersDataRo } from '@/services/data-contracts'
import { SelectOption } from '@/components/Forms/Select'

type MapperOptions = {
  excludeCurrentUser?: {
    enable: boolean
    currentUserId: number
  }
}

export const usersToSelectOptions = (users?: UsersDataRo['data'], additionalOptions?: MapperOptions): SelectOption[] => {
  if (!users?.length) {
    return []
  }

  return (users || []).reduce<SelectOption[]>((users, user) => {
    const isCurrentUser = additionalOptions?.excludeCurrentUser?.currentUserId === user.id
    const needToExcludeCurrentUser = additionalOptions?.excludeCurrentUser?.enable

    if (needToExcludeCurrentUser && isCurrentUser) {
      return users
    }

    users.push({
      id: user.id,
      label: user.username,
    })

    return users
  }, [])
}
