import { AxiosResponse } from 'axios'

import { FundsRequestCreateDto, FundsRequestEditDto, FundsRequestRo, FundsRequestsControllerGetFundsRequestsParams, FundsRequestsDataRo } from '../data-contracts'
import { Request } from '../Request'

export const FundsRequests = {
  create: async (params: FundsRequestCreateDto) => {
    return Request.post<FundsRequestCreateDto, AxiosResponse<FundsRequestRo>>('/funds-requests', params)
  },
  get: async (params?: FundsRequestsControllerGetFundsRequestsParams) => {
    return Request.get<FundsRequestsControllerGetFundsRequestsParams, AxiosResponse<FundsRequestsDataRo>>('/funds-requests', { params })
  },
  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<FundsRequestRo>>(`/funds-requests/${id}`)
  },
  edit: async (id: number, params: FundsRequestEditDto) => {
    return Request.patch<FundsRequestEditDto, AxiosResponse<FundsRequestRo>>(`/funds-requests/${id}`, params)
  },
  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/funds-requests/${id}`)
  },
}
