import * as yup from 'yup'

export type DeclinedPlayGameRequestModalSchema = {
  prize: number
  entries: number
}

export const declinedPlayGameRequestModalSchema = yup.object({
  prize: yup
    .number()
    .typeError('Укажите призовые')
    .min(0, 'Призовые не могут быть меньше 0')
    .required('Укажите призовые'),
  entries: yup
    .number()
    .typeError('Укажите кол-во входов в турнир')
    .integer('Должно быть целым числом')
    .min(1, 'Кол-во входов в турнир не может быть меньше 1')
    .required('Укажите кол-во входов в турнир'),
})
