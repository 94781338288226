import * as yup from 'yup'
import {
  USER_REPORT_BB_100_MONTH_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD,
  USER_REPORT_EVBB_100_MONTH_FIELD,
  USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD,
  USER_REPORT_HANDS_COUNT_FIELD,
} from '@/constants'

export type UserReportModalSchema = {
  fields: Record<string, unknown>
  hands?: number[]
  screenshots?: number[]
}

export const userReportModalSchema = yup.object({
  fields: yup.object({
    [USER_REPORT_HANDS_COUNT_FIELD]: yup.number().typeError('Заполните поле').required('Заполните поле'),
    [USER_REPORT_EVBB_100_MONTH_FIELD]: yup.number().typeError('Заполните поле').required('Заполните поле'),
    [USER_REPORT_BB_100_MONTH_FIELD]: yup.number().typeError('Заполните поле').required('Заполните поле'),
    [USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD]: yup.number().typeError('Заполните поле').required('Заполните поле'),
    [USER_REPORT_EVBB_100_100K_HANDS_FIELD]: yup.number().typeError('Заполните поле').required('Заполните поле'),
    [USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD]: yup.number().typeError('Заполните поле').required('Заполните поле'),
    [USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD]: yup.number().typeError('Заполните поле').required('Заполните поле'),
  }),
  hands: yup.array().optional(),
  screenshots: yup.array().optional(),
})
