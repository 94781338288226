import { ReactNode } from 'react';
import { format } from 'date-fns'
import { Box, Card, CardContent, Typography } from '@mui/material'

export interface PlayerCardProps {
  user: {
    username: string;
    rank: number;
    createdAt: string | Date;
  }
  children?: ReactNode
}

export const PlayerCard = ({ user, children }: PlayerCardProps) => {
  const date = (() => {
    try {
      return new Date(user.createdAt)
    } catch (e) {
      return null
    }
  })();

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h4">
            {user.username}
          </Typography>
          <Typography>
            (Ранг: {user.rank})
          </Typography>
        </Box>
        {date && (
          <Box mt={2}>
            <Typography>Дата прихода в проект: {format(date, 'dd.MM.yyyy')}</Typography>
          </Box>
        )}
        {children}
      </CardContent>
    </Card>
  )
}
