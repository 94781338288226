import { Box } from '@mui/material'

import { PlayerRoomBalances } from '@/components/Containers/PlayerRoomsBalances/PlayerRoomBalances'
import { PlayerResultsCard } from '@/components/Containers/PlayerResultsCard/PlayerResultsCard'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'

export const UserBalancesPage = () => {
  return (
    <DashboardLayout>
      <Box mb={4}>
        <PlayerResultsCard />
      </Box>
      <PlayerRoomBalances />
    </DashboardLayout>
  )
}
