import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { UserGroups } from '@/services/api/user-groups'
import { UserGroupCreateDto, UserGroupCreateRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useCreateUserGroup = (props?: UseMutationOptions<UserGroupCreateRo, AxiosErrorResponse, UserGroupCreateDto>) => {
  const queryClient = useQueryClient()

  return useMutation<UserGroupCreateRo, AxiosErrorResponse, UserGroupCreateDto>(
    ['user-groups'],
    async (params: UserGroupCreateDto) => {
      const response = await UserGroups.create(params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-groups', 'get'])
        props?.onSuccess?.(...args)
      },
    })
}
