import { Cabinet } from '@/services/api/cabinet'
import {
  SetPlayerRoomBalancesRo,
  SetUserRoomBalanceByIdDto,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useSetPlayerRoomBalancesById = (
  props?: UseMutationOptions<
    SetPlayerRoomBalancesRo,
    AxiosErrorResponse,
    SetUserRoomBalanceByIdDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    SetPlayerRoomBalancesRo,
    AxiosErrorResponse,
    SetUserRoomBalanceByIdDto
  >(
    ['user', 'cabinet', 'player', 'rooms-balances'],
    async (payload: SetUserRoomBalanceByIdDto) => {
      const balances = payload.balances.map(balance => ({
        ...balance,
        amount: balance.amount === null ? 0 : balance.amount,
      }))

      const response = await Cabinet.setPlayerRoomsBalancesById(
        payload.userId,
        {
          ...payload,
          balances,
        },
      )

      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries([
          'user',
          'cabinet',
          'player',
          'rooms-balances',
        ])
        await queryClient.invalidateQueries(['funds-transfers', 'balances'])
        await queryClient.invalidateQueries([
          'user',
          'cabinet',
          'admin',
          'player-funds',
        ])
        await queryClient.invalidateQueries([
          'user',
          'cabinet',
          'player',
          'player-funds',
        ])
        await queryClient.invalidateQueries([
          'funds-transfers',
          'admin',
          'personal',
          'total-all',
        ])
        props?.onSuccess?.(...args)
      },
    },
  )
}
