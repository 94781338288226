import {
  AuthorizeDto,
  ExportUserInfoDto,
  GetAuthUrlRo,
  GoogleSheetsUserRo,
  SetUserGoogleSheetsSettingsDto,
} from '@/services/data-contracts'
import { AxiosResponse } from 'axios'

import { Request } from '../Request'

export const GoogleSheets = {
  authorize: async (params: AuthorizeDto) => {
    return Request.post<AuthorizeDto, AxiosResponse<boolean>>(
      '/google-sheets/authorize',
      params,
    )
  },
  checkAlive: async () => {
    return Request.get<void, AxiosResponse<boolean>>(
      '/google-sheets/authorize/check-alive',
    )
  },
  getAuthUrl: async () => {
    return Request.get<void, AxiosResponse<GetAuthUrlRo>>(
      '/google-sheets/authorize/get-auth-url',
    )
  },
  getUserSettings: async (userId: number) => {
    return Request.get<void, AxiosResponse<GoogleSheetsUserRo>>(
      `/google-sheets/settings/user/${userId}`,
    )
  },
  setUserSettings: async (
    userId: number,
    params: SetUserGoogleSheetsSettingsDto,
  ) => {
    return Request.post<
      SetUserGoogleSheetsSettingsDto,
      AxiosResponse<GoogleSheetsUserRo>
    >(`/google-sheets/settings/user/${userId}`, params)
  },
  exportUserInfo: async (userId: number, params: ExportUserInfoDto) => {
    return Request.post<void, AxiosResponse<void>>(
      `/google-sheets/export-user-info/${userId}`,
      params,
    )
  },
  exportProfit: async (googleSheetId: string) => {
    return Request.post<void, AxiosResponse<void>>(
      `/google-sheets/export-profit/${googleSheetId}`,
    )
  },
}
