import { FundsTransfers } from '@/services/api/funds-transfers'
import {
  FundsTransferEditPersonalDto,
  FundsTransferRo,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useEditPersonalFundsTransfer = (
  id: number,
  props?: UseMutationOptions<
    FundsTransferRo,
    AxiosErrorResponse,
    FundsTransferEditPersonalDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    FundsTransferRo,
    AxiosErrorResponse,
    FundsTransferEditPersonalDto
  >(
    ['funds-transfers', 'personal', id],
    async (payload: FundsTransferEditPersonalDto) => {
      const response = await FundsTransfers.editPersonalTransfer(id, payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['funds-transfers', 'any'])
        await queryClient.invalidateQueries(['funds-transfers', 'personal'])
        await queryClient.invalidateQueries(['funds-transfers', 'balances'])
        await queryClient.invalidateQueries(['user', 'cabinet'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
