import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GameRequestsControllerFindAllParams, GameRequestsDataRo } from '@/services/data-contracts'
import { GameRequests } from '@/services/api/game-requests'
import { AxiosErrorResponse } from '@/types'

export const useGetGameRequests = (
  params: GameRequestsControllerFindAllParams = {},
  props?: UseQueryOptions<GameRequestsDataRo, AxiosErrorResponse>,
) => {
  return useQuery<GameRequestsDataRo, AxiosErrorResponse>(
    ['game-requests', params],
    async () => {
      const response = await GameRequests.get(params)
      return response.data
    },
    props,
  )
}
