import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { ru as dateFnsRuLocale } from 'date-fns/locale'

export const TableDate = (value: string | Date) => {
  try {
    const date = new Date(value)

    return (
      <TableColumn minWidth={80} maxWidth={80}>
        <Typography variant="body2">{format(date, 'dd.MM.yyyy')}</Typography>
        <Typography variant="body2">
          ({format(date, 'EEEEEE', { locale: dateFnsRuLocale })})
        </Typography>
      </TableColumn>
    )
  } catch (e) {
    return '-'
  }
}
