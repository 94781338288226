/* eslint-disable prefer-destructuring */
import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter'
import { green, red, yellow } from '@mui/material/colors'

export const GOOD_TOURNAMENT = '1'
export const WARNING_TOURNAMENT = '2'
export const BAD_TOURNAMENT = '3'

export const GOOD_TOURNAMENT_COLOR = green[50]
export const WARNING_TOURNAMENT_COLOR = yellow[50]
export const BAD_TOURNAMENT_COLOR = red[50]
export const GOOD_TOURNAMENT_DARK_COLOR = green[50]
export const WARNING_TOURNAMENT_DARK_COLOR = yellow[50]
export const BAD_TOURNAMENT_DARK_COLOR = red[50]

export const TOURNAMENT_COLOR_MAP = {
  [GOOD_TOURNAMENT]: GOOD_TOURNAMENT_COLOR,
  [WARNING_TOURNAMENT]: WARNING_TOURNAMENT_COLOR,
  [BAD_TOURNAMENT]: BAD_TOURNAMENT_COLOR,
}

export const TOURNAMENT_COLOR_DARK_MAP = {
  [GOOD_TOURNAMENT]: GOOD_TOURNAMENT_DARK_COLOR,
  [WARNING_TOURNAMENT]: WARNING_TOURNAMENT_DARK_COLOR,
  [BAD_TOURNAMENT]: BAD_TOURNAMENT_DARK_COLOR,
}

export const TOURNAMENT_COLOR_SELECT_OPTIONS: MultipleSelectOption[] = [
  {
    id: GOOD_TOURNAMENT,
    label: 'Рекомендуемый',
    avatar: {
      alt: 'Рекомендуемый',
      color: GOOD_TOURNAMENT_COLOR,
    },
  },
  {
    id: WARNING_TOURNAMENT,
    label: 'По усмотрению',
    avatar: {
      alt: 'По усмотрению',
      color: WARNING_TOURNAMENT_COLOR,
    },
  },
  {
    id: BAD_TOURNAMENT,
    label: 'Не рекомендуется',
    avatar: {
      alt: 'Не рекомендуется',
      color: BAD_TOURNAMENT_COLOR,
    },
  },
]
