import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { UserGroupDataRo, UserGroupsControllerGetParams } from '@/services/data-contracts'
import { UserGroups } from '@/services/api/user-groups'
import { AxiosErrorResponse } from '@/types'

export const useGetUserGroups = (
  params: UserGroupsControllerGetParams,
  props?: UseQueryOptions<UserGroupDataRo, AxiosErrorResponse>,
) => {
  return useQuery<UserGroupDataRo, AxiosErrorResponse>(
    ['user-groups', 'get', params],
    async () => {
      const response = await UserGroups.get(params)
      return response.data
    },
    props,
  )
}
