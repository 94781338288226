import * as yup from 'yup'

export type UserContractModalSchema = {
  name: string
  financialInterest: number
  playerShare: number
  projectShare: number
}

export const userContractModalSchema = yup.object({
  name: yup
    .string()
    .required('Введите название')
    .max(255, 'Максимальная длина 255 символов'),
  financialInterest: yup
    .number()
    .max(100, 'Процент финансирования не может быть выше 100%')
    .min(0, 'Процент финансирования не может быть ниже 0%')
    .required('Укажите процент финансирования'),
  playerShare: yup
    .number()
    .max(100, 'Доля игрока не может быть выше 100%')
    .min(0, 'Доля игрока не может быть ниже 0%')
    .required('Укажите долю игрока в процентах')
    .test(
      'correct-sum',
      'Доля игрока и доля проекта, в сумме должна составлять 100%',
      function () {
        const { playerShare = 0, projectShare = 0 } = this.parent as any

        return playerShare + projectShare === 100
      },
    ),
  projectShare: yup
    .number()
    .max(100, 'Доля проекта не может быть выше 100%')
    .min(0, 'Доля проекта не может быть ниже 0%')
    .required('Укажите долю проекта в процентах')
    .test(
      'correct-sum',
      'Доля игрока и доля проекта, в сумме должна составлять 100%',
      function () {
        const { playerShare = 0, projectShare = 0 } = this.parent as any

        return playerShare + projectShare === 100
      },
    ),
})
