import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { countryCodes } from '@/constants/generated/country-codes'
import { Typography, Box } from '@mui/material'

export const TableUserCountry = (value: string) => {
  const country = value in countryCodes ? countryCodes[value] : null

  return (
    <TableColumn width={90}>
      {country && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2">{country.name}</Typography>
        </Box>
      )}
      {!country && <Typography variant="body2">Не указана</Typography>}
    </TableColumn>
  )
}
