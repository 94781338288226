import * as yup from 'yup'

export type RoomNetworkModalSchema = {
  name: string
  rooms: number[]
}

export const roomNetworkModalSchema = {
  generate: () => {
    return yup.object().shape({
      name: yup
        .string()
        .required('Обязательное поле')
        .max(255, 'Максимальная длина имени 255 символов'),
      rooms: yup.array().optional().transform((value) => Array.isArray(value) ? value : []),
    })
  },
}
