import { Cabinet } from '@/services/api/cabinet'
import { PlayersRoomsBalancesRoomRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

type RoomId = number

export type BalanceTreeRo = {
  [key: RoomId]: PlayersRoomsBalancesRoomRo
}

export const useGetPlayerRoomsBalancesByToken = (
  props?: UseQueryOptions<BalanceTreeRo, AxiosErrorResponse>,
) => {
  return useQuery<BalanceTreeRo, AxiosErrorResponse>(
    ['user', 'cabinet', 'player', 'rooms-balances', 'by-token'],
    async () => {
      const response = await Cabinet.getPlayerRoomsBalancesByToken()
      return response.data as unknown as BalanceTreeRo
    },
    props,
  )
}
