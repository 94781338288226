import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { UsersControllerGetParams, UsersDataRo } from '@/services/data-contracts'
import { Users } from '@/services/api/users'
import { AxiosErrorResponse } from '@/types'

export const useGetUsers = (params: UsersControllerGetParams, props?: UseQueryOptions<UsersDataRo, AxiosErrorResponse>) => {
  return useQuery<UsersDataRo, AxiosErrorResponse>(
    ['users', 'get', params],
    async () => {
      const response = await Users.get(params)
      return response.data
    },
    props,
  )
}
