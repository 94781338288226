import React from 'react'

import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import { IconButton } from '@mui/material'

export const TableScreenshot = (value?: string) => {
  if (!value) {
    return ''
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()

    window.open(value, '_blank')
  }

  return (
    <IconButton
      color="primary"
      title="Открыть изображение"
      role="link"
      data-href={value}
      onClick={handleClick}
    >
      <ImageSearchIcon />
    </IconButton>
  )
}
