import { Cabinet } from '@/services/api/cabinet'
import { PlayerRoomSettingsRo, RoomRelationRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export type UseGetPlayerRoomSettingsPayload = {
  userId: number
}

export type Setting = {
  room: RoomRelationRo
  nickname: string
  transferInfo: string
}

export type UseGetPlayerRoomSettingsResponse = Omit<
  PlayerRoomSettingsRo,
  'data'
> & {
  data: Record<number, Setting>
}

export const useGetPlayerRoomsSettings = (
  payload: UseGetPlayerRoomSettingsPayload,
  props?: UseQueryOptions<UseGetPlayerRoomSettingsResponse, AxiosErrorResponse>,
) => {
  return useQuery<UseGetPlayerRoomSettingsResponse, AxiosErrorResponse>(
    ['user', 'cabinet', 'player', 'rooms-settings', payload.userId],
    async () => {
      const response = await Cabinet.getPlayerRoomSettings(payload.userId)
      return response.data as UseGetPlayerRoomSettingsResponse
    },
    props,
  )
}
