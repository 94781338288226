import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { UserReports } from '@/services/api/user-reports'
import { UserReportEditDto, UserReportEditRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

type UseEditUserReportPayload = {
  id: number
} & UserReportEditDto;

export const useEditUserReport = (props?: UseMutationOptions<UserReportEditRo, AxiosErrorResponse, UseEditUserReportPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<UserReportEditRo, AxiosErrorResponse, UseEditUserReportPayload>(
    ['user-reports'],
    async (payload: UseEditUserReportPayload) => {
      const { id, ...params } = payload
      const response = await UserReports.edit(id, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-reports'])
        props?.onSuccess?.(...args)
      },
    })
}
