import { Form } from '@/components/Forms/Form'
import { TextField } from '@/components/Forms/TextField'
import { GameRequestPlayResult } from '@/components/Modals/DeclinedPlayGameRequestModal/components/GameRequestPlayResult.tsx'
import {
  DeclinedPlayGameRequestModalSchema,
  declinedPlayGameRequestModalSchema,
} from '@/components/Modals/DeclinedPlayGameRequestModal/schema/declinedPlayGameRequestModal.schema'
import { GameRequestSingleRo } from '@/services/data-contracts.ts'
import {
  useHandleStageDeclinedPlayGameRequest,
  UseHandleStageDeclinedPlayGameRequestProps,
} from '@/services/hooks/game-requests/useHandleStageDeclinedPlayGameRequest'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'

import { AxiosErrorResponse } from '@/types'

export interface DeclinedPlayGameRequestModalProps
  extends Omit<DialogProps, 'onError' | 'onSuccess'> {
  gameRequest: GameRequestSingleRo
  mode: 'declined_played' | 'declined_not_played'
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const DeclinedPlayGameRequestModal = ({
  gameRequest,
  mode,
  onSuccess,
  onError,
  ...props
}: DeclinedPlayGameRequestModalProps) => {
  const handleStageDeclinedPlayGameRequest =
    useHandleStageDeclinedPlayGameRequest({
      onSuccess,
      onError,
    })

  const titleIcon = {
    declined_played: <CheckCircleIcon color="success" />,
    declined_not_played: <UnpublishedIcon color="error" />,
  }

  const titleMap = {
    declined_played: 'Турнир сыгран. Уточнение данных',
    declined_not_played: 'Турнир не сыгран. Подтверждение',
  }

  const handleSubmit = (data?: DeclinedPlayGameRequestModalSchema) => {
    handleStageDeclinedPlayGameRequest.mutate({
      id: gameRequest.id,
      status: mode,
      timezone: USER_TIMEZONE,
      ...(data && {
        playResult: {
          prize: data.prize,
          entries: data.entries,
        },
      }),
    } as UseHandleStageDeclinedPlayGameRequestProps)
  }

  return (
    <Dialog maxWidth="xs" {...props}>
      <Box minWidth={400}>
        <DialogTitle>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {titleIcon[mode]}
            {titleMap[mode]}
          </Box>
        </DialogTitle>
        <DialogContent>
          {mode === 'declined_played' && (
            <Form<DeclinedPlayGameRequestModalSchema>
              onSubmit={handleSubmit}
              validationSchema={declinedPlayGameRequestModalSchema}
            >
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    name="prize"
                    label="Сумма призовых"
                    placeholder="Укажите число"
                    textFieldProps={{
                      type: 'number',
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="entries"
                    label="Кол-во входов в турнир"
                    placeholder="Укажите целое число"
                    textFieldProps={{
                      type: 'number',
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GameRequestPlayResult
                    buyIn={gameRequest.buyIn}
                    title={gameRequest.title}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={handleStageDeclinedPlayGameRequest.isLoading}
                >
                  Подтвердить
                </Button>
              </Box>
            </Form>
          )}
          {mode === 'declined_not_played' && (
            <Box>
              <Typography color="grey">
                Нажимая кнопку подтвердить, вы подтверждаете, что не играли
                турнир.
              </Typography>
              <br />
              <Typography color="grey">Турнир: {gameRequest.title}</Typography>
              <Box mt={2}>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  type="button"
                  fullWidth
                  disabled={handleStageDeclinedPlayGameRequest.isLoading}
                >
                  Подтвердить
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  )
}
