import { useMemo, useState } from 'react'

import { ScheduledTournamentsTable } from '@/components/Containers/ScheduledTournamentsTable/ScheduledTournamentsTable'
import { Form } from '@/components/Forms/Form'
import { PhotoUploader } from '@/components/Forms/PhotoUploader'
import { TextField } from '@/components/Forms/TextField'
import {
  chooseTournamentSchema,
  ChooseTournamentSchema,
} from '@/components/Modals/CreateGameRequestModal/schema/chooseTournament.schema'
import { useCreateGameRequestByScheduledTournament } from '@/services/hooks/game-requests/useCreateGameRequestByScheduledTournament'
import {
  Alert,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material'

import { AxiosErrorResponse } from '@/types'
import { useGetScheduledTournamentById } from '@/services/hooks/scheduled-tournaments/useGetScheduledTournamentById.ts'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader.tsx'
import { useGetRoomNetworkById } from '@/services/hooks/room-networks/useGetRoomNetworkById.ts'
import { Select, SelectOption } from '@/components/Forms/Select.tsx'
import { roomsToSelectOptions } from '@/utils/mappers/roomsToSelectOptions.ts'
import { useGetPlayerRoomsSettings } from '@/services/hooks/cabinet/player/useGetPlayerRoomsSettings.ts'
import { useCurrentUser } from '@/services/hooks/useCurrentUser.ts'
import { isEmptyRoomSetting } from '@/utils/isEmptyRoomSetting.ts'

interface ChooseTournamentStepProps {
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
  onToggleStep?: () => void
}

/**
 * Шаг выбора турнира для запроса на игру.
 * Выводится, если не удалось найти турнир среди нужных.
 * @constructor
 */
export const ChooseTournamentStep = ({
  onSuccess,
  onError,
  onToggleStep,
}: ChooseTournamentStepProps) => {
  const [tournamentId, setTournamentId] = useState<number | null>(null)

  const { currentUser, isFetching: isFetchingCurrentUser } = useCurrentUser()

  const {
    isLoading: isSubmitting,
    mutate: createGameRequestByScheduledTournament,
  } = useCreateGameRequestByScheduledTournament({ onSuccess, onError })

  const {
    data: choosedTournament,
    isFetching: isFetchingChoosedTournament,
    isError: isChoosedTournamentError,
    refetch: refetchChoosedTournament,
  } = useGetScheduledTournamentById(tournamentId || 0, {
    enabled: Boolean(tournamentId || 0)
  });

  const {
    data: choosedNetwork,
    isFetching: isFetchingChoosedNetwork,
    isError: isChoosedNetworkError,
    refetch: refetchChoosedNetwork,
  } = useGetRoomNetworkById(choosedTournament?.room?.network?.id || 0, {
    enabled: Boolean(choosedTournament?.room?.network?.id || 0),
  });

  const {
    data: playerRoomsSettings,
    isFetching: isFetchingRoomSettings,
    isError: isRoomSettingsError,
    refetch: refetchRoomSettings,
  } = useGetPlayerRoomsSettings({
    userId: currentUser?.id || 0,
  }, {
    enabled: Boolean(currentUser?.id)
  })

  const handleSubmit = (data: ChooseTournamentSchema) => {
    if (!tournamentId) {
      return
    }

    createGameRequestByScheduledTournament({
      studentComment: data.studentComment,
      scheduledTournament: {
        id: tournamentId,
      },
      screenshot: data.screenshot?.id
        ? {
            id: data.screenshot.id,
          }
        : undefined,
      room: data?.room?.id ? {
        id: +data.room.id,
      } : undefined,
    })
  }

  const isFetching = isFetchingChoosedTournament
    || isFetchingChoosedNetwork
    || isFetchingCurrentUser
    || isFetchingRoomSettings;

  const isError = isChoosedTournamentError
    || isChoosedNetworkError
    || isRoomSettingsError;

  const hasNetwork = !!choosedNetwork;
  const validationSchema = chooseTournamentSchema.generate(hasNetwork);

  const roomsOptions = useMemo(() => {
    if (!choosedNetwork?.rooms) {
      return [];
    }

    const options: Array<SelectOption & { hasValidSettings?: boolean }> = roomsToSelectOptions(choosedNetwork.rooms);

    if (!playerRoomsSettings) {
      return options;
    }

    // Если один из них заполнен, автовыбираем его, ставим на 1 место
    // Если ни один не заполнен, даем на выбор
    // Если заполнено больше, чем один, сортируем

    return options
      .map((room) => {
        const roomSettings = playerRoomsSettings?.data?.[+room.id];
        const hasValidSettings = !isEmptyRoomSetting(roomSettings?.nickname)

        return {
          ...room,
          label: [room.label, hasValidSettings ? `(✓)` : '']
            .filter(Boolean)
            .join(' '),
          hasValidSettings,
        }
      })
      .sort((a, b) => {
        if (!a.hasValidSettings && b.hasValidSettings) return 1;
        if (a.hasValidSettings && !b.hasValidSettings) return -1;

        return 0;
      })
  }, [choosedNetwork, playerRoomsSettings]);

  return (
    <>
      {tournamentId === null && (
        <>
          <DialogTitle>Выберите турнир</DialogTitle>
          <Divider />
          <Alert
            severity="info"
            action={
              <Button color="inherit" size="small" onClick={onToggleStep}>
                Вручную
              </Button>
            }
          >
            Используйте фильтры в правом верхнем углу.
            <br />
            <br />
            Если не удается найти турнир по выбранным фильтрам, можно заполнить
            вручную.
          </Alert>
          <Divider />
          <DialogContent>
            <ScheduledTournamentsTable onRowClick={id => setTournamentId(id)} />
          </DialogContent>
        </>
      )}
      {tournamentId !== null && (
        <>
          <DialogTitle>Завершение создания запроса на игру</DialogTitle>
          <WithLoader isLoading={isFetching}>
            {(isError || !choosedTournament) && (
              <Box pb={2}>
                <Alert severity="error" action={(
                  <Button
                    size="small"
                    color="inherit"
                    onClick={() => {
                      void refetchChoosedTournament()
                      void refetchChoosedNetwork()
                      void refetchRoomSettings()
                    }}
                  >
                    Повторить
                  </Button>
                )}>
                  Не удалось загрузить данные о турнире
                </Alert>
              </Box>
            )}
            {!isError && choosedTournament && (
              <>
                <Alert
                  severity="success"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => setTournamentId(null)}
                    >
                      Отменить
                    </Button>
                  }
                >
                  Выбран турнир "{choosedTournament.name}
                  <br />
                </Alert>
                <Divider />
                <DialogContent>
                  <Form<ChooseTournamentSchema>
                    initialValues={{
                      ...(hasNetwork && roomsOptions?.[0]?.hasValidSettings && {
                        room: {
                          id: +roomsOptions[0].id,
                        }
                      })
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    reValidateMode="onBlur"
                  >
                    <Grid container spacing={1}>
                      {choosedNetwork && (
                        <Grid item xs={12}>
                          <Select
                            name="room.id"
                            label={`Рум в сети "${choosedNetwork.name}"`}
                            options={roomsOptions}
                            enableAvatar
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          name="studentComment"
                          label="Комментарий"
                          textFieldProps={{
                            multiline: true,
                            rows: 2,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PhotoUploader
                          name="screenshot.id"
                          label="Скриншот лобби (необязательно)"
                          buttonProps={{
                            color: 'secondary',
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        sx={{ width: '100%' }}
                        type="submit"
                      >
                        Создать
                      </Button>
                    </Box>
                  </Form>
                </DialogContent>
              </>
            )}
          </WithLoader>
        </>
      )}
    </>
  )
}
