import { IconButton, IconButtonProps } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

export const AddEntityButton = (props: IconButtonProps) => {
  return (
    <IconButton style={{ order: -1 }} {...props}>
      <AddIcon />
    </IconButton>
  )
}
