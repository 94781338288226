export const makeColumnFixed = ({
  columnPosition,
  columnWidth = 40,
  background,
}: {
  columnPosition: number
  columnWidth?: number
  background?: any
}) => {
  return {
    setCellProps: () => ({
      style: {
        width: `${columnWidth}px`,
        whiteSpace: 'wrap',
        position: 'sticky',
        left: `${columnPosition * columnWidth * 2}px`,
        background,
        zIndex: 101 - columnPosition,
      },
    }),
    setCellHeaderProps: () => ({
      style: {
        width: `${columnWidth}px`,
        whiteSpace: 'wrap',
        position: 'sticky',
        left: `${columnPosition * columnWidth * 2}px`,
        background,
        zIndex: 102 - columnPosition,
      },
    }),
  }
}
