import { useState } from 'react'

import { ChooseTournamentStep } from '@/components/Modals/CreateGameRequestModal/steps/ChooseTournament.step'
import { Dialog, DialogProps } from '@mui/material'

import { CreateGameRequestModalStep } from '@/components/Modals/CreateGameRequestModal/types'
import { AxiosErrorResponse } from '@/types'

import { ManuallyEnterTournamentStep } from './steps/ManuallyEnterTournament.step'

export interface CreateGameRequestModalProps
  extends Omit<DialogProps, 'onError'> {
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

export const CreateGameRequestModal = ({
  onSuccess,
  onError,
  ...props
}: CreateGameRequestModalProps) => {
  const [step, setStep] =
    useState<CreateGameRequestModalStep>('choose-tournament')

  return (
    <Dialog maxWidth="lg" {...props}>
      {step === 'choose-tournament' && (
        <ChooseTournamentStep
          onError={onError}
          onSuccess={onSuccess}
          onToggleStep={() => setStep('manually-enter-tournament')}
        />
      )}
      {step === 'manually-enter-tournament' && (
        <ManuallyEnterTournamentStep
          onError={onError}
          onSuccess={onSuccess}
          onToggleStep={() => setStep('choose-tournament')}
        />
      )}
    </Dialog>
  )
}
