import { useCallback, useState } from 'react'

import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { PhotoUploader } from '@/components/Forms/PhotoUploader'
import { Select } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { ROOMS_LIMITATIONS } from '@/constants'
import { Tournaments } from '@/services/api/tournaments'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms'
import { roomsToSelectOptions } from '@/utils/mappers/roomsToSelectOptions'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  debounce,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'

const MIN_SEARCH_LENGTH = 3

export interface CreateGameRequestModalContentProps {
  isSubmitting: boolean
}

export const ManuallyEnterTournamentContent = ({
  isSubmitting,
}: CreateGameRequestModalContentProps) => {
  const { defaultSortString } = useGetSort()

  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [tournamentsSuggestions, setTournamentsSuggestions] = useState<
    string[]
  >([])

  const { data: rooms, isLoading: isRoomsLoading } = useGetRooms({
    limit: 500,
    sort: defaultSortString,
    'category.some': ROOMS_LIMITATIONS.TOURNAMENTS,
  })
  const roomsSelectValues = roomsToSelectOptions(rooms ? rooms.data : [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchTournamentsSuggestions = useCallback(
    debounce(async event => {
      const searchValue = event.target.value || ''

      if (searchValue.length < MIN_SEARCH_LENGTH) {
        setTournamentsSuggestions([])
        setIsLoadingOptions(false)
        return
      }

      setIsLoadingOptions(true)

      try {
        const { data: response } = await Tournaments.get({
          limit: 10,
          offset: 0,
          'title.like': `%${searchValue.trim()}%`,
        })

        const uniqueNames = new Set<string>()

        for (const tournament of response?.data || []) {
          uniqueNames.add(tournament.title)
        }

        setTournamentsSuggestions([...uniqueNames])
        setIsLoadingOptions(false)
      } catch {
        setTournamentsSuggestions([])
        setIsLoadingOptions(false)
      }
    }, 500),
    [],
  )

  return (
    <WithLoader isLoading={isRoomsLoading}>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              name="room.id"
              label="Рум"
              options={roomsSelectValues}
              enableAvatar
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="date"
              label="Дата проведения"
              textFieldProps={{
                InputProps: {
                  type: 'date',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="startTime"
              label="Начало"
              textFieldProps={{
                InputProps: {
                  type: 'time',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              renderInput={params => (
                <TextField
                  name="title"
                  label="Название турнира"
                  textFieldProps={{
                    onInput: handleSearchTournamentsSuggestions,
                    ...params,
                  }}
                />
              )}
              filterOptions={option => option}
              options={tournamentsSuggestions}
              loading={isLoadingOptions}
              loadingText={
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  <Typography>Ищем турниры...</Typography>
                </Box>
              }
              noOptionsText="Похожих турниров не найдено"
              clearOnBlur={false}
              clearOnEscape={false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="buyIn"
              label="Бай-ин"
              textFieldProps={{
                InputProps: {
                  type: 'number',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PhotoUploader
              name="screenshot.id"
              label="Скриншот лобби (обязательно)"
              buttonProps={{
                color: 'secondary',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="studentComment"
              label="Комментарий"
              textFieldProps={{
                multiline: true,
                rows: 2,
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Button
            variant="contained"
            sx={{ width: '100%' }}
            type="submit"
            disabled={isSubmitting}
          >
            Создать
          </Button>
        </Box>
      </DialogContent>
    </WithLoader>
  )
}
