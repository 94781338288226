import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { PlayerCard } from '@/components/UI/PlayerCard/PlayerCard'
import { UserReportsTable } from '@/components/Containers/UserReports/UserReportsTable'
import { WithAlert } from '@/components/Utils/WithAlert/WithAlert'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { useGetUserById } from '@/services/hooks/users/useGetUserById'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { RoleHelper } from '@/utils/RoleHelper'

export const UserReportsPage = () => {
  const params = useParams()
  const userId = params?.id ? +params?.id : 0

  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser()
  const { data: user, isLoading: isUserLoading } = useGetUserById(userId, { enabled: !!userId })

  const isSameUser = currentUser?.id === userId

  const canViewEveryUserReport = RoleHelper.isAdmin(currentUser?.role) || !RoleHelper.isCoach(currentUser?.role)

  return (
    <DashboardLayout>
      <WithLoader isLoading={isLoadingCurrentUser || isUserLoading}>
        {user && currentUser && (
          <WithAlert isRestricted={!isSameUser && !canViewEveryUserReport}>
            <Box>
              <Box mb={2}>
                <PlayerCard user={user} />
              </Box>
              <Box>
                <UserReportsTable
                  userId={user.id}
                  canAdd={isSameUser}
                  canEdit={RoleHelper.isAdmin(currentUser.role)}
                  canDelete={RoleHelper.isAdmin(currentUser.role)}
                />
              </Box>
            </Box>
          </WithAlert>
        )}
      </WithLoader>
    </DashboardLayout>
  )
}
