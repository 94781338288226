import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Rooms } from '@/services/api/rooms'
import { RoomCreateDto, RoomCreateRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useCreateRoom = (props?: UseMutationOptions<RoomCreateRo, AxiosErrorResponse, RoomCreateDto>) => {
  const queryClient = useQueryClient()

  return useMutation<RoomCreateRo, AxiosErrorResponse, RoomCreateDto>(
    ['rooms'],
    async (payload: RoomCreateDto) => {
      const response = await Rooms.create(payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['rooms'])
        props?.onSuccess?.(...args)
      },
    })
}
