import React from 'react'

import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'

export const CustomSettingWrap = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[]
}) => {
  return (
    <Box
      sx={{
        borderSize: 1,
        borderColor: grey[300],
        borderStyle: 'solid',
        borderRadius: 4,
        p: 3,
      }}
    >
      {children}
    </Box>
  )
}
