import { MouseEventHandler, useCallback } from 'react'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { CustomSettingRo } from '@/services/data-contracts'
import { useGetCustomSettingByName } from '@/services/hooks/custom-settings/useGetCustomSettingByName'
import { Box, Typography, Paper } from '@mui/material'

import { TextSettingValues } from '@/components/CustomSettings/types'

import styles from './Text.view.module.scss'

type TextViewProps =
  | {
      name: string
    }
  | {
      previewContent: TextSettingValues
    }

export const TextView = (props: TextViewProps) => {
  const { data, isInitialLoading, isFetched } = useGetCustomSettingByName(
    { name: 'name' in props ? props.name : '' },
    { enabled: 'name' in props },
  )

  const setting = data as CustomSettingRo
  const value =
    'previewContent' in props
      ? props.previewContent
      : (setting?.value as TextSettingValues)

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
    const node = event.target;

    if (!(node instanceof Element)) {
      return;
    }

    tryCopyToClipboard(node);
  }, []);

  if (isFetched && !value?.content) {
    return null
  }

  return (
    <WithLoader isLoading={isInitialLoading}>
      {value?.content && (
        <Paper>
          <Box p={2}>
            {!!value?.title && (
              <Typography variant="h4" mb={2} component="h3">
                {value.title}
              </Typography>
            )}
            {!!value.content && (
              <Box
                className={styles.container}
                dangerouslySetInnerHTML={{ __html: value.content }}
                onClick={handleClick}
              />
            )}
          </Box>
        </Paper>
      )}
    </WithLoader>
  )
}

function tryCopyToClipboard(node: Element) {
  const COPY_TO_CLIPBOARD_CLASS = 'js-copy-to-clipboard'
  const COPY_TO_CLIPBOARD_CLASS_SUCCESS = 'js-copy-to-clipboard--success'

  if (!node.classList.contains(COPY_TO_CLIPBOARD_CLASS)) {
    return;
  }

  const range = document.createRange();
  range.selectNode(node);

  const selection = window.getSelection();

  if (!selection || !node.textContent) {
    return;
  }

  selection.removeAllRanges();
  selection.addRange(range);

  navigator.clipboard.writeText(node.textContent)
    .then(() => {
      if (document.body.contains(node)) {
        node.classList.add(COPY_TO_CLIPBOARD_CLASS_SUCCESS);
      }

      setTimeout(() => {
        if (document.body.contains(node)) {
          node.classList.remove(COPY_TO_CLIPBOARD_CLASS_SUCCESS);
        }
      }, 800)
    })
    .finally(() => {
      selection.empty();
    })
}
