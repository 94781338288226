import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { TournamentRulesControllerFindAllParams, TournamentRulesDataRo } from '@/services/data-contracts.ts'
import { AxiosErrorResponse } from '@/types.ts'
import { TournamentRules } from '@/services/api/tournament-rules.ts'

export const useGetTournamentRules = (
  params?: TournamentRulesControllerFindAllParams,
  props?: UseQueryOptions<TournamentRulesDataRo, AxiosErrorResponse>,
) => {
  return useQuery<TournamentRulesDataRo, AxiosErrorResponse>(
    ['tournament-rules', params],
    async () => {
      const response = await TournamentRules.get(params)
      return response.data
    },
    props,
  )
}
