import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Users } from '@/services/api/users'
import { UsersEditDto, UsersRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

type UserEditUserPayload = UsersEditDto & { id: number }

export const useEditUser = (props?: UseMutationOptions<UsersRo, AxiosErrorResponse, UserEditUserPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<UsersRo, AxiosErrorResponse, UserEditUserPayload>(
    ['users'],
    async (payload: UserEditUserPayload) => {
      const { id, ...params } = payload
      const response = await Users.edit(id, params)

      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['users'])
        props?.onSuccess?.(...args)
      },
    })
}
