import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { RoomNetworks } from '@/services/api/room-networks.ts'
import { RoomNetworkRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGetRoomNetworkById = (id: number, props?: UseQueryOptions<RoomNetworkRo, AxiosErrorResponse>) => {
  return useQuery<RoomNetworkRo, AxiosErrorResponse>(
    ['room-networks', id],
    async () => {
      const response = await RoomNetworks.getById(id)
      return response.data
    },
    props,
  )
}
