import {
  TournamentFiltersGetDto,
  TournamentFiltersSetDto,
  TournamentFiltersSetRo,
} from '@/services/data-contracts.ts'
import { Request } from '@/services/Request.ts'
import { AxiosResponse } from 'axios'

export const TournamentFilters = {
  set: async (params: TournamentFiltersSetDto) => {
    return Request.post<
      TournamentFiltersSetDto,
      AxiosResponse<TournamentFiltersSetRo>
    >(`/tournament-filters`, params)
  },
  getByName: async (name: string) => {
    return Request.get<
      TournamentFiltersGetDto,
      AxiosResponse<TournamentFiltersSetRo>
    >(`/tournament-filters/${name}`)
  },
  deleteById: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(
      `/tournament-filters/${id}`,
    )
  },
}
