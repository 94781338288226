import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { FundsTransfers } from '@/services/api/funds-transfers'
import { FundsTransfersControllerGetPersonalTransfersParams, FundsTransfersDataRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGetPersonalFundsTransfersByToken = (
  params?: FundsTransfersControllerGetPersonalTransfersParams,
  props?: UseQueryOptions<FundsTransfersDataRo, AxiosErrorResponse>,
) => {
  return useQuery<FundsTransfersDataRo, AxiosErrorResponse>(
    ['funds-transfers', 'personal', params],
    async () => {
      const response = await FundsTransfers.getPersonalTransfersByToken(params)
      return response.data
    },
    props,
  )
}
