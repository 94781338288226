import {
  GameRequestCreateByScheduledTournamentDto,
  GameRequestCreateDto,
  GameRequestCreateRo,
  GameRequestRo,
  GameRequestsControllerFindAllParams,
  GameRequestsDataRo,
  GameRequestStageApprovalDto,
  GameRequestStageDeclinedPlayDto,
} from '@/services/data-contracts'
import { AxiosResponse } from 'axios'

import { Request } from '../Request'

export const GameRequests = {
  create: async (params: GameRequestCreateDto) => {
    return Request.post<
      GameRequestCreateDto,
      AxiosResponse<GameRequestCreateRo>
    >('/game-requests', params)
  },
  createByScheduledTournament: async (
    params: GameRequestCreateByScheduledTournamentDto,
  ) => {
    return Request.post<
      GameRequestCreateByScheduledTournamentDto,
      AxiosResponse<GameRequestCreateRo>
    >('/game-requests/by-scheduled-tournament', params)
  },
  get: async (params: GameRequestsControllerFindAllParams) => {
    return Request.get<
      GameRequestsControllerFindAllParams,
      AxiosResponse<GameRequestsDataRo>
    >('/game-requests', { params })
  },
  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<GameRequestRo>>(
      `/game-requests/${id}`,
    )
  },
  handleStageApproval: async (
    id: number,
    params: GameRequestStageApprovalDto,
  ) => {
    return Request.post<
      GameRequestStageApprovalDto,
      AxiosResponse<GameRequestRo>
    >(`/game-requests/${id}/stage/approval`, params)
  },
  handleStageDeclinedPlay: async (
    id: number,
    params: GameRequestStageDeclinedPlayDto,
  ) => {
    return Request.post<
      GameRequestStageDeclinedPlayDto,
      AxiosResponse<GameRequestRo>
    >(`/game-requests/${id}/stage/declined-play`, params)
  },
  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/game-requests/${id}`)
  },
}
