import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Rooms } from '@/services/api/rooms'
import { AxiosErrorResponse } from '@/types'

type UseDeleteRoomPayload = {
  id: number;
}

export const useDeleteRoom = (props?: UseMutationOptions<boolean, AxiosErrorResponse, UseDeleteRoomPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteRoomPayload>(
    ['rooms'],
    async (payload: UseDeleteRoomPayload) => {
      const response = await Rooms.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['rooms'])
        props?.onSuccess?.(...args)
      },
    })
}
