import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter'
import { RoomsDataRo } from '@/services/data-contracts'

export const roomsToMultipleSelectOptions = (
  rooms?: RoomsDataRo['data'],
): MultipleSelectOption[] => {
  if (!rooms?.length) {
    return []
  }

  return (rooms || []).map(room => ({
    id: room.id,
    label: room.name,
    network: room?.network,
    avatar: {
      src: room.avatar?.location,
      alt: room.name,
    },
  }))
}
