import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import googleApiLogo from '@/assets/images/google-api.jpeg'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useGoogleAuthorize } from '@/services/hooks/google-sheets/useGoogleAuthorize'
import { useGoogleCheckAlive } from '@/services/hooks/google-sheets/useGoogleCheckAlive'
import { useGoogleGetAuthUrl } from '@/services/hooks/google-sheets/useGoogleGetAuthUrl'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'

export const GoogleSheetsAuth = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: isAlive, isFetching: isAliveFetching } = useGoogleCheckAlive()
  const { data: getAuthUrlData, isFetching: isGetAuthUrlDataFetching } =
    useGoogleGetAuthUrl()
  const authorizeMutation = useGoogleAuthorize()

  useEffect(() => {
    const authCode = searchParams.get('code')

    if (authCode) {
      authorizeMutation.mutate({
        code: authCode,
      })
      setSearchParams({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      sx={{
        maxWidth: 345,
        px: 2,
        pb: 2,
        height: 260,
      }}
      variant="outlined"
      raised
    >
      <CardMedia
        component="img"
        alt="Google api"
        height="100"
        image={googleApiLogo}
      />
      <CardContent>
        <Box mb={2}>
          <Typography variant="h4">Авторизация в Google API</Typography>
        </Box>
        <WithLoader isLoading={isAliveFetching}>
          <Box>
            <Typography sx={{ display: 'inline' }}>Статус: </Typography>
            <Typography
              sx={{ display: 'inline' }}
              color={isAlive === true ? 'secondary' : 'grey'}
            >
              {isAlive === true ? 'успех' : 'ожидание авторизации'}
            </Typography>
          </Box>
        </WithLoader>
      </CardContent>
      <CardActions sx={{ marginTop: 'auto' }}>
        <Button
          variant="contained"
          disabled={isGetAuthUrlDataFetching}
          href={getAuthUrlData ? getAuthUrlData.authUrl : '#'}
          target="_blank"
        >
          Авторизоваться
        </Button>
      </CardActions>
    </Card>
  )
}
