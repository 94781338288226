import { useState } from 'react'
import { Box, Button, IconButton, IconButtonProps, Tooltip, Typography } from '@mui/material'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

import AddIcon from '@mui/icons-material/Add'
import RequestPageIcon from '@mui/icons-material/RequestPage'

import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import { CreateTransferToFundsRequestModal } from '@/components/Modals/CreateTransferToFundsRequestModal/CreateTransferToFundsRequestModal'
import {
  FundsRequestModal,
  FundsRequestModalProps,
} from '@/components/Modals/FundsRequestModal/FundsRequestModal'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle'
import { TableUser } from '@/components/Tables/columns/TableUser/TableUser'
import { TableUserCountry } from '@/components/Tables/columns/TableUserCountry/TableUserCountry'
import { createMultipleSelectFilter } from '@/components/Tables/filters/createMultipleSelectFilter'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { FundsRequestModerationModal, FundsRequestModerationModalProps } from '@/components/Modals/FundsRequestModerationModal/FundsRequestModerationModal.tsx'
import { FundsRequestStatusEnum, getFundsRequestStatusTranslate, ROOMS_LIMITATIONS } from '@/constants'
import { countryCodesMultipleSelectOptions } from '@/constants/countryCodesSelectOptions'
import { useSnackBar } from '@/context/SnackbarContext'
import {
  FundsRequestsControllerGetFundsRequestsParams,
  FundsRequestsDataSingleRo,
} from '@/services/data-contracts'
import { useDeleteFundRequest } from '@/services/hooks/funds-requests/useDeleteFundRequest'
import { useGetFundsRequests } from '@/services/hooks/funds-requests/useGetFundsRequests'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { RoleHelper } from '@/utils/RoleHelper'
import { useGetRoomsSelectValues } from '@/components/Tables/hooks/useGetRoomsSelectValues.ts'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'

const FILTERS_DEFAULT: FundsRequestsControllerGetFundsRequestsParams = {
  'country.in': undefined,
  'room.in': undefined,
}

export const FundsRequestsTable = () => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()
  const tableSort = useTableSort(['createdAt', 'ASC'])

  const { showSnackBar } = useSnackBar()
  const [filters, setFilters] = useState(FILTERS_DEFAULT)

  const { currentUser } = useCurrentUser()
  const { roomsSelectValues, isRoomsFetching } = useGetRoomsSelectValues({
    category: ROOMS_LIMITATIONS.FUNDS_REQUESTS,
  })
  const { data: fundsRequests, isFetching: isGetFundsRequestsFetching } =
    useGetFundsRequests({
      ...tablePagination.queryParams,
      ...tableSort.queryParams,
      ...filters,
    })
  const { showModal } = useModal()

  const handleFilterConfirm: MUIDataTableOptions['onFilterConfirm'] =
    filterList => {
      /* eslint-disable prefer-destructuring */

      const country = filterList[4]
      const room = filterList[5]

      setFilters({
        'country.in': country ? country.map(String) : undefined,
        'room.in': room ? room.map(Number) : undefined,
      })
    }

  const handleOpenCreateTransferModal = (row: FundsRequestsDataSingleRo) => {
    const modal = showModal(CreateTransferToFundsRequestModal, {
      onSuccess: () => {
        modal.hide()
        showSnackBar('Перевод успешно создан', 'success')
      },
      onError: err => {
        showSnackBar('Ошибка при создании перевода', 'error', err)
      },
      recipient: {
        id: row.createdBy.id,
        room: {
          id: row.room.id,
        },
      },
      fundsRequest: {
        id: row.id,
      },
      amount: row.amountLeft,
    })
  }

  const fundsRequestDelete = useDeleteFundRequest({
    onSuccess: () =>
      showSnackBar('Запрос на перевод успешно удален', 'success'),
    onError: err =>
      showSnackBar('Ошибка при удалении запроса на перевод', 'error', err),
  })

  const handleOpenCreateModal = () => {
    const modal = showModal<FundsRequestModalProps>(FundsRequestModal, {
      onSuccess: () => {
        modal.hide()
        showSnackBar('Запрос на перевод успешно создан', 'success')
      },
      onError: err => {
        showSnackBar('Ошибка при создании запроса на перевод', 'error', err)
      },
    })
  }

  const handleDeleteFundsRequest = (row: FundsRequestsDataSingleRo) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить запрос на перевод?`,
      buttons: {
        confirm: {
          onClick: () => {
            fundsRequestDelete.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const handleOpenModerationModal = (row: FundsRequestsDataSingleRo) => {
    const modal = showModal<FundsRequestModerationModalProps>(FundsRequestModerationModal, {
      fundsRequestId: row.id,
      fundsRequestModerationStatus: row.moderationStatus,
      onSuccess: () => {
        modal.hide()
        showSnackBar('Успешно изменен статус', 'success')
      },
      onError: err => {
        showSnackBar('Ошибка при изменении статуса', 'error', err)
      },
    })
  }

  const [data, count] = fundsRequests
    ? [fundsRequests.data, fundsRequests.count]
    : [[], 0]

  const canDeleteAllFundsRequests = RoleHelper.isAdmin(currentUser?.role)

  const { TableMenuColumn } = useMenu<FundsRequestsDataSingleRo>({
    data,
    options: [
      function (row) {
        const hasPendingStatus =
          row.moderationStatus === 'pending'
          || row.moderationStatus === 'auto_pending';
        const isOwner = row.createdBy.id === currentUser?.id;

        const canDeleteOwnRequest = hasPendingStatus && isOwner;

        if (!canDeleteOwnRequest && !canDeleteAllFundsRequests) {
          return null;
        }

        return {
          id: 'delete',
          label: 'Удалить',
          onClick: handleDeleteFundsRequest,
        }
      },
    ],
  })

  return (
    <Box>
      <MUIDataTable
        data={data}
        title="Запросы на перевод"
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              customBodyRender: (value: string) => {
                return (
                  <TableColumn minWidth={30}>
                    <Typography variant="body2">{value}</Typography>
                  </TableColumn>
                )
              },
            },
          },
          {
            name: 'moderationStatus',
            label: 'Статус',
            options: {
              filter: false,
              customBodyRender: (value: FundsRequestStatusEnum, meta) => {
                const row = data[meta.rowIndex]

                const iconColor: Record<FundsRequestStatusEnum, IconButtonProps['color']> = {
                  [FundsRequestStatusEnum.PENDING]: 'warning',
                  [FundsRequestStatusEnum.AUTO_PENDING]: 'warning',
                  [FundsRequestStatusEnum.APPROVED]: 'secondary',
                  [FundsRequestStatusEnum.AUTO_APPROVED]: 'secondary',
                  [FundsRequestStatusEnum.DECLINED]: 'error',
                }

                const canModerateFundsRequests = RoleHelper.isAdmin(currentUser?.role);
                const translate = getFundsRequestStatusTranslate(value, canModerateFundsRequests);

                if (!translate) {
                  return;
                }

                return (
                  <TableColumn minWidth={40} maxWidth={40}>
                    <Tooltip title={translate}>
                      <IconButton
                        color={iconColor[value]}
                        onClick={canModerateFundsRequests
                          ? handleOpenModerationModal.bind(this, row)
                          : undefined
                      }>
                        <RequestPageIcon />
                      </IconButton>
                    </Tooltip>
                  </TableColumn>
                )
              }
            }
          },
          {
            name: 'createdAt',
            label: 'Создан',
            options: {
              filter: false,
              customBodyRender: TableCreatedAt,
            },
          },
          {
            name: 'createdBy.username',
            label: 'Игрок',
            options: {
              filter: false,
              customBodyRender: TableUser,
            },
          },
          {
            name: 'createdBy.personalData.country',
            label: 'Страна',
            options: {
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(
                  countryCodesMultipleSelectOptions,
                  {
                    enableAvatar: true,
                  },
                ),
                fullWidth: true,
              },
              customBodyRender: TableUserCountry,
            },
          },
          {
            name: 'room.name',
            label: 'Счёт',
            options: {
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(roomsSelectValues, {
                  enableAvatar: true,
                }),
                fullWidth: true,
              },
              customBodyRender: (_: string, tableMeta) => {
                const row = data[tableMeta.rowIndex];
                const room = row.room;
                const avatar = room?.avatar?.location

                return (
                  <TableColumn minWidth={70}>
                    <TableImageTitle title={room.name} src={avatar} />
                  </TableColumn>
                )
              },
            },
          },
          {
            name: 'amount',
            label: 'Запрос',
            options: {
              filter: false,
              customBodyRender: (value: string) => {
                return <TableColumn minWidth={70}>{value}</TableColumn>
              },
            },
          },
          {
            name: 'amountLeft',
            label: 'Осталось',
            options: {
              filter: false,
              customBodyRender: (value: string) => {
                return (
                  <TableColumn minWidth={70}>
                    <Typography variant="body2" fontWeight={600}>
                      {value}
                    </Typography>
                  </TableColumn>
                )
              },
            },
          },
          {
            name: 'room.currency',
            label: 'Валюта',
            options: {
              filter: false,
              customBodyRender: (value: string) => {
                return (
                  <TableColumn minWidth={70}>
                    <Typography variant="body2">{value}</Typography>
                  </TableColumn>
                )
              },
            },
          },
          {
            name: '__send_funds_transfer_action__',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              customBodyRender: (_, meta) => {
                const row = data[meta.rowIndex]
                const isCreator = row.createdBy.id === currentUser?.id;
                const isBadModerationStatus =
                  row.moderationStatus !== FundsRequestStatusEnum.APPROVED
                  && row.moderationStatus !== FundsRequestStatusEnum.AUTO_APPROVED;

                const isDisabledSubmit = isCreator || isBadModerationStatus;

                return (
                  <TableColumn minWidth={120}>
                    <Button
                      disabled={isDisabledSubmit}
                      variant="contained"
                      size="small"
                      onClick={() => handleOpenCreateTransferModal(row)}
                    >
                      Отправить
                    </Button>
                  </TableColumn>
                )
              },
            },
          },
          {
            name: '__actions__',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              customBodyRenderLite: TableMenuColumn,
            },
          },
        ]}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          ...tableSort.options,
          count,
          tableBodyHeight: '497px',
          filter: true,
          onFilterConfirm: handleFilterConfirm,
          textLabels: createTextLabels({
            isLoading: isGetFundsRequestsFetching || isRoomsFetching,
            textLabels: {
              body: {
                noMatch: 'Запросов на перевод сейчас нет',
              },
            },
          }),
          customToolbar: () => {
            return (
              <Box sx={{ display: 'inline-flex' }} ml={2}>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  size="small"
                  onClick={handleOpenCreateModal}
                >
                  Создать запрос
                </Button>
              </Box>
            )
          },
        }}
        components={tableDefault.components}
      />
    </Box>
  )
}
