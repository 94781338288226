import { Form } from '@/components/Forms/Form'
import { Select } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import {
  userPersonalDataModalSchema,
  UserPersonalDataModalSchema,
} from '@/components/Modals/UserPersonalDataModal/schema/userPersonalDataModal.schema'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { countryCodesSelectOptions } from '@/constants/countryCodesSelectOptions'
import { UsersRo } from '@/services/data-contracts'
import { useEditUser } from '@/services/hooks/users/useEditUser'
import { useGetUserById } from '@/services/hooks/users/useGetUserById'
import { useGetUsers } from '@/services/hooks/users/useGetUsers'
import { usersToSelectOptions } from '@/utils/mappers/usersToSelectOptions'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material'

import { AxiosErrorResponse } from '@/types'

type UserPersonalDataModalProps = {
  userId: number
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
} & Omit<DialogProps, 'onError' | 'onSuccess'>

export const UserPersonalDataModal = ({
  userId,
  onSuccess,
  onError,
  ...props
}: UserPersonalDataModalProps) => {
  const { data: user, isFetching: isUserFetching } = useGetUserById(userId)
  const { data: users, isFetching: isFetchingUsers } = useGetUsers({
    limit: 500,
    sort: 'username:ASC',
  })
  const editUser = useEditUser({ onSuccess, onError })

  const usersSelectValues = usersToSelectOptions(users ? users.data : [], {
    excludeCurrentUser: {
      enable: true,
      currentUserId: userId,
    },
  })

  const initialValues = (user as UsersRo)?.personalData || null

  const handleSubmit = ({
    address,
    additionalInfo,
    country,
    dateOfBirth,
    discordId,
    fullName,
    gmail,
    joinDate,
    ref,
    phoneNumber,
    telegramId,
    discordChannelId,
    screenshotsUrl,
    sharkAlias,
    kickDate,
  }: UserPersonalDataModalSchema) => {
    editUser.mutate({
      id: userId,
      personalData: {
        address: address || null,
        additionalInfo: additionalInfo || null,
        country: country || null,
        dateOfBirth: dateOfBirth || null,
        discordId: discordId || null,
        fullName: fullName || null,
        gmail: gmail || null,
        joinDate: joinDate || null,
        discordChannelId: discordChannelId || null,
        ref: ref?.id
          ? {
              id: ref.id,
            }
          : null,
        phoneNumber: phoneNumber || null,
        telegramId: telegramId || null,
        screenshotsUrl: screenshotsUrl || null,
        sharkAlias: sharkAlias || null,
        kickDate: kickDate || null,
      },
    })
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...props}
    >
      <DialogTitle variant="h4" sx={{ textAlign: 'center' }}>
        Редактирование личных данных пользователя. ID: {userId}
      </DialogTitle>
      <Divider />
      <WithLoader isLoading={isUserFetching || isFetchingUsers}>
        <Form<UserPersonalDataModalSchema>
          validationSchema={userPersonalDataModalSchema}
          initialValues={initialValues as UserPersonalDataModalSchema}
          onSubmit={handleSubmit}
          reValidateMode="onChange"
        >
          <DialogContent>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={6}>
                <Typography variant="h6">Личная информация</Typography>
                <Divider sx={{ my: 1 }} />
                <TextField
                  name="fullName"
                  placeholder="Иванов Иван Иванович"
                  label="ФИО"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
                <TextField
                  name="dateOfBirth"
                  label="Дата рождения"
                  type="date"
                  textFieldProps={{ size: 'small' }}
                />
                <TextField
                  name="phoneNumber"
                  placeholder="88005553535"
                  label="Номер телефона"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
                <Select
                  name="country"
                  label="Страна"
                  enableAvatar
                  options={countryCodesSelectOptions}
                  formItemProps={{ margin: 'normal' }}
                  selectProps={{ size: 'small' }}
                />
                <TextField
                  name="address"
                  placeholder="ул. Подшибякина 19"
                  label="Адрес"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Аккаунты</Typography>
                <Divider sx={{ my: 1 }} />
                <TextField
                  name="sharkAlias"
                  placeholder="Sharcscope. Объединенный профиль со всеми никами"
                  label="Алиас в шарке"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
                <TextField
                  name="gmail"
                  placeholder="Укажите никнейм"
                  label="Gmail аккаунт"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
                <TextField
                  name="discordId"
                  placeholder="Укажите никнейм"
                  label="Аккаунт в Discord"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
                <TextField
                  name="discordChannelId"
                  placeholder="Укажите канал"
                  label="Личный канал в в Discord"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
                <TextField
                  name="telegramId"
                  placeholder="Укажите никнейм"
                  label="Аккаунт в Telegram"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Проектная информация</Typography>
                <Divider sx={{ my: 1 }} />
                <TextField
                  name="joinDate"
                  placeholder="Введите дату"
                  label="Дата присоединения к фонду"
                  type="date"
                  textFieldProps={{ size: 'small' }}
                />
                <TextField
                  name="kickDate"
                  placeholder="Введите дату"
                  label="Дата кика из фонда"
                  type="date"
                  textFieldProps={{ size: 'small' }}
                />
                <Select
                  name="ref.id"
                  label="Реферал"
                  options={[
                    {
                      id: '',
                      label: 'Не выбран',
                    },
                    ...usersSelectValues,
                  ]}
                  enableAvatar={false}
                  formItemProps={{ margin: 'normal' }}
                  selectProps={{ size: 'small' }}
                />
                <TextField
                  name="screenshotsUrl"
                  placeholder="https://someurl.com"
                  label="Ссылка на папку со скриншотами"
                  type="text"
                  textFieldProps={{ size: 'small' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Прочее</Typography>
                <Divider sx={{ my: 1 }} />
                <TextField
                  name="additionalInfo"
                  placeholder="Введите текст"
                  label="Дополнительная информация"
                  textFieldProps={{ multiline: true, rows: 8 }}
                />
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={editUser.isLoading}
              >
                Сохранить
              </Button>
            </Box>
          </DialogContent>
        </Form>
      </WithLoader>
    </Dialog>
  )
}
