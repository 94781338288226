import { useMemo } from 'react'

import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { Form } from '@/components/Forms/Form'
import { TextField } from '@/components/Forms/TextField'
import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useSnackBar } from '@/context/SnackbarContext'
import { useSetPlayerRoomsSettings } from '@/services/hooks/cabinet/admin/useSetPlayerRoomsSettings'
import { useGetPlayerRoomsSettings } from '@/services/hooks/cabinet/player/useGetPlayerRoomsSettings'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import {
  playerRoomsSettingsSchema,
  PlayerRoomsSettingsValues,
} from './schema/playerRoomsSettings.schema'

type RoomSetting = {
  room: {
    id: number
    name: string
    avatar?: string
  }
  transferInfo?: string
  nickname?: string
}

type PlayerRoomsSettingsProps = {
  userId: number
}

export const PlayerRoomsSettings = ({ userId }: PlayerRoomsSettingsProps) => {
  const { showSnackBar } = useSnackBar()
  const { defaultSortString } = useGetSort()
  const { data: rooms, isFetching: isRoomsFetching } = useGetRooms({
    limit: 500,
    offset: 0,
    sort: defaultSortString,
  })
  const { data: playerRoomsSettings, isInitialLoading: isInitialLoading } =
    useGetPlayerRoomsSettings({ userId })
  const setPlayerRoomsSettings = useSetPlayerRoomsSettings({
    onSuccess: () =>
      showSnackBar('Настройки румов успешно сохранены', 'success'),
    onError: () =>
      showSnackBar('Не удалось сохранить настройки румов', 'error'),
  })

  const roomsSettings: RoomSetting[] = useMemo(() => {
    if (!rooms || !playerRoomsSettings) {
      return []
    }

    if (!rooms.data.length) {
      return []
    }

    return rooms.data.map(room => {
      const settings = playerRoomsSettings.data[room.id]

      return {
        room: {
          id: room.id,
          name: room.name,
          avatar: room?.avatar?.location,
        },
        nickname: settings?.nickname || '',
        transferInfo: settings?.transferInfo || '',
      }
    })
  }, [playerRoomsSettings, rooms])

  const handleSubmit = (data: PlayerRoomsSettingsValues) => {
    if (data.settings.length) {
      setPlayerRoomsSettings.mutate({
        userId,
        settings: data.settings,
      })
    }
  }

  const isSubmitting = setPlayerRoomsSettings.isLoading

  return (
    <Box>
      <WithLoader isLoading={isInitialLoading || isRoomsFetching}>
        <Form<PlayerRoomsSettingsValues>
          onSubmit={handleSubmit}
          initialValues={{ settings: roomsSettings }}
          validationSchema={playerRoomsSettingsSchema}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }} mb={3}>
            <Typography>
              Используются для автозаполнения информации в переводах
            </Typography>
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Сохранить
            </Button>
            {isSubmitting && <CircularProgress size={20} />}
          </Box>
          <Grid container spacing={2}>
            {(roomsSettings || []).map(({ room }, idx) => (
              <Grid item xs={12} md={4} lg={3} key={room.id}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 4,
                    alignItems: 'center',
                    border: `1px solid ${grey[500]}`,
                    borderRadius: 2,
                  }}
                  mb={2}
                >
                  <Box maxWidth="100%" p={2}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <ColorAvatar title={room.name} src={room.avatar} />
                      <Typography
                        title={room.name}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {room.name}
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        label="Никнейм"
                        name={`settings.${idx}.nickname`}
                        textFieldProps={{
                          disabled: isSubmitting,
                          size: 'small',
                          type: 'text',
                        }}
                      />
                      <TextField
                        label="Данные для перевода"
                        name={`settings.${idx}.transferInfo`}
                        textFieldProps={{
                          disabled: isSubmitting,
                          size: 'small',
                          type: 'text',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Form>
      </WithLoader>
    </Box>
  )
}
