import { useMemo } from 'react'

import { Form } from '@/components/Forms/Form'
import { Select } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import {
  userGroupModalSchema,
  UserGroupModalSchema,
} from '@/components/Modals/UserGroupModal/schema/userGroupModal.schema'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { UserRole } from '@/constants'
import { useCreateUserGroup } from '@/services/hooks/user-groups/useCreateUserGroup'
import { useEditUserGroup } from '@/services/hooks/user-groups/useEditUserGroup'
import { useGetUserGroupById } from '@/services/hooks/user-groups/useGetUserGroupById'
import { useGetUsers } from '@/services/hooks/users/useGetUsers'
import { usersToSelectOptions } from '@/utils/mappers/usersToSelectOptions'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material'

interface UserGroupCreateModalProps {
  mode: 'create'
  userGroupId: undefined
  onSuccess?: () => void
  onError?: () => void
}

interface UserGroupEditModalProps {
  mode: 'edit'
  userGroupId: number
  onSuccess?: () => void
  onError?: () => void
}

export type UserGroupModalProps = (
  | UserGroupCreateModalProps
  | UserGroupEditModalProps
) &
  DialogProps

export const UserGroupModal = ({
  onSuccess,
  onError,
  mode,
  userGroupId,
  ...props
}: UserGroupModalProps) => {
  const titleMap: Record<'create' | 'edit', string> = {
    create: 'Создание группы пользователей',
    edit: `Редактирование группы пользователей. ID: ${userGroupId}`,
  }

  const { data: userGroupResponse, isFetching: isUserGroupFetching } =
    useGetUserGroupById(userGroupId || 0, {
      enabled: Boolean(mode === 'edit' && userGroupId),
    })

  const createUserGroup = useCreateUserGroup({
    onSuccess,
    onError,
  })

  const editUserGroup = useEditUserGroup({
    onSuccess,
    onError,
  })

  const { data: coachesResponse, isLoading: isLoadingCoaches } = useGetUsers({
    limit: 500,
    offset: 0,
    'role.equal': UserRole.coach,
  })

  const { data: playersResponse, isLoading: isLoadingPlayers } = useGetUsers({
    limit: 500,
    offset: 0,
    'role.equal': UserRole.player,
  })

  const coachSelectOptions = usersToSelectOptions(
    coachesResponse && coachesResponse.data,
  )
  const playersSelectOptions = usersToSelectOptions(
    playersResponse && playersResponse.data,
  )

  const isLoading = isLoadingCoaches || isLoadingPlayers || isUserGroupFetching

  const initialValues: Partial<UserGroupModalSchema> = useMemo(() => {
    if (!userGroupResponse) {
      return {}
    }

    return {
      id: userGroupResponse.id,
      name: userGroupResponse.name,
      coach: userGroupResponse.coach,
      students: (userGroupResponse.students || []).map(student => student.id),
    }
  }, [userGroupResponse])

  const handleSubmit = (data: UserGroupModalSchema) => {
    const students = (data.students || []).map(studentId => ({
      id: studentId,
    }))

    if (mode === 'create') {
      createUserGroup.mutate({
        name: data.name,
        coach: data.coach,
        students,
      })

      return
    }

    if (mode === 'edit') {
      editUserGroup.mutate({
        id: userGroupId,
        name: data.name,
        coach: data.coach,
        students,
      })

      return
    }
  }

  return (
    <Dialog maxWidth="sm" {...props}>
      <DialogTitle>{titleMap[mode]}</DialogTitle>
      <DialogContent>
        <Box minWidth={500}>
          <WithLoader isLoading={isLoading}>
            <Form<UserGroupModalSchema>
              onSubmit={handleSubmit}
              validationSchema={userGroupModalSchema}
              initialValues={initialValues}
            >
              <Box mb={1}>
                <TextField label="Название" name="name" />
              </Box>
              <Box mb={2}>
                <Select
                  label="Тренер"
                  name="coach.id"
                  options={coachSelectOptions}
                  enableAvatar={true}
                />
              </Box>
              <Box mb={2}>
                <Select
                  label="Ученики"
                  name="students"
                  options={playersSelectOptions}
                  multiple={true}
                  enableAvatar={true}
                />
              </Box>
              <Box mt={2}>
                <Button variant="contained" type="submit" fullWidth>
                  Сохранить
                </Button>
              </Box>
            </Form>
          </WithLoader>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
