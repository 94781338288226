import { transform } from '@/config/yup/transform'
import { UserRole, UserRoleValue } from '@/constants'
import * as yup from 'yup'

export type UserModalSchema = {
  username: string
  password: string
  email: string
  role: UserRoleValue
  rank: number
  isKicked: 'active' | 'kicked'
  contract?: {
    id: number
  }
}

export const userModalSchema = {
  generate: (mode: 'create' | 'edit') => {
    return yup.object().shape({
      username: yup
        .string()
        .required('Никнейм обязателен')
        .max(255, 'Максимальная длина никнейма 255 символов'),
      password: yup
        .string()
        .max(255, 'Максимальная длина пароля 255 символов')
        .when([], {
          is: () => mode === 'create',
          then: () => yup.string().required('Пароль обязателен'),
          otherwise: () => yup.string().optional(),
        }),
      email: yup
        .string()
        .email('Введите валидный e-mail')
        .required('Email обязателен'),
      role: yup
        .number()
        .transform(transform.emptyStringToNull)
        .required('Не может быть пустым')
        .oneOf(
          [UserRole.player, UserRole.coach, UserRole.selector, UserRole.admin],
          'Выберите одну из существующих ролей',
        ),
      rank: yup
        .mixed()
        .transform(value => (value === '' ? 1 : +value))
        .optional()
        .nullable(),
      contract: yup.mixed().when('role', {
        is: (value: UserRoleValue) => value === UserRole.player,
        then: () =>
          yup
            .object({
              id: yup.string().required('Укажите пакет'),
            })
            .required('Укажите пакет'),
        otherwise: () => yup.mixed().optional().nullable(),
      }),
    })
  },
}
