import React from 'react'

import { Box, CircularProgress } from '@mui/material'

interface WithLoaderProps {
  size?: number
  fullHeight?: boolean
  isLoading: boolean
  children?: React.ReactNode
}

export const WithLoader = ({
  children,
  isLoading,
  fullHeight,
  size,
}: WithLoaderProps) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: fullHeight ? '100%' : 'auto',
        }}
        py={2}
      >
        <CircularProgress size={size} />
      </Box>
    )
  }

  return <>{children}</>
}
