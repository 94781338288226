import { useMemo, useState } from 'react'

import { Form } from '@/components/Forms/Form'
import { VisibleIf } from '@/components/Forms/helpers/VisibleIf'
import { Select, SelectOption } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { UserRole, UserRoleMap } from '@/constants'
import { useGetUserContracts } from '@/services/hooks/user-contracts/useGetUserContracts'
import { useCreateUser } from '@/services/hooks/users/useCreateUser'
import { useEditUser } from '@/services/hooks/users/useEditUser'
import { useGetUserById } from '@/services/hooks/users/useGetUserById'
import { userContractsToSelectOptions } from '@/utils/mappers/userContractsToSelectOptions'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
} from '@mui/material'

import { AxiosErrorResponse } from '@/types'

import { UserModalSchema, userModalSchema } from './schema/userModal.schema'

interface UserCreateModalProps {
  mode: 'create'
  userId: undefined
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

interface UserEditModalProps {
  mode: 'edit'
  userId: number
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

type UserModalProps = (UserCreateModalProps | UserEditModalProps) &
  Omit<DialogProps, 'onError' | 'onSuccess'>

export const UserModal = ({
  mode,
  userId,
  onSuccess,
  onError,
  ...props
}: UserModalProps) => {
  const [isPasswordEdit, setIsPasswordEdit] = useState(false)

  const titleMap: Record<'create' | 'edit', string> = {
    create: 'Создание пользователя',
    edit: `Редактирование пользователя. ID: ${userId}`,
  }

  const { data: user, isFetching: isUserFetching } = useGetUserById(
    userId || 0,
    {
      enabled: Boolean(mode === 'edit' && userId),
    },
  )

  const createUser = useCreateUser({ onSuccess, onError })
  const editUser = useEditUser({ onSuccess, onError })

  const { data: userContractsData, isFetching: isFetchingUserContracts } =
    useGetUserContracts({
      limit: 500,
    })

  const isButtonDisabled =
    createUser.isLoading ||
    editUser.isLoading ||
    isUserFetching ||
    isFetchingUserContracts

  const handleSubmit = (data: UserModalSchema) => {
    const payload = {
      ...data,
      isKicked: data.isKicked === 'kicked',
      contract: data.contract?.id
        ? { id: Number(data.contract.id) }
        : undefined,
    }

    if (mode === 'create') {
      createUser.mutate(payload)
      return
    }

    if (mode === 'edit') {
      editUser.mutate({ id: userId, ...payload })
      return
    }

    return null
  }

  const roleOptions = Object.entries(UserRoleMap).map(([key, label]) => ({
    id: +key,
    label,
  }))

  const kickedOptions: SelectOption[] = [
    { id: 'active', label: 'Активен' },
    { id: 'kicked', label: 'Кикнут' },
  ]

  const userContractsOptions: SelectOption[] = userContractsToSelectOptions(
    userContractsData ? userContractsData.data : [],
  )

  const passwordEditDisabled = mode === 'edit' && !isPasswordEdit
  const validationSchema = useMemo(() => userModalSchema.generate(mode), [mode])

  const initialValues: Partial<UserModalSchema> = useMemo(() => {
    if (mode !== 'edit' || !user) {
      return {}
    }

    return {
      ...user,
      isKicked: user.isKicked ? 'kicked' : 'active',
    }
  }, [mode, user])

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...props}
    >
      <DialogTitle variant="h4">{titleMap[mode]}</DialogTitle>
      <Divider />
      <WithLoader isLoading={isUserFetching}>
        <Form<UserModalSchema>
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          reValidateMode="onChange"
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField name="username" label="Никнейм" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  label="Пароль"
                  disabled={passwordEditDisabled}
                  textFieldProps={{
                    onClick: () => setIsPasswordEdit(true),
                    inputProps: {
                      type: 'password',
                    },
                    InputProps: {
                      endAdornment: passwordEditDisabled && (
                        <InputAdornment position="end">
                          <Button onClick={() => setIsPasswordEdit(true)}>
                            Редактировать
                          </Button>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="E-mail"
                  textFieldProps={{
                    inputProps: {
                      type: 'email',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select name="role" label="Роль" options={roleOptions} />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="isKicked"
                  label="Доступ к проекту"
                  options={kickedOptions}
                />
              </Grid>
              <VisibleIf
                names={['role']}
                condition={([role]) => role === UserRole.player}
              >
                <>
                  <Grid item xs={12}>
                    <TextField
                      name="rank"
                      label="Ранг (турнирный)"
                      textFieldProps={{
                        inputProps: {
                          type: 'number',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      name="contract.id"
                      label="Пакет"
                      options={userContractsOptions}
                    />
                  </Grid>
                </>
              </VisibleIf>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={isButtonDisabled}
              >
                Сохранить
              </Button>
            </Box>
          </DialogContent>
        </Form>
      </WithLoader>
    </Dialog>
  )
}
