import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { UserGroups } from '@/services/api/user-groups'
import { UserGroupEditDto, UserGroupEditRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

type UseEditUserGroupPayload = {
  id: number
} & UserGroupEditDto;

export const useEditUserGroup = (props?: UseMutationOptions<UserGroupEditRo, AxiosErrorResponse, UseEditUserGroupPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<UserGroupEditRo, AxiosErrorResponse, UseEditUserGroupPayload>(
    ['user-groups'],
    async (payload: UseEditUserGroupPayload) => {
      const { id, ...params } = payload
      const response = await UserGroups.edit(id, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-groups', 'get'])
        props?.onSuccess?.(...args)
      },
    })
}
