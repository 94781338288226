import { AxiosResponse } from 'axios'
import { useQuery } from '@tanstack/react-query'

import { Users } from '@/services/api/users'
import { UsersRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useCurrentUser = () => {
  const userQuery = useQuery({
    queryFn: Users.getByToken,
    queryKey: ['current-user'],
    select: (response: AxiosResponse<UsersRo>) => response.data,
    staleTime: Infinity,
    retry: (_, error: AxiosErrorResponse) => {
      return error.code !== 'ERR_BAD_REQUEST'
    },
  })

  return {
    currentUser: userQuery.data,
    isLoading: userQuery.isLoading,
    isError: userQuery.isError,
    isFetching: userQuery.isFetching,
  }
}
