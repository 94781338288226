import { Request } from '../Request'
import { AxiosResponse } from 'axios'
import { RoomCreateDto, RoomCreateRo, RoomEditDto, RoomRo, RoomsControllerFindAllParams, RoomsDataRo } from '@/services/data-contracts'

export const Rooms = {
  create: async (params: RoomCreateDto) => {
    return Request.post<RoomCreateDto, AxiosResponse<RoomCreateRo>>('/rooms', params)
  },
  get: async (params?: RoomsControllerFindAllParams) => {
    return Request.get<RoomsControllerFindAllParams, AxiosResponse<RoomsDataRo>>('/rooms', { params })
  },
  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<RoomRo>>(`/rooms/${id}`)
  },
  patch: async (id: number, params: RoomEditDto) => {
    return Request.patch<RoomEditDto, AxiosResponse<RoomRo>>(`/rooms/${id}`, params)
  },
  delete: async (id: number) => {
    return Request.delete(`/rooms/${id}`)
  },
}
