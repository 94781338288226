import { SelectOption } from '@/components/Forms/Select'
import { UserContractDataRo } from '@/services/data-contracts'

export const userContractsToSelectOptions = (
  userContracts?: UserContractDataRo['data'],
): SelectOption[] => {
  if (!userContracts?.length) {
    return []
  }

  return (userContracts || []).map(contract => ({
    id: contract.id,
    label: contract.name,
  }))
}
