import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Reviews } from '@/services/api/reviews'
import { ReviewCreateDto, ReviewCreateRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useCreateReview = (
  props?: UseMutationOptions<ReviewCreateRo, AxiosErrorResponse, ReviewCreateDto>,
) => {
  const queryClient = useQueryClient()

  return useMutation<ReviewCreateRo, AxiosErrorResponse, ReviewCreateDto>(
    ['reviews'],
    async (payload) => {
      const response = await Reviews.create(payload)

      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['reviews'])
        return props?.onSuccess?.(...args)
      }
    },
  )
}
