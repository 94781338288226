import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { Select, SelectOption } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { ROOMS_LIMITATIONS } from '@/constants.ts'
import { useGetPlayerRoomsSettingsList } from '@/services/hooks/cabinet/player/useGetPlayerRoomsSettingsList.ts'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { CurrencyHelper } from '@/utils/CurrencyHelper'
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField as TextFieldMui,
  Typography,
} from '@mui/material'

interface FundsRequestFormValuesProps {
  isSubmitting?: boolean
}

export const FundsRequestFormValues = ({
  isSubmitting = false,
}: FundsRequestFormValuesProps) => {
  const { defaultSortString } = useGetSort()
  const { watch } = useFormContext()
  const { currentUser } = useCurrentUser()
  const {
    data: playerRoomsSettings,
    isFetching: isPlayerRoomsSettingsFetching,
  } = useGetPlayerRoomsSettingsList(
    {
      userId: currentUser ? currentUser.id : 0,
      params: {
        'category.some': ROOMS_LIMITATIONS.FUNDS_REQUESTS,
        sort: defaultSortString,
      },
    },
    { enabled: !!currentUser },
  )
  const playerRoomsSettingsFiltered = playerRoomsSettings
    ? playerRoomsSettings.data.filter(
        setting => setting?.transferInfo || setting?.nickname,
      )
    : null

  const roomSelectOptions = useMemo<SelectOption[]>(() => {
    if (!playerRoomsSettingsFiltered || !playerRoomsSettingsFiltered.length) {
      return []
    }

    return playerRoomsSettingsFiltered.map(setting => ({
      id: setting.room.id,
      label: setting.room.name,
      avatar: setting.room.avatar?.location,
    }))
  }, [playerRoomsSettingsFiltered])

  const roomId = watch('room.id')
  const amount = watch('amount')

  const room = playerRoomsSettings?.data.find(
    roomSettings => roomSettings.room.id === roomId,
  )

  const roomSetting = playerRoomsSettingsFiltered
    ? playerRoomsSettingsFiltered.find(setting => setting.room.id === roomId)
    : null

  const isValidRoomSettings = roomSetting?.transferInfo || roomSetting?.nickname
  const isButtonDisabled = !roomId || !isValidRoomSettings || amount <= 0

  return (
    <WithLoader isLoading={isPlayerRoomsSettingsFetching || isSubmitting}>
      <Box width="100%">
        <Select
          name="room.id"
          label="Рум"
          options={roomSelectOptions}
          enableAvatar={true}
          selectProps={{ size: 'medium' }}
        />
        <TextField
          name="amount"
          label="Запрашиваемая сумма"
          type="number"
          disabled={!roomId}
          textFieldProps={{
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  {CurrencyHelper.getCurrencySign(room?.room.currency)}
                </InputAdornment>
              ),
            },
          }}
        />
        <Box my={2}>
          <Divider />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextFieldMui
            disabled={true}
            value={roomSetting?.transferInfo || 'Не указан'}
            label="Ваши данные для перевода"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextFieldMui
            disabled={true}
            value={roomSetting?.nickname || 'Не указан'}
            label="Ваш ник на руме"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          {roomId && !isValidRoomSettings && (
            <Box>
              <Typography color="primary">
                Не указаны данные рума. Обратитесь к администратору для их
                изменения.
              </Typography>
            </Box>
          )}
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={isButtonDisabled}
          >
            Создать запрос
          </Button>
        </Box>
      </Box>
    </WithLoader>
  )
}
