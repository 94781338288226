import * as yup from 'yup'

export type ChooseTournamentSchema = {
  studentComment?: string
  screenshot?: {
    id: number
  }
  room?: {
    id: number;
  }
}

export const chooseTournamentSchema = {
  generate: (hasNetwork?: boolean) => {
    return yup.object({
      studentComment: yup
        .string()
        .optional()
        .max(500, 'Комментарий не может быть больше 500 символов'),
      screenshot: yup
        .object({
          id: yup.mixed(),
        })
        .optional(),
      ...(hasNetwork && ({
        room: yup.object({
            id: yup.number().typeError('Необходимо выбрать рум').required('Необходимо выбрать рум'),
          })
          .typeError('Необходимо выбрать рум')
          .required('Необходимо выбрать рум')
      }))
    })
  }
}
