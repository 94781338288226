import { Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, Divider, Typography } from '@mui/material'

import { AxiosErrorResponse } from '@/types.ts'
import { Form } from '@/components/Forms/Form.tsx'
import { Select } from '@/components/Forms/Select.tsx'
import { FundsRequestStatusEnum, getFundsRequestStatusTranslate } from '@/constants.ts'
import { useEditFundsRequest } from '@/services/hooks/funds-requests/useEditFundsRequest.ts'

export type FundsRequestModerationModalProps = {
  fundsRequestId: number;
  fundsRequestModerationStatus: `${FundsRequestStatusEnum}`;
  onSuccess: () => void
  onError: (error?: AxiosErrorResponse) => void
} & Omit<DialogProps, 'onError' | 'onSuccess'>

const AVAILABLE_STATUSES = [
  FundsRequestStatusEnum.PENDING,
  FundsRequestStatusEnum.APPROVED,
  FundsRequestStatusEnum.DECLINED
] as const;

export const FundsRequestModerationModal = ({
  onSuccess,
  onError,
  fundsRequestModerationStatus,
  fundsRequestId,
  ...dialogProps
}: FundsRequestModerationModalProps) => {
  const editFundsRequest  = useEditFundsRequest({
    onSuccess,
    onError,
  })

  const handleSubmit = (data: { moderationStatus: (typeof AVAILABLE_STATUSES)[number] }) => {
    editFundsRequest.mutate({
      id: fundsRequestId,
      moderationStatus: data.moderationStatus,
    })
  }

  const moderationStatusOptions = AVAILABLE_STATUSES.map((status) => ({
    id: status,
    label: getFundsRequestStatusTranslate(status, true),
    disabled: fundsRequestModerationStatus === status,
  }));

  const initialModerationStatus = moderationStatusOptions.find((option) => {
    return !option.disabled
  })?.id

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...dialogProps}
    >
      <DialogTitle variant="h4">Модерация запроса на перевод</DialogTitle>
      <Divider />
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          moderationStatus: initialModerationStatus,
        }}
      >
        <DialogContent>
          <Box>
            <Typography mb={1}>
              ID запроса на перевод: <b>{fundsRequestId}</b>
            </Typography>
            <Typography mb={1}>
              Текущий статус: <b>{getFundsRequestStatusTranslate(fundsRequestModerationStatus as FundsRequestStatusEnum, true)}</b>
            </Typography>
          </Box>
          <Box my={3}>
            <Select
              name="moderationStatus"
              label="Выберите новый статус"
              options={moderationStatusOptions}
            />
          </Box>
          <Box>
            <Button
              disabled={editFundsRequest.isLoading}
              fullWidth
              variant="contained"
              type="submit"
            >
              Сохранить
            </Button>
          </Box>
        </DialogContent>
      </Form>
    </Dialog>
  )
}
