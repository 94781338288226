import { useCallback, useEffect, DragEvent } from 'react'

import { UploadFileDto, UploadFileRo } from '@/services/data-contracts.ts'
import { AxiosErrorResponse } from '@/types.ts'
import { UseMutationResult } from '@tanstack/react-query'

export const usePasteImage = (
  uploadMutation: UseMutationResult<
    UploadFileRo,
    AxiosErrorResponse,
    UploadFileDto
  >,
) => {
  const handleMutate = useCallback(
    (file: File | null | undefined) => {
      if (file) {
        uploadMutation.mutate({ file })
      }
    },
    [uploadMutation],
  )

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      const clipboardItems = event.clipboardData?.items
      const file = clipboardItems?.[0].getAsFile()
      handleMutate(file)
    },
    [handleMutate],
  )

  const handleDrop = (event: DragEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const file = event.dataTransfer?.files[0]
    handleMutate(file)
  }

  const handleDropOver = (event: DragEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  useEffect(() => {
    window.addEventListener('paste', handlePaste)

    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])

  return { handleDrop, handleDropOver }
}
