import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { RoomsControllerFindAllParams, RoomsDataRo } from '@/services/data-contracts'
import { Rooms } from '@/services/api/rooms'
import { AxiosErrorResponse } from '@/types'

export const useGetRooms = (
  params?: RoomsControllerFindAllParams,
  props?: UseQueryOptions<RoomsDataRo, AxiosErrorResponse>,
) => {
  return useQuery<RoomsDataRo, AxiosErrorResponse>(
    ['rooms', params],
    async () => {
      const response = await Rooms.get(params)
      return response.data
    },
    props,
  )
}
