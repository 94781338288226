import { createAxiosInstance, Request } from '@/services/Request'
import { LoginRo, RefreshTokensDto } from '@/services/data-contracts'
import { AxiosResponse } from 'axios'

interface JWTTokens {
  accessToken: string;
  refreshToken: string
}

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'accessToken'
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refreshToken'
const EXPIRES_IN_LOCAL_STORAGE_KEY = 'expiresIn'

const ACCESS_TOKEN_EXPIRE_TIME = 1000 * 60 * 60 * 24 * 30 // 30 days

export const TokensStorage = {
  set: (tokens: JWTTokens) => {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, tokens.accessToken)
    localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, tokens.refreshToken)
    localStorage.setItem(EXPIRES_IN_LOCAL_STORAGE_KEY, String(Date.now() + ACCESS_TOKEN_EXPIRE_TIME))
  },
  get: (): Partial<JWTTokens> => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
    const expiresIn = localStorage.getItem(EXPIRES_IN_LOCAL_STORAGE_KEY)

    return {
      ...(accessToken && { accessToken }),
      ...(refreshToken && { refreshToken }),
      ...(expiresIn && { expiresIn }),
    }
  },
  clear: (): void => {
    localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)
    localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
    localStorage.removeItem(EXPIRES_IN_LOCAL_STORAGE_KEY)
  },
  isTokenAlive: () => {
    const expiresIn = localStorage.getItem(EXPIRES_IN_LOCAL_STORAGE_KEY)

    if (!expiresIn) {
      return false
    }

    return Date.now() <= Number(expiresIn)
  },
}

Request.interceptors.request.use(async (config) => {
  const { accessToken: storageAccessToken, refreshToken: storageRefreshToken } = TokensStorage.get()
  const isTokenAlive = TokensStorage.isTokenAlive()

  if (!isTokenAlive && storageAccessToken && storageRefreshToken) {
    TokensStorage.clear()
    try {
      const req = createAxiosInstance()
      const response = await req.post<RefreshTokensDto, AxiosResponse<LoginRo>>('/auth/refresh-tokens', { refresh_token: storageRefreshToken })
      TokensStorage.set(response.data)
    } catch (err) {
      TokensStorage.clear()
    }
  }

  const { accessToken } = TokensStorage.get()

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return config
})
