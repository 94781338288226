import { useParams } from 'react-router-dom'
import { Alert, Box, Grid } from '@mui/material'

import { UserReportsTable } from '@/components/Containers/UserReports/UserReportsTable'
import { PlayerCard } from '@/components/UI/PlayerCard/PlayerCard'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { useGetUserById } from '@/services/hooks/users/useGetUserById'
import { ReviewsTable } from '@/components/Containers/CoachUserReviewsTable/ReviewsTable'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { RoleHelper } from '@/utils/RoleHelper'

export const ReviewsPage = () => {
  const params = useParams()
  const userId = params?.id ? +params.id : 0;

  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser()
  const { data: user, isLoading: isUserLoading } = useGetUserById(userId, { enabled: !!userId })

  return (
    <DashboardLayout>
      <WithLoader isLoading={isUserLoading || isCurrentUserLoading}>
        {user && currentUser && (
          <Box>
            <Box mb={2}>
              <PlayerCard user={user} />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ReviewsTable
                  type="monthly"
                  playerId={userId}
                  canEdit={RoleHelper.isAdmin(currentUser.role)}
                  canDelete={RoleHelper.isAdmin(currentUser.role)}
                  canAdd={RoleHelper.isAdmin(currentUser.role) || RoleHelper.isCoach(currentUser.role)}
                  tableBodyHeight='500px'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <UserReportsTable
                  userId={userId}
                  canEdit={RoleHelper.isAdmin(currentUser.role)}
                  canDelete={RoleHelper.isAdmin(currentUser.role)}
                  canAdd={false}
                  tableBodyHeight='500px'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ReviewsTable
                  type="intermediate"
                  playerId={userId}
                  canEdit={RoleHelper.isAdmin(currentUser.role)}
                  canDelete={RoleHelper.isAdmin(currentUser.role)}
                  canAdd={RoleHelper.isAdmin(currentUser.role) || RoleHelper.isCoach(currentUser.role)}
                  tableBodyHeight='500px'
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {!user && (
          <Alert severity="error">Пользователь не найден</Alert>
        )}
      </WithLoader>
    </DashboardLayout>
  )
}
