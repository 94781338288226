import { FundsTransfers } from '@/services/api/funds-transfers'
import { PlayersBalancesTotalRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useGetAdminPlayerBalancesTotal = (
  props?: UseQueryOptions<PlayersBalancesTotalRo[], AxiosErrorResponse>,
) => {
  return useQuery<PlayersBalancesTotalRo[], AxiosErrorResponse>(
    ['funds-transfers', 'admin', 'personal', 'total-all'],
    async () => {
      const response = await FundsTransfers.getAdminTotalPlayerBalances()
      return response.data as PlayersBalancesTotalRo[]
    },
    props,
  )
}
