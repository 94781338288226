import { FundsTransfers } from '@/services/api/funds-transfers'
import { FundsTransferRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useGetFundsTransferById = (
  id: number,
  props?: UseQueryOptions<FundsTransferRo, AxiosErrorResponse>,
) => {
  return useQuery<FundsTransferRo, AxiosErrorResponse>(
    ['funds-transfers', 'any', id],
    async () => {
      const response = await FundsTransfers.getById(id)
      return response.data
    },
    props,
  )
}
