import * as yup from 'yup'

export type UserGroupModalSchema = {
  name: string;
  coach: {
    id: number;
  }
  students: number[]
}

export const userGroupModalSchema = yup.object({
  name: yup.string().required('Введите название').max(255, 'Максимальная длина 255 символов'),
  coach: yup.object({
    id: yup.number().typeError('Укажите тренера группы').required('Укажите тренера группы'),
  }).required('Укажите тренера группы'),
  students: yup.array(),
})
