import React from 'react'
import { Box, Typography } from '@mui/material'

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ mb: 'auto' }} />
      <Box py={2} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {children}
      </Box>
      <Box sx={{
        flexGrow: 1,
        justifyContent: 'center',
        display: 'flex',
        mb: 2,
        mt: 'auto',
      }}>
        <Typography variant="caption">
          Copyright © {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  )
}
