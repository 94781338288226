export const CUSTOM_SETTING_TOURNAMENTS_RULES =
  'custom-setting-tournaments-rules'

export const CUSTOM_SETTING_OTHER_ROOMS_TABLE_PREFIX =
  'custom-setting-other-rooms-table-by-rank'

export const CUSTOM_SETTING_FUNDS_REQUESTS_INFO =
  'custom-setting-funds-requests-info'

export const CUSTOM_SETTING_WIKI_URL = 'custom-setting-wiki-url'

export const getCustomSettingOtherRoomsName = (rank: number) =>
  `${CUSTOM_SETTING_OTHER_ROOMS_TABLE_PREFIX}-${rank}`
