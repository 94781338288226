import { useState } from 'react'

import { FundsRequestsTable } from '@/components/Containers/FundsRequestsTable/FundsRequestsTable'
import { FundsTransfersTable } from '@/components/Containers/FundsTransfersTable/FundsTransfersTable'
import { FundsRequestsInfoView } from '@/components/CustomSettings/settings/FundsRequestsInfo/FundsRequestsInfo.view'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'

export const FundsTransfersPage = () => {
  const [transfersMode, setTransfersMode] = useState<'project' | 'personal'>(
    'project',
  )

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={8} lg={9}>
          <FundsRequestsTable />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FundsRequestsInfoView />
        </Grid>
      </Grid>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              color={transfersMode === 'project' ? 'primary' : 'secondary'}
              value={transfersMode}
              exclusive
              onChange={(_, value) => setTransfersMode(value)}
            >
              <ToggleButton
                value="project"
                disabled={transfersMode === 'project'}
              >
                Переводы внутри проекта
              </ToggleButton>
              <ToggleButton
                value="personal"
                disabled={transfersMode === 'personal'}
              >
                Личные переводы
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} key={transfersMode}>
            <FundsTransfersTable mode={transfersMode} />
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  )
}
