import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import { DefaultLayout } from '@/layouts/DefaultLayout/DefaultLayout'

export const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <DefaultLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mx: 'auto',
          my: 'auto',
          rowGap: '12px',
          maxWidth: '600px',
          minWidth: '300px',
        }}
      >
        <Typography variant="h2" component="h1">
          404
        </Typography>
        <Typography variant="h6">Страница не найдена</Typography>
        <Button onClick={() => navigate(-1)} variant="contained">Перейти назад</Button>
      </Box>
    </DefaultLayout>
  )
}
