import { useNavigate } from 'react-router-dom'

import { Form } from '@/components/Forms/Form'
import { FormItem } from '@/components/Forms/FormItem.tsx'
import { TextField } from '@/components/Forms/TextField'
import { Logo } from '@/components/UI/Logo/Logo'
import { useSnackBar } from '@/context/SnackbarContext'
import { useAuth } from '@/services/hooks/useAuth'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'

import { loginFormSchema, LoginFormValues } from './schema/loginForm.schema'

export const LoginForm = () => {
  const { showSnackBar } = useSnackBar()
  const navigate = useNavigate()
  const { loginMutation } = useAuth({
    onLoginSuccess: () => {
      showSnackBar('Успешный логин', 'success')
      navigate('/cabinet')
    },
    onLoginError: () => {
      showSnackBar('Не удалось войти', 'error')
    },
  })

  const handleSubmit = async ({
    username,
    password,
    isRememberMe,
  }: LoginFormValues) => {
    loginMutation.mutate({ username, password, isRememberMe })
  }

  const isFormDisabled = loginMutation.isLoading

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Logo width={180} height={40} mb={2} />
      <Typography component="h1" variant="h3">
        Вход в личный кабинет
      </Typography>
      <Box sx={{ mt: 1, width: '100%' }}>
        <Form
          validationSchema={loginFormSchema}
          onSubmit={handleSubmit}
          reValidateMode="onChange"
        >
          <TextField
            name="username"
            label="Логин"
            placeholder="Введите логин"
            textFieldProps={{
              disabled: isFormDisabled,
              required: true,
              autoFocus: true,
            }}
          />
          <TextField
            name="password"
            label="Пароль"
            placeholder="Введите пароль"
            textFieldProps={{
              disabled: isFormDisabled,
              required: true,
              inputProps: {
                type: 'password',
                autoComplete: 'current-password',
              },
            }}
          />
          <FormItem name="isRememberMe" labelPosition="none">
            <FormControlLabel
              name="isRememberMe"
              control={<Checkbox />}
              label="Запомнить меня"
            />
          </FormItem>

          <Button
            disabled={isFormDisabled}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Войти
          </Button>
        </Form>
      </Box>
    </Box>
  )
}
