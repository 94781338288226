import { ChangeEventHandler, InputHTMLAttributes } from 'react'

import { FormControl, Grid, TextField, TextFieldProps } from '@mui/material'
import { MUIDataTableColumn, MUIDataTableState } from 'mui-datatables'

export type CreateRangeFilterProps = {
  type: TextFieldProps['type']
  showSingleFilter?: boolean
  inputProps?: {
    single?: InputHTMLAttributes<HTMLInputElement>
    from?: InputHTMLAttributes<HTMLInputElement>
    to?: InputHTMLAttributes<HTMLInputElement>
  }
}

/**
 * @param type - тип инпута
 * @param showSingleFilter - Вместо промежутка, создаст один единственный фильтр.
 * @param inputProps
 */
export const createRangeFilter = ({
  type = 'text',
  showSingleFilter = false,
  inputProps,
}: CreateRangeFilterProps) => {
  return {
    display: (
      filterList: MUIDataTableState['filterList'],
      onChange: (
        val: string | string[],
        index: number,
        column: MUIDataTableColumn,
      ) => void,
      index: number,
      column: MUIDataTableColumn,
    ) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const value = filterList[index] as any

      const name = column.label || column.name
      const labels = {
        single: name,
        from: `${name} (от)`,
        to: `${name} (до)`,
      }

      const handleChangeSingle: ChangeEventHandler<HTMLInputElement> = ({
        target: { value },
      }) => {
        filterList[index] = filterList[index] ?? []
        filterList[index][0] = value
        onChange(filterList[index], index, column)
      }

      const handleChangeFrom: ChangeEventHandler<HTMLInputElement> = ({
        target: { value },
      }) => {
        filterList[index] = filterList[index] ?? []
        filterList[index][0] = value
        onChange(filterList[index], index, column)
      }

      const handleChangeTo: ChangeEventHandler<HTMLInputElement> = ({
        target: { value },
      }) => {
        filterList[index] = filterList[index] ?? []
        filterList[index][1] = value
        onChange(filterList[index], index, column)
      }

      if (showSingleFilter) {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label={labels.single}
                  id={`${column.name}`}
                  value={value[0] || ''}
                  onChange={handleChangeSingle}
                  type={type}
                  inputProps={inputProps?.single}
                />
              </FormControl>
            </Grid>
          </Grid>
        )
      }

      return (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                label={labels.from}
                id={`${column.name}.from`}
                value={value[0] || ''}
                onChange={handleChangeFrom}
                type={type}
                inputProps={inputProps?.from}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                label={labels.to}
                id={`${column.name}.to`}
                value={value[1] || ''}
                onChange={handleChangeTo}
                type={type}
                inputProps={inputProps?.to}
              />
            </FormControl>
          </Grid>
        </Grid>
      )
    },
    fullWidth: true,
  }
}
