import { UsersDataRo } from '@/services/data-contracts'
import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter'

export const usersToMultipleSelectOptions = (users?: UsersDataRo['data']): MultipleSelectOption[] => {
  if (!users?.length) {
    return []
  }

  return (users || []).map((user) => ({
    id: user.id,
    label: user.username,
    avatar: {
      alt: user.username,
    },
  }))
}
