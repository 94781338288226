import { AxiosResponse } from 'axios'

import {
  UserReportCreateDto,
  UserReportCreateRo,
  UserReportDataRo,
  UserReportEditDto,
  UserReportEditRo,
  UserReportRo,
  UserReportsControllerGetParams,
} from '@/services/data-contracts'

import { Request } from '../Request'

export const UserReports = {
  create: async (params: UserReportCreateDto) => {
    return Request.post<UserReportCreateDto, AxiosResponse<UserReportCreateRo>>('/user-reports', params)
  },

  get: async (params: UserReportsControllerGetParams) => {
    return Request.get<UserReportsControllerGetParams, AxiosResponse<UserReportDataRo>>('/user-reports', { params })
  },

  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<UserReportRo>>(`/user-reports/${id}`)
  },

  edit: async (id: number, params: UserReportEditDto) => {
    return Request.patch<UserReportEditDto, AxiosResponse<UserReportEditRo>>(`/user-reports/${id}`, params)
  },

  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/user-reports/${id}`)
  },
}
