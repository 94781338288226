import { FundsTransfers } from '@/services/api/funds-transfers'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

type UseFundsTransferPayload = {
  id: number
}

export const useDeleteFundsTransfer = (
  props?: UseMutationOptions<
    boolean,
    AxiosErrorResponse,
    UseFundsTransferPayload
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseFundsTransferPayload>(
    ['funds-transfers'],
    async (payload: UseFundsTransferPayload) => {
      const response = await FundsTransfers.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['funds-transfers'])
        await queryClient.invalidateQueries(['funds-requests'])
        await queryClient.invalidateQueries(['user', 'cabinet'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
