import { FundsTransfers } from '@/services/api/funds-transfers'
import {
  FundsTransferCreateToFundsRequestDto,
  FundsTransferRo,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useCreateToFundsRequestTransfer = (
  props?: UseMutationOptions<
    FundsTransferRo,
    AxiosErrorResponse,
    FundsTransferCreateToFundsRequestDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    FundsTransferRo,
    AxiosErrorResponse,
    FundsTransferCreateToFundsRequestDto
  >(
    ['funds-transfers', 'project', 'funds-request'],
    async (payload: FundsTransferCreateToFundsRequestDto) => {
      const response = await FundsTransfers.createToFundsRequestTransfer(
        payload,
      )
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['funds-transfers'])
        await queryClient.invalidateQueries(['funds-requests'])
        await queryClient.invalidateQueries(['user', 'cabinet'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
