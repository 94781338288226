import { TournamentFilters } from '@/services/api/tournament-filters.ts'
import {
  TournamentFiltersGetDto,
  TournamentFiltersSetRo,
} from '@/services/data-contracts.ts'
import { AxiosErrorResponse } from '@/types.ts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const useGetFilterByName = (
  params: TournamentFiltersGetDto,
  props?: UseQueryOptions<TournamentFiltersSetRo, AxiosErrorResponse>,
) => {
  return useQuery<TournamentFiltersSetRo, AxiosErrorResponse>(
    ['tournament-filters', params.name],
    async () => {
      const response = await TournamentFilters.getByName(params.name)
      return response.data
    },
    props,
  )
}
