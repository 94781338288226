import { AxiosResponse } from 'axios'

import { Request } from '../Request'
import {
  TournamentCreateDto,
  TournamentCreateRo,
  TournamentEditDto,
  TournamentEditRo,
  TournamentRo,
  TournamentsControllerFindAllParams,
  TournamentsDataRo,
} from '@/services/data-contracts'

export const Tournaments = {
  create: async (params: TournamentCreateDto) => {
    return Request.post<TournamentCreateDto, AxiosResponse<TournamentCreateRo>>('/tournaments', params)
  },
  get: async (params?: TournamentsControllerFindAllParams) => {
    return Request.get<TournamentsControllerFindAllParams, AxiosResponse<TournamentsDataRo>>('/tournaments', { params })
  },
  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<TournamentRo>>(`/tournaments/${id}`)
  },
  edit: async (id: number, params: TournamentEditDto) => {
    return Request.patch<TournamentEditDto, AxiosResponse<TournamentEditRo>>(`/tournaments/${id}`, params)
  },
  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/tournaments/${id}`)
  },
}
