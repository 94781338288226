import { Cabinet } from '@/services/api/cabinet.ts'
import {
  PlayersRoomsSettingsRo,
  UsersControllerGetParams,
} from '@/services/data-contracts.ts'
import { AxiosErrorResponse } from '@/types.ts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const useGetPlayersRoomsSettings = (
  params: UsersControllerGetParams,
  props?: UseQueryOptions<PlayersRoomsSettingsRo, AxiosErrorResponse>,
) => {
  return useQuery<PlayersRoomsSettingsRo, AxiosErrorResponse>(
    ['rooms-players-settings', params],
    async () => {
      const response = await Cabinet.getPlayersRoomsSettings(params)
      return response.data
    },
    props,
  )
}
