import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { UserReportDataRo, UserReportsControllerGetParams } from '@/services/data-contracts'
import { UserReports } from '@/services/api/user-reports'
import { AxiosErrorResponse } from '@/types'

export const useGetUserReports = (
  params: UserReportsControllerGetParams,
  props?: UseQueryOptions<UserReportDataRo, AxiosErrorResponse>,
) => {
  return useQuery<UserReportDataRo, AxiosErrorResponse>(
    ['user-reports', 'get', params],
    async () => {
      const response = await UserReports.get(params)
      return response.data
    },
    props,
  )
}
