import { useMemo } from 'react'

import { Form } from '@/components/Forms/Form'
import { TextField } from '@/components/Forms/TextField'
import {
  userContractModalSchema,
  UserContractModalSchema,
} from '@/components/Modals/UserContractModal/schema/userContractModal.schema'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useCreateUserContract } from '@/services/hooks/user-contracts/useCreateUserContract'
import { useEditUserContract } from '@/services/hooks/user-contracts/useEditUserContract'
import { useGetUserContractById } from '@/services/hooks/user-contracts/useGetUserContractById'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material'

import { AxiosErrorResponse } from '@/types'

interface UserContractCreateModalProps {
  mode: 'create'
  userContractId: undefined
  onSuccess?: () => void
  onError?: () => void
}

interface UserContractEditModalProps {
  mode: 'edit'
  userContractId: number
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

export type UserContractModalProps = (
  | UserContractCreateModalProps
  | UserContractEditModalProps
) &
  Omit<DialogProps, 'onError' | 'onSuccess'>

export const UserContractModal = ({
  mode,
  userContractId,
  onSuccess,
  onError,
  ...props
}: UserContractModalProps) => {
  const titleMap: Record<'create' | 'edit', string> = {
    create: 'Создание нового пакета',
    edit: `Редактирование пакета. ID: ${userContractId}`,
  }

  const { data: userContractResponse, isFetching: isUserContractFetching } =
    useGetUserContractById(userContractId || 0, {
      enabled: Boolean(mode === 'edit' && userContractId),
    })

  const createUserContract = useCreateUserContract({
    onSuccess,
    onError,
  })

  const editUserContract = useEditUserContract({
    onSuccess,
    onError,
  })

  const initialValues: Partial<UserContractModalSchema> = useMemo(() => {
    if (!userContractResponse) {
      return {}
    }

    return {
      id: userContractResponse.id,
      name: userContractResponse.name,
      financialInterest: userContractResponse.financialInterest,
      playerShare: userContractResponse.playerShare,
      projectShare: userContractResponse.projectShare,
    }
  }, [userContractResponse])

  const handleSubmit = (data: UserContractModalSchema) => {
    switch (mode) {
      case 'edit':
        return editUserContract.mutate({
          id: userContractId,
          ...data,
        })
      case 'create':
      default:
        return createUserContract.mutate(data)
    }
  }

  return (
    <Dialog maxWidth="sm" {...props}>
      <DialogTitle>{titleMap[mode]}</DialogTitle>
      <DialogContent>
        <Box minWidth={500}>
          <WithLoader isLoading={isUserContractFetching}>
            <Form<UserContractModalSchema>
              onSubmit={handleSubmit}
              validationSchema={userContractModalSchema}
              initialValues={initialValues}
            >
              <Box mb={1}>
                <TextField
                  label="Название"
                  name="name"
                  placeholder="Введите название пакета"
                />
              </Box>
              <Box mb={1}>
                <TextField
                  label="Процент финансирования"
                  name="financialInterest"
                  placeholder="0%"
                />
              </Box>
              <Box mb={1}>
                <TextField
                  label="Доля игрока"
                  name="playerShare"
                  placeholder="0%"
                  textFieldProps={{ InputProps: { type: 'number' } }}
                />
              </Box>
              <Box mb={1}>
                <TextField
                  label="Доля проекта"
                  name="projectShare"
                  placeholder="0%"
                  textFieldProps={{ InputProps: { type: 'number' } }}
                />
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={
                    createUserContract.isLoading || editUserContract.isLoading
                  }
                >
                  Сохранить
                </Button>
              </Box>
            </Form>
          </WithLoader>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
