import { MUIDataTableOptions } from 'mui-datatables'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'

export type CreateTextLabelsProps = {
  isLoading: boolean
  textLabels?: MUIDataTableOptions['textLabels']
}

export const createTextLabels = ({ isLoading, textLabels }: CreateTextLabelsProps): MUIDataTableOptions['textLabels'] => {
  return {
    toolbar: {
      filterTable: textLabels?.toolbar?.filterTable || 'Отфильтровать результаты',
      print: textLabels?.toolbar?.print || 'Распечатать',
      viewColumns: textLabels?.toolbar?.viewColumns || 'Отобразить колонки',
      search: textLabels?.toolbar?.search || 'Поиск',
      downloadCsv: textLabels?.toolbar?.downloadCsv || 'Загрузить CSV',
    },
    filter: {
      title: textLabels?.filter?.title || 'Фильтр',
      reset: textLabels?.filter?.reset || 'Сбросить',
      all: textLabels?.filter?.all || 'Все',
    },
    pagination: {
      rowsPerPage: textLabels?.pagination?.rowsPerPage || 'Строк',
      next: textLabels?.pagination?.next || 'Следующая страница',
      previous: textLabels?.pagination?.previous || 'Предыдущая страница',
      displayRows: textLabels?.pagination?.displayRows || 'из',
      jumpToPage: textLabels?.pagination?.jumpToPage || 'Перейти на',
    },
    body: {
      toolTip: textLabels?.body?.toolTip || 'Отсортировать',
      noMatch: isLoading
        ? <WithLoader isLoading={true} />
        : textLabels?.body?.noMatch || 'Ничего не найдено',
      columnHeaderTooltip: textLabels?.body?.columnHeaderTooltip,
    },
    selectedRows: textLabels?.selectedRows,
    viewColumns: {
      title: 'Отобразить колонки' || textLabels?.viewColumns?.title,
      titleAria: 'Отобразить колонки' || textLabels?.viewColumns?.titleAria,
    },
  }
}
