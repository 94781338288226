import { UserContracts } from '@/services/api/user-contracts'
import {
  UserContractEditDto,
  UserContractEditRo,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

type UseEditUserContractPayload = {
  id: number
} & UserContractEditDto

export const useEditUserContract = (
  props?: UseMutationOptions<
    UserContractEditRo,
    AxiosErrorResponse,
    UseEditUserContractPayload
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    UserContractEditRo,
    AxiosErrorResponse,
    UseEditUserContractPayload
  >(
    ['user-contracts'],
    async (payload: UseEditUserContractPayload) => {
      const { id, ...params } = payload
      const response = await UserContracts.edit(id, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['user-contracts'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
