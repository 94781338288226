import { useRef, useState } from 'react'
import { IconButton, InputAdornment, InputAdornmentProps, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface CopyToClipboardAdornmentProps {
  position: InputAdornmentProps['position'];
  valueToCopy: string;
}

export const CopyToClipboardAdornment = ({ position, valueToCopy }: CopyToClipboardAdornmentProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const prevTimeout = useRef<NodeJS.Timeout>()

  const handleCopy = () => {
    navigator.clipboard.writeText(valueToCopy)
      .then(() => setIsCopied(true));

    if (prevTimeout.current) {
      clearTimeout(prevTimeout.current)
    }

    prevTimeout.current = setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <InputAdornment
      position={position}
    >
      <IconButton onClick={handleCopy} color={isCopied ? 'secondary' : 'primary'}>
        <Tooltip title={isCopied ? 'Скопировано!' : 'Скопировать данные'} placement="top">
          <ContentCopyIcon />
        </Tooltip>
      </IconButton>
    </InputAdornment>
  )
}
