import { GameRequests } from '@/services/api/game-requests'
import {
  GameRequestCreateByScheduledTournamentDto,
  GameRequestCreateRo,
} from '@/services/data-contracts'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useCreateGameRequestByScheduledTournament = (
  props?: UseMutationOptions<
    GameRequestCreateRo,
    AxiosErrorResponse,
    GameRequestCreateByScheduledTournamentDto
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    GameRequestCreateRo,
    AxiosErrorResponse,
    GameRequestCreateByScheduledTournamentDto
  >(
    ['game-requests'],
    async (payload: GameRequestCreateByScheduledTournamentDto) => {
      const response = await GameRequests.createByScheduledTournament(payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['game-requests'])
        props?.onSuccess?.(...args)
      },
    },
  )
}
