import { useState } from 'react'

import { Form } from '@/components/Forms/Form'
import { WithFormContext } from '@/components/Forms/helpers/WithFormContext'
import { TextField } from '@/components/Forms/TextField'
import {
  userGoogleSheetsSettingsSchema,
  UserGoogleSheetsSettingsSchema,
} from '@/components/Modals/UserGoogleSheetsSettingsModal/schema/userGoogleSheetsSettings.schema'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useGoogleGetUserSettings } from '@/services/hooks/google-sheets/useGoogleGetUserSettings'
import {
  useGoogleSetUserSettings,
  UseGoogleSetUserSettingsPayload,
} from '@/services/hooks/google-sheets/useGoogleSetUserSettings'
import { parseGoogleSheetId } from '@/utils/parseGoogleSheetId'
import { Box, Button } from '@mui/material'

import { AxiosErrorResponse } from '@/types'

import { GridError } from './GridError'

export type UserGoogleSheetsSettingsProps = {
  userId: number
}

export const UserGoogleSheetsSettings = ({
  userId,
}: UserGoogleSheetsSettingsProps) => {
  const [error, setError] = useState<string>('')

  const { data: userSettings, isFetching: isUserSettingsFetching } =
    useGoogleGetUserSettings(userId)

  const setUserSettings = useGoogleSetUserSettings({
    onError: (error: AxiosErrorResponse) => {
      setError(error?.response?.data?.error || error.message)
    },
  })

  const handleSaveUserSettings = (data: UserGoogleSheetsSettingsSchema) => {
    setError('')

    setUserSettings.mutate({
      userId,
      balancesTransfersSheetId: data.balancesTransfersSheetId || null,
    } as UseGoogleSetUserSettingsPayload)
  }

  return (
    <WithLoader isLoading={isUserSettingsFetching}>
      <GridError error={error} />
      <Form<UserGoogleSheetsSettingsSchema>
        onSubmit={handleSaveUserSettings}
        validationSchema={userGoogleSheetsSettingsSchema}
        initialValues={userSettings}
      >
        <WithFormContext>
          {({ setValue }) => (
            <TextField
              label="ID таблицы балансов/переводов пользователя"
              name="balancesTransfersSheetId"
              disabled={isUserSettingsFetching}
              textFieldProps={{
                placeholder: 'Можно скопировать полный URL',
                inputProps: {
                  onPaste: event => {
                    const raw = event.clipboardData.getData('Text')
                    const parsedId = parseGoogleSheetId(raw)

                    if (parsedId) {
                      requestAnimationFrame(() => {
                        setValue('balancesTransfersSheetId', parsedId)
                      })
                    }
                  },
                },
              }}
            />
          )}
        </WithFormContext>
        <Box mt={1}>
          <Button
            disabled={setUserSettings.isLoading}
            variant="contained"
            type="submit"
            fullWidth
            size="small"
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </WithLoader>
  )
}
