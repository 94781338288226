import React, { useEffect } from 'react'

interface HelmetProps {
  title: string;
  children: React.ReactNode;
}

export const Helmet = ({ title, children }: HelmetProps) => {
  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <>{children}</>
  )
}
