import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { Uploads } from '@/services/api/upload'
import { UploadFileDto, UploadFileRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useUpload = (props?: UseMutationOptions<UploadFileRo, AxiosErrorResponse, UploadFileDto>) => {
  return useMutation<UploadFileRo, AxiosErrorResponse, UploadFileDto>(
    ['uploads'],
    async (payload: UploadFileDto) => {
      const response = await Uploads.create(payload)
      return response.data
    },
    props,
  )
}
