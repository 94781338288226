import { Form } from '@/components/Forms/Form'
import { TextField } from '@/components/Forms/TextField'
import {
  useHandleStageApprovalGameRequest,
  UseHandleStageApprovalGameRequestProps,
} from '@/services/hooks/game-requests/useHandleStageApprovalGameRequest'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@mui/material'

import { AxiosErrorResponse } from '@/types'

import {
  ApproveGameRequestModalSchema,
  approveGameRequestModalSchema,
} from './schema/approveGameRequestModal.schema'

export interface ApproveGameRequestModalProps
  extends Omit<DialogProps, 'onError' | 'onSuccess'> {
  gameRequestId: number
  mode: 'approved' | 'declined'
  onSuccess?: () => void
  onError?: (err: AxiosErrorResponse) => void
}

export const ApproveGameRequestModal = ({
  gameRequestId,
  mode,
  onSuccess,
  onError,
  ...props
}: ApproveGameRequestModalProps) => {
  const handleStageApprovalGameRequest = useHandleStageApprovalGameRequest({
    onSuccess,
    onError,
  })

  const titleIcon = {
    approved: <CheckCircleIcon color="success" />,
    declined: <UnpublishedIcon color="error" />,
  }

  const titleMap = {
    approved: 'Разрешить. Игра в пакет',
    declined: 'Отказать. Игра от себя.',
  }

  const handleSubmit = (data: ApproveGameRequestModalSchema) => {
    handleStageApprovalGameRequest.mutate({
      id: gameRequestId,
      status: mode,
      ...(data.moderatorComment && {
        moderatorComment: data.moderatorComment,
      }),
    } as UseHandleStageApprovalGameRequestProps)
  }

  return (
    <Dialog maxWidth="xs" {...props}>
      <Box minWidth={400}>
        <DialogTitle>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {titleIcon[mode]}
            {titleMap[mode]}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Form<ApproveGameRequestModalSchema>
            onSubmit={handleSubmit}
            validationSchema={approveGameRequestModalSchema}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  name="moderatorComment"
                  label="Комментарий селектора"
                  textFieldProps={{
                    multiline: true,
                    rows: 4,
                  }}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={handleStageApprovalGameRequest.isLoading}
              >
                Подтвердить
              </Button>
            </Box>
          </Form>
        </DialogContent>
      </Box>
    </Dialog>
  )
}
