import { Box, Typography } from '@mui/material'

import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'

import BlurOnOutlinedIcon from '@mui/icons-material/BlurOnOutlined'

export const CabinetPage = () => {
  const { currentUser } = useCurrentUser()

  return (
    <DashboardLayout>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: '1', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <BlurOnOutlinedIcon color="primary" sx={{ fontSize: 64 }} />
        <Typography color="primary" variant="h1">Добро пожаловать{currentUser?.username ? `, ${currentUser.username}` : ''}</Typography>
        <Typography>Чтобы продолжить, перейдите на любую страницу</Typography>
      </Box>
    </DashboardLayout>
  )
}
