import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { WithRole } from '@/components/Utils/WithRole/WithRole'
import { UserRole } from '@/constants'
import { useAuth } from '@/services/hooks/useAuth'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'

export const DashboardUser = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement | null>(
    null,
  )
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()
  const { logoutMutation } = useAuth()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = async () => {
    logoutMutation.mutate()
    navigate('/login')
  }

  if (!currentUser) {
    return null
  }

  return (
    <Box>
      <Tooltip title="Открыть настройки">
        <Box
          onClick={handleOpenUserMenu}
          sx={{
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <IconButton sx={{ p: 0 }}>
            <Avatar>{currentUser.username[0].toUpperCase()}</Avatar>
          </IconButton>
          <Typography>{currentUser.username}</Typography>
        </Box>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={false}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorElUser}
        onClose={handleCloseUserMenu}
      >
        <WithRole roles={[UserRole.admin]}>
          <MenuItem component={Link} to="/cabinet/custom-settings">
            <Typography textAlign="center">Кастомные настройки</Typography>
          </MenuItem>
        </WithRole>
        <WithRole roles={[UserRole.admin, UserRole.selector]}>
          <MenuItem component={Link} to={`/cabinet/google-sheets`}>
            <Typography textAlign="center">Google API</Typography>
          </MenuItem>
        </WithRole>
        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">Выход</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
