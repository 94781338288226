import { UserRole, UserRoleValue } from '@/constants'
import { UsersRo } from '@/services/data-contracts'

export class RoleHelper {
  static isPlayer(role?: UserRoleValue | UsersRo['role']): boolean {
    if (!role) {
      return false
    }

    return role === UserRole.player
  }

  static isSelector(role?: UserRoleValue | UsersRo['role']): boolean {
    if (!role) {
      return false
    }

    return role === UserRole.selector
  }

  static isCoach(role?: UserRoleValue | UsersRo['role']): boolean {
    if (!role) {
      return false
    }

    return role === UserRole.coach
  }

  static isAdmin(role?: UserRoleValue | UsersRo['role']): boolean {
    if (!role) {
      return false
    }

    return role === UserRole.admin
  }
}
