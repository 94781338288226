import React from 'react'
import { Alert } from '@mui/material'

export interface WithForbiddenAlertProps {
  message?: string;
  isRestricted: boolean;
  onRefresh?: VoidFunction;
  children: React.ReactNode
}

export const WithAlert = ({ message, isRestricted, onRefresh, children }: WithForbiddenAlertProps): React.ReactElement => {
  if (isRestricted) {
    return (
      <Alert
        severity="error"
        role={onRefresh ? 'button' : undefined}
        title={onRefresh ? 'Обновить' : undefined}
        onClick={onRefresh}
        sx={{
          cursor: onRefresh ? 'pointer' : undefined,
        }}
      >
        {message || 'Доступ запрещен'}
      </Alert>
    )
  }

  return children as React.ReactElement;
}
