import { buildOptions } from '@/components/Containers/TournamentRulesTable/utils/buildOptions.ts'
import {
  MultipleSelectOption,
  MultipleSelectProps,
} from '@/components/Tables/filters/createMultipleSelectFilter.tsx'
import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar.tsx'
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { MUIDataTableColumn, MUIDataTableState } from 'mui-datatables'

export const createMultipleSelectWithNetworkFilter = (
  options: MultipleSelectOption[],
  initialProps: MultipleSelectProps = {},
) => {
  const props: MultipleSelectProps = {
    enableAvatar: false,
    ...initialProps,
  }

  const optionsAdapter = buildOptions(options)

  return {
    display: (
      filterList: MUIDataTableState['filterList'],
      onChange: (
        val: string | string[],
        index: number,
        column: MUIDataTableColumn,
      ) => void,
      index: number,
      column: MUIDataTableColumn,
    ) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const value = filterList[index] as any
      const label: string = column.label || column.name

      return (
        <FormControl>
          <InputLabel shrink>{label}</InputLabel>
          <Select
            size="small"
            multiple
            value={value}
            renderValue={selected =>
              selected
                .map((id: number) => {
                  const candidate = optionsAdapter.find(
                    option => option.id === id,
                  )
                  return candidate?.label || ''
                })
                .join(',')
            }
            onChange={event => {
              filterList[index] = event.target.value
              onChange(filterList[index], index, column)
            }}
            input={<OutlinedInput notched label={label} />}
          >
            {optionsAdapter.map(option => {
              const { id } = option
              const name = option?.network?.name || option?.label
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox color="primary" checked={value.includes(id)} />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {props.enableAvatar && option.avatar && (
                      <ColorAvatar
                        color={option?.avatar?.color}
                        variant="rounded"
                        src={option?.avatar?.src}
                        title={option.avatar.alt}
                        sx={{ width: '30px', height: '30px', fontSize: '12px' }}
                      />
                    )}
                    <ListItemText primary={name} />
                  </Box>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )
    },
  }
}
