import { memo } from 'react'

import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar'
import {
  AvatarProps,
  Box,
  BoxProps,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material'

export interface TableImageTitleProps {
  title: string
  src?: string
  boxProps?: BoxProps
  avatarProps?: AvatarProps
  typographyProps?: TypographyProps
  vertical?: boolean
  disableTooltip?: boolean
  disableAvatar?: boolean
}

export const TableImageTitle = memo(
  ({
    title,
    src,
    disableTooltip = true,
    disableAvatar,
    vertical,
    boxProps,
    avatarProps,
    typographyProps,
  }: TableImageTitleProps) => {
    const contentJSX = title ? (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          flexDirection: vertical ? 'column' : 'row',
          ...boxProps?.sx,
        }}
        {...boxProps}
      >
        {!disableAvatar && (
          <ColorAvatar
            variant="rounded"
            title={title}
            src={src}
            sx={{
              width: '24px',
              height: '24px',
              fontSize: '10px',
              ...avatarProps?.sx,
            }}
            {...avatarProps}
          />
        )}
        <Typography variant="body2" {...typographyProps}>
          {title}
        </Typography>
      </Box>
    ) : (
      <>Не указан</>
    )

    if (disableTooltip) {
      return contentJSX
    }

    return <Tooltip title={title}>{contentJSX}</Tooltip>
  },
)
