import { CUSTOM_SETTING_TOURNAMENTS_RULES } from '@/components/CustomSettings/constants'
import { CustomSettingWrap } from '@/components/CustomSettings/templates/CustomSettingWrap'
import { TextSetting } from '@/components/CustomSettings/templates/TextCustomSetting/Text.setting'

export const TournamentsRulesSetting = () => (
  <CustomSettingWrap>
    <TextSetting
      name={CUSTOM_SETTING_TOURNAMENTS_RULES}
      title={`Блок с текстом под блоком "Серии"`}
    />
  </CustomSettingWrap>
)
