import { useMemo } from 'react'
import { Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, Grid, Typography } from '@mui/material'
import { Form } from '@/components/Forms/Form'
import { TextField } from '@/components/Forms/TextField'
import {
  USER_REPORT_BB_100_MONTH_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD,
  USER_REPORT_EVBB_100_MONTH_FIELD,
  USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD,
  USER_REPORT_HANDS_COUNT_FIELD,
  userReportFieldMap,
} from '@/constants'
import { PhotoUploader } from '@/components/Forms/PhotoUploader'
import { userReportModalSchema, UserReportModalSchema } from '@/components/Modals/UserReportModal/schema/userReportModal.schema'
import { useCreateUserReport } from '@/services/hooks/user-reports/useCreateUserReport'
import { useEditUserReport } from '@/services/hooks/user-reports/useEditUserReport'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useGetUserReportById } from '@/services/hooks/user-reports/useGetUserReportById'

interface UserReportCreateModalProps {
  mode: 'create'
  reportId: undefined
  onSuccess?: () => void;
  onError?: () => void;
}

interface UserReportEditModalProps {
  mode: 'edit'
  reportId: number
  onSuccess?: () => void;
  onError?: () => void;
}

export type UserReportModalProps = (UserReportCreateModalProps | UserReportEditModalProps) & DialogProps;

export const UserReportModal = ({ mode, reportId, onSuccess, onError, ...props }: UserReportModalProps) => {
  const titleMap: Record<'create' | 'edit', string> = {
    create: 'Создание отчёта',
    edit: `Редактирование отчёта. ID: ${reportId}`,
  }

  const { data: userReportResponse, isFetching: isUserReportFetching } = useGetUserReportById(reportId || 0, {
    enabled: Boolean(mode === 'edit' && reportId),
  })

  const createUserReport = useCreateUserReport({
    onSuccess,
    onError,
  })

  const editUserReport = useEditUserReport({
    onSuccess,
    onError,
  })

  const handleSubmit = (data: UserReportModalSchema) => {
    const hands = (data.hands || []).filter(Boolean).map((id) => ({ id }))
    const screenshots = (data.screenshots || []).filter(Boolean).map((id) => ({ id }))

    if (mode === 'create') {
      createUserReport.mutate({
        hands,
        screenshots,
        fields: data.fields,
      })

      return
    }

    if (mode === 'edit') {
      editUserReport.mutate({
        id: reportId,
        hands,
        screenshots,
        fields: data.fields,
      })

      return
    }
  }

  const initialValues: Partial<UserReportModalSchema> = useMemo(() => {
    return {
      fields: userReportResponse?.fields,
      hands: (userReportResponse?.hands || []).map((entity) => entity.id),
      screenshots: (userReportResponse?.screenshots || []).map((entity) => entity.id),
    }
  }, [userReportResponse])

  return (
    <Dialog maxWidth="xl" {...props}>
      <DialogTitle>{titleMap[mode]}</DialogTitle>
      <DialogContent>
        <Box width={600}>
          <WithLoader isLoading={isUserReportFetching}>
            <Form<UserReportModalSchema>
              onSubmit={handleSubmit}
              validationSchema={userReportModalSchema}
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              initialValues={initialValues as any}>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={userReportFieldMap[USER_REPORT_HANDS_COUNT_FIELD]}
                    name={`fields.${USER_REPORT_HANDS_COUNT_FIELD}`}
                    textFieldProps={{
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={userReportFieldMap[USER_REPORT_EVBB_100_MONTH_FIELD]}
                    name={`fields.${USER_REPORT_EVBB_100_MONTH_FIELD}`}
                    textFieldProps={{
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={userReportFieldMap[USER_REPORT_BB_100_MONTH_FIELD]}
                    name={`fields.${USER_REPORT_BB_100_MONTH_FIELD}`}
                    textFieldProps={{
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={userReportFieldMap[USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD]}
                    name={`fields.${USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD}`}
                    textFieldProps={{
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={userReportFieldMap[USER_REPORT_EVBB_100_100K_HANDS_FIELD]}
                    name={`fields.${USER_REPORT_EVBB_100_100K_HANDS_FIELD}`}
                    textFieldProps={{
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={userReportFieldMap[USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD]}
                    name={`fields.${USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD}`}
                    textFieldProps={{
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    label={userReportFieldMap[USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD]}
                    name={`fields.${USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD}`}
                    textFieldProps={{
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box>
                <Typography>Руки (скрины)</Typography>
              </Box>
              <Box mb={1} sx={{ display: 'flex', gap: 1 }}>
                <PhotoUploader label="Скрин 1" name="hands.0" buttonProps={{ color: 'secondary' }} />
                <PhotoUploader label="Скрин 2" name="hands.1" buttonProps={{ color: 'secondary' }} />
                <PhotoUploader label="Скрин 3" name="hands.2" buttonProps={{ color: 'secondary' }} />
              </Box>
              <Box>
                <Typography>Общие (скрины)</Typography>
              </Box>
              <Box mb={1} sx={{ display: 'flex', gap: 1 }}>
                <PhotoUploader label="Скрин 1" name="screenshots.0" buttonProps={{ color: 'secondary' }} />
                <PhotoUploader label="Скрин 2" name="screenshots.1" buttonProps={{ color: 'secondary' }} />
                <PhotoUploader label="Скрин 3" name="screenshots.2" buttonProps={{ color: 'secondary' }} />
              </Box>
              <Box mt={2}>
                <Button variant="contained" type="submit" fullWidth>Сохранить</Button>
              </Box>
            </Form>
          </WithLoader>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
