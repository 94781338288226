import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Users } from '@/services/api/users'
import { AxiosErrorResponse } from '@/types'

type UseDeleteUserPayload = {
  id: number;
}

export const useDeleteUser = (props?: UseMutationOptions<boolean, AxiosErrorResponse, UseDeleteUserPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteUserPayload>(
    ['users'],
    async (payload: UseDeleteUserPayload) => {
      const response = await Users.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['users'])
        props?.onSuccess?.(...args)
      },
    })
}
