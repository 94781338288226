import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { TextView } from '@/components/CustomSettings/templates/TextCustomSetting/Text.view'
import { Box, FormControlLabel, Switch } from '@mui/material'

import { CustomSettingTypeEnum } from '@/components/CustomSettings/types'

export const TextSettingPreview = () => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const { watch } = useFormContext()

  const title = watch('title')
  const content = watch('content')

  return (
    <Box my={2}>
      <FormControlLabel
        control={<Switch />}
        label="Отобразить превью"
        onChange={() => setPreviewVisible(prev => !prev)}
        value={previewVisible}
      />
      {previewVisible && (
        <Box mt={2}>
          <TextView
            previewContent={{
              title,
              content,
              type: CustomSettingTypeEnum.Text,
            }}
          />
        </Box>
      )}
    </Box>
  )
}
