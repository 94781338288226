import { useState } from 'react'
import { Box, Popper, Typography, ClickAwayListener, Paper } from '@mui/material'
import { CurrencyRateTable } from '@/components/Containers/CurrencyRateTable/CurrencyRateTable.tsx'
import { useGetTodayRate } from '@/services/hooks/currency-rate/useGetTodayRate.ts'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader.tsx'
import { WithAlert } from '@/components/Utils/WithAlert/WithAlert.tsx'
import { RoomCurrency } from '@/types.ts'
import { CurrencyHelper } from '@/utils/CurrencyHelper.ts'

interface CurrencyRateProps {
  from: RoomCurrency;
  to: RoomCurrency;
  view?: 'full' | 'sign-only'
}

export const CurrencyRate = ({ from, to, view = 'full' }: CurrencyRateProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    data: rate,
    isFetching: isRateFetching,
    isError: isRateError,
    refetch: refetchRate
  } = useGetTodayRate({ fromCurrency: from, toCurrency: to });

  const currencyView = {
    from: view === 'full' ? from : CurrencyHelper.getCurrencySign(from),
    to: view === 'full' ? to : CurrencyHelper.getCurrencySign(to),
  };

  const currencyPair = `${currencyView.from}/${currencyView.to}`
  const currencyRate = (rate || 0).toFixed(4)

  return (
    <Box
      sx={{
        paddingX: '24px',
      }}>
    <WithLoader isLoading={isRateFetching} size={1}>
      <WithAlert
        isRestricted={isRateError}
        message="Не удалось загрузить курс"
        onRefresh={() => void refetchRate()}
      >
        {rate && (
          <ClickAwayListener onClickAway={() => {
            setAnchorEl(null);
            setOpen(false);
          }}>
            <Box>
              <Typography
                aria-describedby="currency-rate-popper"
                role="button"
                sx={{ cursor: 'pointer' }}
                onClick={(event) => {
                  setOpen((prev) => !prev);
                  setAnchorEl(event.currentTarget.parentElement);
                }}
              >
                {currencyPair}: {currencyRate}
              </Typography>
              <Popper
                id="currency-rate-popper"
                open={open}
                placement="bottom-start"
                anchorEl={anchorEl}
              >
                <Paper>
                  <Box p={0} minWidth={500} maxWidth={500}>
                    <CurrencyRateTable from={from} to={to} view={view} />
                  </Box>
                </Paper>
              </Popper>
            </Box>
          </ClickAwayListener>
        )}
      </WithAlert>
    </WithLoader>
    </Box>
  )
}
