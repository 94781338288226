import { Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, Divider } from '@mui/material'

import { Form } from '@/components/Forms/Form'
import { PhotoUploader } from '@/components/Forms/PhotoUploader'
import { TextField } from '@/components/Forms/TextField'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useGetUserById } from '@/services/hooks/users/useGetUserById'
import { useGetReviewById } from '@/services/hooks/reviews/useGetReviewById'
import { useCreateReview } from '@/services/hooks/reviews/useCreateReview'
import { useEditReview, UseEditReviewPayload } from '@/services/hooks/reviews/useEditReview'
import { ReviewType } from '@/types'
import { Select } from '@/components/Forms/Select'
import { getFieldName } from '@/utils/getFieldName'
import { getFieldLabel, KnownFieldMaps } from '@/utils/getFieldLabel'
import {
  REVIEW_COMMENT_LOYALTY_FIELD,
  REVIEW_COMMENT_RECOMMENDATIONS_FIELD,
  REVIEW_COMMENT_TECHNICAL_FIELD,
  REVIEW_PROMOTION_OPINION_FIELD,
  REVIEW_SCORE_MENTAL_FIELD,
  REVIEW_SCORE_SELECT_FIELD,
  REVIEW_SCORE_TECHNICAL_FIELD,
  REVIEW_USER_RANK_HIDDEN_FIELD,
  reviewPromotionOpinionSelectOptions,
  reviewScoreSelectOptions,
} from '@/constants'
import { ReviewCreateDto } from '@/services/data-contracts'
import { reviewModalSchema, ReviewModalSchema } from '@/components/Modals/ReviewModal/schema/reviewModal.schema'

interface ReviewCreateModalProps {
  mode: 'create'
  type: ReviewType
  reviewId: undefined
  playerId: number
  onSuccess?: () => void
  onError?: () => void
}

interface ReviewEditModalProps {
  mode: 'edit'
  type: ReviewType
  reviewId: number
  playerId: number
  onSuccess?: () => void
  onError?: () => void
}

export type ReviewModalProps = (ReviewCreateModalProps | ReviewEditModalProps) & DialogProps;

export const ReviewModal = ({ mode, type, reviewId, playerId, onSuccess, onError, ...props }: ReviewModalProps) => {
  const titleMap: Record<'create' | 'edit', Record<ReviewType, string>> = {
    create: {
      monthly: 'Создание отзыва на игрока',
      intermediate: 'Создание промежуточного отзыва на игрока',
    },
    edit: {
      monthly: 'Редактирование отзыва на игрока',
      intermediate: 'Редактирование промежуточного отзыва на игрока',
    },
  }

  const { data: player, isFetching: isPlayerFetching } = useGetUserById(playerId || 0, {
    enabled: !!playerId,
  })
  const { data: review, isFetching: isReviewFetching } = useGetReviewById({ id: reviewId || 0 }, {
    enabled: !!reviewId,
  })

  const createReview = useCreateReview({
    onSuccess,
    onError,
  })

  const editReview = useEditReview({
    onSuccess,
    onError,
  })

  const handleSubmit = (data: ReviewModalSchema) => {
    if (!player) {
      return
    }

    const fields = {
      ...data.fields,
      [REVIEW_USER_RANK_HIDDEN_FIELD]: player.rank,
    }

    if (mode === 'create') {
      createReview.mutate({
        type,
        player: {
          id: player.id,
        },
        fields,
        ...(data.file?.id && {
          file: {
            id: data.file.id,
          },
        }),
      } as ReviewCreateDto)

      return
    }

    if (!review) {
      return
    }

    if (mode === 'edit') {
      editReview.mutate({
        type,
        id: review.id,
        fields,
        ...(data.file?.id && {
          file: {
            id: data.file.id,
          },
        }),
      } as UseEditReviewPayload)
    }
  }

  const isLoading = isPlayerFetching || isReviewFetching

  const mapType: KnownFieldMaps = 'review'

  return (
    <Dialog {...props}>
      <DialogTitle>{titleMap[mode][type]}</DialogTitle>
      <DialogContent>
        <Box minWidth={500}>
          <WithLoader isLoading={isLoading}>
            <Form<ReviewModalSchema>
              onSubmit={handleSubmit}
              initialValues={review as ReviewModalSchema}
              validationSchema={reviewModalSchema}
            >
              <Box mb={1}>
                <TextField
                  name={getFieldName(REVIEW_COMMENT_TECHNICAL_FIELD)}
                  label={getFieldLabel(REVIEW_COMMENT_TECHNICAL_FIELD, mapType)}
                  textFieldProps={{
                    rows: 3,
                    multiline: true,
                  }}
                />
              </Box>
              <Box mb={1}>
                <TextField
                  name={getFieldName(REVIEW_COMMENT_LOYALTY_FIELD)}
                  label={getFieldLabel(REVIEW_COMMENT_LOYALTY_FIELD, mapType)}
                  textFieldProps={{
                    rows: 3,
                    multiline: true,
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  name={getFieldName(REVIEW_COMMENT_RECOMMENDATIONS_FIELD)}
                  label={getFieldLabel(REVIEW_COMMENT_RECOMMENDATIONS_FIELD, mapType)}
                  textFieldProps={{
                    rows: 3,
                    multiline: true,
                  }}
                />
              </Box>
              <Box mb={2}>
                <Select
                  name={getFieldName(REVIEW_SCORE_TECHNICAL_FIELD)}
                  label={getFieldLabel(REVIEW_SCORE_TECHNICAL_FIELD, mapType)}
                  options={reviewScoreSelectOptions}
                />
              </Box>
              <Box mb={2}>
                <Select
                  name={getFieldName(REVIEW_SCORE_MENTAL_FIELD)}
                  label={getFieldLabel(REVIEW_SCORE_MENTAL_FIELD, mapType)}
                  options={reviewScoreSelectOptions}
                />
              </Box>
              <Box mb={2}>
                <Select
                  name={getFieldName(REVIEW_SCORE_SELECT_FIELD)}
                  label={getFieldLabel(REVIEW_SCORE_SELECT_FIELD, mapType)}
                  options={reviewScoreSelectOptions}
                />
              </Box>
              <Box mb={2}>
                <Select
                  name={getFieldName(REVIEW_PROMOTION_OPINION_FIELD)}
                  label={getFieldLabel(REVIEW_PROMOTION_OPINION_FIELD, mapType)}
                  options={reviewPromotionOpinionSelectOptions}
                />
              </Box>
              <PhotoUploader name="file.id" label="Скриншот" buttonProps={{ color: 'secondary' }} />
              <Box my={2}>
                <Divider />
              </Box>
              <Box>
                <Button
                  disabled={createReview.isLoading || editReview.isLoading}
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  Сохранить
                </Button>
              </Box>
            </Form>
          </WithLoader>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
