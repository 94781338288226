import { addMinutes, isDate, parse } from 'date-fns'
import * as yup from 'yup'
import { TestFunction } from 'yup'

export type UserPersonalDataModalSchema = {
  fullName: string | null
  dateOfBirth: string | null
  phoneNumber: string | null
  country: string | null
  address: string | null
  sharkAlias: string | null
  gmail: string | null
  discordId: string | null
  discordChannelId: string | null
  telegramId: string | null
  joinDate: string | null
  screenshotsUrl: string | null
  additionalInfo: string | null
  kickDate: string | null
  ref: {
    id: number
  } | null
}

const validateMaxTodayDateString: TestFunction = value => {
  if (typeof value !== 'string' || value === '') {
    return true
  }

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const parsed = isDate(value)
    ? (value as unknown as Date)
    : parse(value, 'yyyy-MM-dd', addMinutes(today, -today.getTimezoneOffset()))

  return today.getTime() >= parsed.getTime()
}

const testMaxTodayDate: [string, string, TestFunction] = [
  'check-max-data',
  'Не может быть больше сегодняшнего дня',
  validateMaxTodayDateString,
]

export const userPersonalDataModalSchema = yup.object().shape({
  // personal
  fullName: yup
    .string()
    .optional()
    .nullable()
    .max(100, 'Не более 100 символов'),
  dateOfBirth: yup
    .string()
    .optional()
    .nullable()
    .test(...testMaxTodayDate),
  phoneNumber: yup
    .string()
    .optional()
    .nullable()
    .max(15, 'Не более 15 символов'),
  country: yup.string().optional().nullable().max(3, 'Не более 3 символов'),
  address: yup.string().optional().nullable().max(255, 'Не более 255 символов'),

  // accounts
  sharkAlias: yup
    .string()
    .optional()
    .nullable()
    .max(50, 'Не более 50 символов'),
  gmail: yup.string().optional().nullable(),
  discordId: yup
    .string()
    .optional()
    .nullable()
    .max(100, 'Не более 100 символов'),
  telegramId: yup
    .string()
    .optional()
    .nullable()
    .max(100, 'Не более 100 символов'),

  // project
  kickDate: yup
    .string()
    .optional()
    .nullable()
    .test(...testMaxTodayDate),
  joinDate: yup
    .string()
    .optional()
    .nullable()
    .test(...testMaxTodayDate),
  screenshotsUrl: yup
    .string()
    .optional()
    .nullable()
    .max(150, 'Не более 150 символов')
    .url('Введите валидный URL'),

  // other
  additionalInfo: yup.string().optional().nullable(),
})
