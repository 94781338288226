import { Cabinet } from '@/services/api/cabinet'
import { UserPlayerFundsRo } from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useGetPlayerFundsByToken = (
  props?: UseQueryOptions<UserPlayerFundsRo, AxiosErrorResponse>,
) => {
  return useQuery<UserPlayerFundsRo, AxiosErrorResponse>(
    ['user', 'cabinet', 'player', 'player-funds', 'by-token'],
    async () => {
      const response = await Cabinet.getPlayerFundsByToken()
      return response.data as UserPlayerFundsRo
    },
    props,
  )
}
