import { useEffect, useState } from 'react'

import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableDate } from '@/components/Tables/columns/TableDate/TableDate'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle'
import { createMultipleSelectFilter } from '@/components/Tables/filters/createMultipleSelectFilter'
import { createRangeFilter } from '@/components/Tables/filters/createRangeFilter'
import { useGetRoomsSelectValues } from '@/components/Tables/hooks/useGetRoomsSelectValues.ts'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { ROOMS_LIMITATIONS } from '@/constants.ts'
import { useTheme } from '@/context/ThemeContext.tsx'
import { TournamentsControllerFindAllParams } from '@/services/data-contracts'
import { useGetFilterByName } from '@/services/hooks/tournament-filters/useGetFilterByName.ts'
import { useSetFilter } from '@/services/hooks/tournament-filters/useSetFilter.ts'
import { useGetTournaments } from '@/services/hooks/tournaments/useGetTournaments'
import { isoStringToDatetimeLocal } from '@/utils/isoStringToDatetimeLocal'
import { Box, Button, Typography } from '@mui/material'
import { format, formatDistance } from 'date-fns'
import { ru as dateFnsRuLocale } from 'date-fns/locale'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'

import {
  TOURNAMENT_COLOR_DARK_MAP,
  TOURNAMENT_COLOR_MAP,
  TOURNAMENT_COLOR_SELECT_OPTIONS,
} from './constants'

const FILTERS_DEFAULT: TournamentsControllerFindAllParams = {
  'startTimestamp.from': new Date().toISOString(),
  'endTimestamp.to': undefined,
  'buyIn.from': undefined,
  'buyIn.to': undefined,
  'room.in': undefined,
  'color.in': undefined,
}

interface TournamentsTableProps {
  type: 'tournament' | 'serie'
  rank: number | undefined
}

const langMap = {
  tournament: {
    title: 'Турнир',
    header: 'Турниры',
    notFound: 'Турниров не найдено',
  },
  serie: {
    title: 'Серия',
    header: 'Серии',
    notFound: 'Серий не найдено',
  },
}

export const TournamentsTable = ({ type, rank }: TournamentsTableProps) => {
  const { theme } = useTheme() ?? {}
  const [filters, setFilters] =
    useState<TournamentsControllerFindAllParams>(FILTERS_DEFAULT)
  const [canSave, setCanSave] = useState(false)
  const saveFilter = useSetFilter()
  const { data: savedFilter } = useGetFilterByName({ name: 'tournament' })
  const tableDefault = useTableDefaultOptions()
  const tableSort = useTableSort(['startTimestamp', 'ASC'])
  const tablePagination = useTablePagination()

  const { data: tournaments, isFetching: isTournamentsFetching } =
    useGetTournaments({
      ...tablePagination.queryParams,
      ...tableSort.queryParams,
      'type.equal': type,
      ...(rank !== undefined && { 'rank.equal': rank }),
      ...filters,
    })
  const { isRoomsFetching, roomsSelectValues } = useGetRoomsSelectValues({
    category: ROOMS_LIMITATIONS.TOURNAMENTS,
  })

  const isFetching = isRoomsFetching || isTournamentsFetching

  const lang = langMap[type]

  const handleFilterConfirm: MUIDataTableOptions['onFilterConfirm'] =
    filterList => {
      const [dateTimeFilter, , room, , buyIn, color] = filterList
      tablePagination.helpers.resetPage()
      setFilters({
        'startTimestamp.from': dateTimeFilter?.[0]
          ? new Date(dateTimeFilter[0]).toISOString()
          : undefined,
        'buyIn.from': buyIn?.[0] ? +buyIn[0] : undefined,
        'buyIn.to': buyIn?.[1] ? +buyIn[1] : undefined,
        'room.in': room ? (room || []).map(Number) : undefined,
        'color.in': color ? (color || []).map(Number) : undefined,
      })
    }

  const [data, count] = tournaments
    ? [tournaments.data, tournaments.count]
    : [[], 0]

  useEffect(() => {
    if (canSave) {
      saveFilter.mutate({ name: 'tournament', value: filters })
      setCanSave(false)
    }
  }, [canSave, filters, saveFilter])

  useEffect(() => {
    if (savedFilter) {
      setFilters(savedFilter?.value as typeof filters)
    }
  }, [savedFilter])

  return (
    <MUIDataTable
      title={lang.header}
      data={data}
      columns={[
        {
          name: 'startTimestamp',
          label: 'Дата и время',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            customHeadLabelRender: () => 'Дата',
            customBodyRender: TableDate,
            filterType: 'custom',
            filterOptions: createRangeFilter({
              type: 'datetime-local',
              showSingleFilter: true,
            }),
            filterList: [
              filters['startTimestamp.from']
                ? isoStringToDatetimeLocal(filters['startTimestamp.from'])
                : '',
              filters['endTimestamp.to']
                ? isoStringToDatetimeLocal(filters['endTimestamp.to'])
                : '',
            ],
          },
        },
        {
          name: 'startTime',
          label: 'Начало',
          options: {
            sort: false,
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            customHeadLabelRender: () => 'Время',
            customBodyRender: (_, meta) => {
              const row = data[meta.rowIndex]
              const startTime = new Date(row.startTimestamp)
              const endTime = row.endTimestamp
                ? new Date(row.endTimestamp)
                : null

              const formatted = {
                startTime: format(startTime, 'HH:mm'),
                endTime: endTime ? format(endTime, 'HH:mm') : null,
              }

              const value: string =
                formatted.startTime && formatted.endTime && endTime
                  ? `${formatted.startTime}-${
                      formatted.endTime
                    } (${formatDistance(endTime, startTime, {
                      locale: dateFnsRuLocale,
                    })})`
                  : formatted.startTime

              return (
                <TableColumn minWidth={120} maxWidth={120}>
                  <Typography>{value}</Typography>
                </TableColumn>
              )
            },
            filter: false,
          },
        },
        {
          name: 'room.name',
          label: 'Рум',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            customBodyRender: (_: string, tableMeta) => {
              const row = data[tableMeta.rowIndex]
              const roomName = row.room?.name
              const roomAvatar = row.room?.avatar?.location

              return (
                <TableColumn minWidth={70} maxWidth={100}>
                  <TableImageTitle title={roomName} src={roomAvatar} />
                </TableColumn>
              )
            },
            filter: true,
            filterType: 'custom',
            filterOptions: {
              ...createMultipleSelectFilter(roomsSelectValues, {
                enableAvatar: true,
              }),
              fullWidth: true,
            },
            // @ts-ignore
            filterList: (filters['room.in'] ?? []).map(item => item),
          },
        },

        {
          name: 'title',
          label: lang.title,
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            filter: false,
            customBodyRender: (value: string) => (
              <TableColumn maxWidth={260}>
                <Typography>{value}</Typography>
              </TableColumn>
            ),
          },
        },

        {
          name: 'buyIn',
          label: 'BI',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            customBodyRender: value => (
              <TableColumn minWidth={30} maxWidth={30}>
                {value}
              </TableColumn>
            ),
            filter: true,
            filterType: 'custom',
            filterOptions: createRangeFilter({ type: 'number' }),
            filterList: [
              filters?.['buyIn.from']?.toString() || '',
              filters?.['buyIn.to']?.toString() || '',
            ],
          },
        },
        {
          name: 'color',
          label: 'Надежность турнира',
          options: {
            setCellProps:
              theme === 'dark'
                ? () => ({
                    style: {
                      color: '#121212',
                    },
                  })
                : undefined,
            display: false,
            filter: true,
            filterType: 'custom',
            filterOptions: {
              ...createMultipleSelectFilter(TOURNAMENT_COLOR_SELECT_OPTIONS, {
                enableAvatar: true,
              }),
              fullWidth: true,
            },
            // @ts-ignore
            filterList:
              filters['color.in']?.map(filter => filter.toString()) ?? [],
          },
        },
      ]}
      options={{
        ...tableDefault.options,
        ...tablePagination.options,
        ...tableSort.options,
        count,
        tableBodyHeight: '500px',
        filter: true,
        onFilterConfirm: handleFilterConfirm,
        customFilterDialogFooter: (filterList, applyNewFilters) => {
          return (
            <Box display="flex" gap="16px" alignItems="center">
              {tableDefault.options?.customFilterDialogFooter?.(
                filterList,
                applyNewFilters,
              )}
              <Box marginTop="24px">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleFilterConfirm(filterList)
                    applyNewFilters?.(filterList)
                    setCanSave(true)
                  }}
                >
                  Сохранить и применить
                </Button>
              </Box>
            </Box>
          )
        },
        onFilterChange: (_changedColumn, filterList, type) => {
          if (type === 'reset') {
            handleFilterConfirm(filterList)
          }
        },
        textLabels: createTextLabels({
          isLoading: isFetching,
          textLabels: {
            body: {
              noMatch: lang.notFound,
            },
          },
        }),
        setRowProps: (_, idx) => {
          const row = data[idx]
          const key = row.color as keyof typeof TOURNAMENT_COLOR_MAP
          return {
            style: {
              background:
                theme === 'light'
                  ? TOURNAMENT_COLOR_MAP[key] || undefined
                  : TOURNAMENT_COLOR_DARK_MAP[key] || undefined,
            },
          }
        },
      }}
      components={tableDefault.components}
    />
  )
}
