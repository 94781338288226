import { Request } from '@/services/Request.ts'
import { AxiosResponse } from 'axios'
import { TournamentRulesControllerFindAllParams, TournamentRulesDataRo } from '@/services/data-contracts.ts'

export const TournamentRules = {
  get(params?: TournamentRulesControllerFindAllParams) {
    return Request.get<
      TournamentRulesControllerFindAllParams,
      AxiosResponse<TournamentRulesDataRo>
    >('/tournament-rules', { params })
  }
}
