import { useMemo } from 'react'

import { Form } from '@/components/Forms/Form'
import { PhotoUploader } from '@/components/Forms/PhotoUploader'
import { Select } from '@/components/Forms/Select'
import { TextField } from '@/components/Forms/TextField'
import {
  roomModalSchema,
  RoomModalSchema,
} from '@/components/Modals/RoomModal/schema/roomModal.schema'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { roomCategorySelectOptions, roomFeaturesSelectOptions } from '@/constants'
import { useCreateRoom } from '@/services/hooks/rooms/useCreateRoom'
import { useEditRoom } from '@/services/hooks/rooms/useEditRoom'
import { useGetRoomById } from '@/services/hooks/rooms/useGetRoomById'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material'

interface RoomCreateModalProps {
  mode: 'create'
  roomId: undefined
  onSuccess?: () => void
  onError?: () => void
}

interface RoomEditModalProps {
  mode: 'edit'
  roomId: number
  onSuccess?: () => void
  onError?: () => void
}

export type RoomModalProps = (RoomCreateModalProps | RoomEditModalProps) &
  DialogProps

export const RoomModal = ({
  mode,
  roomId,
  onSuccess,
  onError,
  ...props
}: RoomModalProps) => {
  const titleMap: Record<'create' | 'edit', string> = {
    create: 'Создание рума',
    edit: `Редактирование рума. ID: ${roomId}`,
  }

  const { data: room, isFetching: isRoomFetching } = useGetRoomById(
    roomId || 0,
    {
      enabled: Boolean(mode === 'edit' && roomId),
    },
  )

  const createRoom = useCreateRoom({ onSuccess, onError })
  const editRoom = useEditRoom({ onSuccess, onError })

  const isButtonDisabled =
    createRoom.isLoading || editRoom.isLoading || isRoomFetching

  const handleSubmit = (data: RoomModalSchema) => {
    const payload = {
      name: data.name,
      ...(data.category && {
        category: data.category,
      }),
      ...(data.features && {
        features: data.features,
      }),
      currency: data.currency,
      sort: data.sort,
      ...(data?.avatar?.id && {
        avatar: {
          id: data.avatar.id,
        },
      }),
    }

    if (mode === 'create') {
      return createRoom.mutate(payload)
    }

    if (mode === 'edit') {
      return editRoom.mutate({
        id: roomId,
        ...payload,
      })
    }

    return null
  }

  const validationSchema = useMemo(() => roomModalSchema.generate(), [])

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...props}
    >
      <DialogTitle variant="h4">{titleMap[mode]}</DialogTitle>
      <Divider />
      <WithLoader isLoading={isRoomFetching}>
        <Form<RoomModalSchema>
          validationSchema={validationSchema}
          initialValues={
            mode === 'edit' ? (room as unknown as RoomModalSchema) : {}
          }
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField name="name" label="Название" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="sort"
                  label="Сортировка"
                  textFieldProps={{
                    inputProps: {
                      type: 'number',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="currency"
                  label="Валюта"
                  options={[
                    { id: 'USD', label: 'USD' },
                    { id: 'EUR', label: 'EUR' },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Категория"
                  name="category"
                  options={roomCategorySelectOptions}
                  multiple={true}
                  enableAvatar={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Фичи"
                  name="features"
                  options={roomFeaturesSelectOptions}
                  multiple={true}
                  enableAvatar={true}
                />
              </Grid>
              <Grid item xs={12}>
                <PhotoUploader
                  label="Аватар (JPG, PNG)"
                  name="avatar.id"
                  buttonProps={{ color: 'secondary' }}
                />
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={isButtonDisabled}
              >
                Сохранить
              </Button>
            </Box>
          </DialogContent>
        </Form>
      </WithLoader>
    </Dialog>
  )
}
