import { reviewFieldMap, userReportFieldMap } from '@/constants'

export type KnownFieldMaps = 'user-report' | 'review'

export const getFieldLabel = (field: string, mapType: KnownFieldMaps): string=> {
  const maps: Record<KnownFieldMaps, Record<string, string>> = {
    ['user-report']: userReportFieldMap,
    ['review']: reviewFieldMap
  }

  const fieldMap = maps[mapType]

  return fieldMap[field] || ''
}
