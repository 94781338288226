import { Alert, Grid } from '@mui/material'

type GridErrorProps = {
  error?: string
}

export const GridError = ({ error }: GridErrorProps) => {
  if (!error) {
    return null
  }

  return (
    <Grid item xs={12}>
      <Alert severity="error">{error}</Alert>
    </Grid>
  )
}
