import { TableColumn, TableColumnProps } from '@/components/Tables/columns/TableColumn/TableColumn'
import { ColorAvatar } from '@/components/UI/ColorAvatar/ColorAvatar'
import { Box, Chip } from '@mui/material'

type ChipItem = {
  id: number | string
  label: string
  avatar?: {
    src?: string;
    color?: string
    title: string
  }
}

type TableMultipleChipProps = {
  items: ChipItem[]
  onClick?: (id: number | string) => void
  tableColumnProps?: Omit<TableColumnProps, 'children'>
}

export const TableMultipleChip = ({
  items,
  onClick,
  tableColumnProps,
}: TableMultipleChipProps) => {
  return (
    <TableColumn {...tableColumnProps}>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {items.map(item => (
          <Chip
            onClick={onClick ? () => onClick(item.id) : undefined}
            key={item.id}
            label={`${item.label}`}
            avatar={
              item?.avatar ? (
                <ColorAvatar
                  src={item?.avatar?.src}
                  color={item?.avatar?.color}
                  title={item?.avatar?.title}
                  variant="circular"
                />
              ) : undefined
            }
            variant="outlined"
          />
        ))}
      </Box>
    </TableColumn>
  )
}
