import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GoogleSheets } from '@/services/api/google-sheets'
import { GetAuthUrlRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGoogleGetAuthUrl = (props?: UseQueryOptions<GetAuthUrlRo, AxiosErrorResponse>) => {
  return useQuery<GetAuthUrlRo, AxiosErrorResponse>(
    ['google-sheets', 'authorize', 'get-auth-url'],
    async () => {
      const response = await GoogleSheets.getAuthUrl()
      return response.data
    },
    props,
  )
}
