import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Rooms } from '@/services/api/rooms'
import { RoomEditDto, RoomEditRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

type UseEditRoomPayload = {
  id: number;
} & RoomEditDto

export const useEditRoom = (props?: UseMutationOptions<RoomEditRo, AxiosErrorResponse, UseEditRoomPayload>) => {
  const queryClient = useQueryClient()

  return useMutation<RoomEditRo, AxiosErrorResponse, UseEditRoomPayload>(
    ['rooms'],
    async (payload: UseEditRoomPayload) => {
      const { id, ...params } = payload
      const response = await Rooms.patch(id, params)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['rooms'])
        props?.onSuccess?.(...args)
      },
    })
}
