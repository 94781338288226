import {
  UserContractCreateDto,
  UserContractCreateRo,
  UserContractDataRo,
  UserContractEditDto,
  UserContractEditRo,
  UserContractRo,
  UserContractsControllerGetParams,
} from '@/services/data-contracts'
import { AxiosResponse } from 'axios'

import { Request } from '../Request'

export const UserContracts = {
  create: async (params: UserContractCreateDto) => {
    return Request.post<
      UserContractCreateDto,
      AxiosResponse<UserContractCreateRo>
    >('/user-contracts', params)
  },

  get: async (params: UserContractsControllerGetParams) => {
    return Request.get<
      UserContractsControllerGetParams,
      AxiosResponse<UserContractDataRo>
    >('/user-contracts', { params })
  },

  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<UserContractRo>>(
      `/user-contracts/${id}`,
    )
  },

  edit: async (id: number, params: UserContractEditDto) => {
    return Request.patch<
      UserContractEditDto,
      AxiosResponse<UserContractEditRo>
    >(`/user-contracts/${id}`, params)
  },

  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/user-contracts/${id}`)
  },
}
