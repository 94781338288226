import { AxiosResponse } from 'axios'

import {
  ReviewCreateDto,
  ReviewCreateRo,
  ReviewDataRo,
  ReviewEditDto, ReviewEditRo,
  ReviewRo,
  ReviewsControllerFindAllParams
} from '@/services/data-contracts'

import { Request } from '../Request'

export const Reviews = {
  create: async (params: ReviewCreateDto) => {
    return Request.post<ReviewCreateDto, AxiosResponse<ReviewCreateRo>>('/reviews', params)
  },

  get: async (params: ReviewsControllerFindAllParams) => {
    return Request.get<ReviewsControllerFindAllParams, AxiosResponse<ReviewDataRo>>('/reviews', { params })
  },

  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<ReviewRo>>(`/reviews/${id}`)
  },

  edit: async (id: number, params: ReviewEditDto) => {
    return Request.patch<ReviewEditDto, AxiosResponse<ReviewEditRo>>(`/reviews/${id}`, params)
  },

  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/reviews/${id}`)
  },
}
