import { AxiosResponse } from 'axios'

import { UserGroupCreateDto, UserGroupCreateRo, UserGroupDataRo, UserGroupEditDto, UserGroupEditRo, UserGroupRo, UserGroupsControllerGetParams } from '@/services/data-contracts'

import { Request } from '../Request'

export const UserGroups = {
  create: async (params: UserGroupCreateDto) => {
    return Request.post<UserGroupCreateDto, AxiosResponse<UserGroupCreateRo>>('/user-groups', params)
  },

  get: async (params: UserGroupsControllerGetParams) => {
    return Request.get<UserGroupsControllerGetParams, AxiosResponse<UserGroupDataRo>>('/user-groups', { params })
  },

  getById: async (id: number) => {
    return Request.get<void, AxiosResponse<UserGroupRo>>(`/user-groups/${id}`)
  },

  edit: async (id: number, params: UserGroupEditDto) => {
    return Request.patch<UserGroupEditDto, AxiosResponse<UserGroupEditRo>>(`/user-groups/${id}`, params)
  },

  delete: async (id: number) => {
    return Request.delete<void, AxiosResponse<boolean>>(`/user-groups/${id}`)
  },
}
