import { Form } from '@/components/Forms/Form'
import { FundsRequestFormValues } from '@/components/Modals/FundsRequestModal/FundsRequestFormValues'
import {
  fundsRequestsFormSchema,
  FundsRequestsFormSchema,
} from '@/components/Modals/FundsRequestModal/schema/fundsRequestsForm.schema'
import { FundsRequestCreateDto } from '@/services/data-contracts'
import { useCreateFundRequest } from '@/services/hooks/funds-requests/useCreateFundRequest'
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from '@mui/material'

import { AxiosErrorResponse } from '@/types'

export type FundsRequestModalProps = {
  onSuccess: () => void
  onError: (error?: AxiosErrorResponse) => void
} & Omit<DialogProps, 'onError' | 'onSuccess'>

export const FundsRequestModal = ({
  onSuccess,
  onError,
  ...dialogProps
}: FundsRequestModalProps) => {
  const createFundsRequest = useCreateFundRequest({
    onSuccess,
    onError,
  })

  const handleSubmit = (data: FundsRequestCreateDto) => {
    createFundsRequest.mutate(data)
  }

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { p: 1, px: 0 } }}
      {...dialogProps}
    >
      <DialogTitle variant="h4">Создание запроса на перевод</DialogTitle>
      <Divider />
      <Form<FundsRequestsFormSchema>
        onSubmit={handleSubmit}
        validationSchema={fundsRequestsFormSchema}
      >
        <DialogContent>
          <FundsRequestFormValues isSubmitting={createFundsRequest.isLoading} />
        </DialogContent>
      </Form>
    </Dialog>
  )
}
