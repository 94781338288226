import { useMemo } from 'react'
import { MultipleSelectOption } from '@/components/Tables/filters/createMultipleSelectFilter.tsx'
import { usersToMultipleSelectOptions } from '@/utils/mappers/usersToMultipleSelectOptions.ts'
import { useGetUsers } from '@/services/hooks/users/useGetUsers.ts'

export const useGetPlayersSelectValues = () => {
  const { data: users, isFetching: isUsersFetching } = useGetUsers({
    limit: 500,
    sort: 'username:ASC'
  })

  const playerMultipleSelectOptions = useMemo<MultipleSelectOption[]>(
    () => usersToMultipleSelectOptions(users ? users.data : []),
    [users],
  )

  return {
    isUsersFetching,
    playerMultipleSelectOptions
  };
}
