import { useState } from 'react'
import { MUIDataTableOptions } from 'mui-datatables'

interface UsePaginationProps {
  limit?: number;
}

const DEFAULT_PAGINATION_LIMIT = 100;

export const useTablePagination = (props?: UsePaginationProps) => {
  const { limit: defaultLimit } = props || {};
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(defaultLimit || DEFAULT_PAGINATION_LIMIT);

  const handleChangeRowsPerPage: MUIDataTableOptions['onChangeRowsPerPage'] = (selectedLimit) => {
    setPage(0);
    setLimit(selectedLimit)
  }

  const handleChangePage: MUIDataTableOptions['onChangePage'] = (selectedPage) => {
    setPage(selectedPage);
  }

  const handlePageReset = () => {
    setPage(0);
  }

  return {
    helpers: {
      resetPage: handlePageReset,
    },
    queryParams: {
      limit,
      offset: page * limit,
    },
    options: {
      page,
      pagination: true,
      rowsPerPage: limit,
      onChangeRowsPerPage: handleChangeRowsPerPage,
      rowsPerPageOptions: [10, 50, 100, 200, 500],
      onChangePage: handleChangePage,
    } as MUIDataTableOptions
  }
}
