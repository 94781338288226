import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { Typography } from '@mui/material'

export const TablePercent = (value: string) => {
  try {
    return (
      <TableColumn minWidth={30}>
        <Typography variant="body2">{`${value}%`}</Typography>
      </TableColumn>
    )
  } catch (e) {
    return '-'
  }
}
