import React from 'react'

import { useTheme } from '@/context/ThemeContext.tsx'
import { DashboardHeader } from '@/layouts/DashboardLayout/DashboardHeader'
import { Box, Container } from '@mui/material'
import { grey } from '@mui/material/colors'

interface DashboardLayoutProps {
  children: React.ReactNode
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { theme } = useTheme()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <DashboardHeader />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          paddingTop: 12,
          paddingBottom: 4,
          height: '100%',
          ...(theme === 'light' && { bgcolor: grey[50] }),
        }}
      >
        <Container
          maxWidth={false}
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  )
}
