import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Reviews } from '@/services/api/reviews'
import { ReviewDataRo, ReviewsControllerFindAllParams } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGetReviews = (
  params: ReviewsControllerFindAllParams,
  props?: UseQueryOptions<ReviewDataRo, AxiosErrorResponse>
) => {
  return useQuery<ReviewDataRo, AxiosErrorResponse>(
    ['reviews', params],
    async () => {
      const response = await Reviews.get(params)
      return response.data;
    },
    props
  )
}
