import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { CurrencyRate } from '@/services/api/currency-rate.ts'
import { CurrencyRateControllerHistoryRatesParams, CurrencyRateHistoryDataRo } from '@/services/data-contracts.ts'

import { AxiosErrorResponse } from '@/types.ts'

export const useGetHistoryRates = (
  params: CurrencyRateControllerHistoryRatesParams,
  props?: UseQueryOptions<CurrencyRateHistoryDataRo, AxiosErrorResponse>,
) => {
  return useQuery<CurrencyRateHistoryDataRo, AxiosErrorResponse>(
    ['currency-rate', 'history-rates', params],
    async () => {
      const response = await CurrencyRate.getHistoryRates(params)
      return response.data
    },
    props,
  )
}
