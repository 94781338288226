import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { GoogleSheets } from '@/services/api/google-sheets'
import { AuthorizeDto } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGoogleAuthorize = (props?: UseMutationOptions<boolean, AxiosErrorResponse, AuthorizeDto>) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, AuthorizeDto>(
    ['google-sheets', 'authorize'],
    async (payload) => {
      const response = await GoogleSheets.authorize(payload)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['google-sheets'])
        props?.onSuccess?.(...args)
      },
    })
}
