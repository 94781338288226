export const parseGoogleSheetId = (input: string): string | null => {
  const regex = /\/d\/(?<google_sheet_id>[0-9a-z_-]+)\//i

  const matches = input.match(regex)

  if (matches?.groups?.google_sheet_id) {
    return matches.groups.google_sheet_id
  }

  return null
}
