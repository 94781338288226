import { GoogleSheetsAuth } from '@/components/Containers/GoogleSheetsAuth/GoogleSheetsAuth'
import { GoogleSheetsExportProfit } from '@/components/Containers/GoogleSheetsExportProfit/GoogleSheetsExportProfit'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { Grid } from '@mui/material'

export const GoogleSheetsPage = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item>
          <GoogleSheetsAuth />
        </Grid>
        <Grid item>
          <GoogleSheetsExportProfit />
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}
