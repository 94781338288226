import { UserContracts } from '@/services/api/user-contracts'
import {
  UserContractDataRo,
  UserContractsControllerGetParams,
} from '@/services/data-contracts'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/types'

export const useGetUserContracts = (
  params: UserContractsControllerGetParams,
  props?: UseQueryOptions<UserContractDataRo, AxiosErrorResponse>,
) => {
  return useQuery<UserContractDataRo, AxiosErrorResponse>(
    ['user-contracts', 'get', params],
    async () => {
      const response = await UserContracts.get(params)
      return response.data
    },
    props,
  )
}
