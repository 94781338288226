import { FormControl, TextField, TextFieldProps } from '@mui/material'
import { MUIDataTableColumn, MUIDataTableState } from 'mui-datatables'

export type CreateRangeFilterProps = Partial<TextFieldProps>

export const createTextFieldFilter = ({
  ...textFieldProps
}: CreateRangeFilterProps) => {
  return {
    display: (
      filterList: MUIDataTableState['filterList'],
      onChange: (
        val: string | string[],
        index: number,
        column: MUIDataTableColumn,
      ) => void,
      index: number,
      column: MUIDataTableColumn,
    ) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const value = filterList[index] as any

      return (
        <FormControl fullWidth>
          <TextField
            {...textFieldProps}
            InputLabelProps={{
              shrink: true,
              ...textFieldProps.InputLabelProps,
            }}
            label={textFieldProps.label || column.label || column.name}
            id={column.name}
            value={value?.[0] || ''}
            onChange={event => {
              filterList[index][0] = event.target.value
              onChange(filterList[index], index, column)
            }}
            type={textFieldProps.type || 'text'}
          />
        </FormControl>
      )
    },
    fullWidth: true,
  }
}
