import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'

import { Reviews } from '@/services/api/reviews'
import { AxiosErrorResponse } from '@/types'

export type UseDeleteReviewPayload = {
  id: number;
}

export const useDeleteReview = (
  props?: UseMutationOptions<boolean, AxiosErrorResponse, UseDeleteReviewPayload>,
) => {
  const queryClient = useQueryClient()

  return useMutation<boolean, AxiosErrorResponse, UseDeleteReviewPayload>(
    ['reviews'],
    async (payload: UseDeleteReviewPayload) => {
      const response = await Reviews.delete(payload.id)
      return response.data
    },
    {
      ...props,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(['reviews'])
        return props?.onSuccess?.(...args)
      }
    },
  )
}
