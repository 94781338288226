import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Users } from '@/services/api/users'
import { UsersRo } from '@/services/data-contracts'
import { AxiosErrorResponse } from '@/types'

export const useGetUserById = (id: number, props?: UseQueryOptions<UsersRo, AxiosErrorResponse>) => {
  return useQuery<UsersRo, AxiosErrorResponse>(
    ['users', id],
    async () => {
      const response = await Users.getById(id)
      return response.data
    },
    props,
  )
}
