import { useMemo } from 'react'

import { getColumnRoomKey } from '@/components/Containers/AdminUsersTotalTable/utils'
import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { RoomCategoryEnum, ROOMS_LIMITATIONS } from '@/constants'
import { PlayersBalancesTotalRo, RoomsDataRo } from '@/services/data-contracts'
import { useGetAdminPlayerBalancesTotal } from '@/services/hooks/funds-transfers/useGetAdminPlayerBalancesTotal'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms'

import {
  AdminUsersTotalTable,
  RoomName,
  TableHeaderColumn,
  TableOrderColumns,
  TableRowData,
} from './types'

export const useAdminUserTotalTable = (): AdminUsersTotalTable => {
  const {
    data,
    isLoading: isGetAdminPlayerBalancesLoading,
    isError: isGetAdminPlayerBalancesTotalError,
  } = useGetAdminPlayerBalancesTotal()
  const playerBalances = data as PlayersBalancesTotalRo[]
  const { defaultSortString } = useGetSort()

  const {
    data: roomsData,
    isLoading: isRoomsLoading,
    isError: isErrorLoadingRooms,
  } = useGetRooms({
    limit: 500,
    offset: 0,
    sort: defaultSortString,
  })

  const rooms = useMemo<RoomsDataRo['data']>(
    () => (roomsData ? roomsData.data : []),
    [roomsData],
  )

  const roomsKeys = rooms.map(room => getColumnRoomKey(room.id))

  const columnsOrder = useMemo(
    () => [
      'username',
      'projectFundsContract',
      'personalFundsContract',
      'profit',
      'profitWithoutBonusesAndTickets',
      'bonuses',
      'surplus',
      'significantSurplus',
      'contractName',
      'averageBuyIn',
      ...roomsKeys,
    ],
    [roomsKeys],
  ) as TableOrderColumns

  const rows: (TableRowData & { userId: number })[] = useMemo(() => {
    if (!playerBalances) {
      return []
    }

    return playerBalances
      .sort((a, b) => {
        return a.user.username.localeCompare(b.user.username)
      })
      .map(({ user, roomsBalancesTree, playerFunds }) => {
        return {
          userId: user.id,
          username: user.username,
          projectFundsContract: playerFunds.projectContract.totalUSD,
          personalFundsContract: playerFunds.personalContract.totalUSD,
          bonuses: playerFunds.roomsByCategorySum.bonuses.totalUSD,
          profit: playerFunds.profit.totalUSD,
          profitWithoutBonusesAndTickets:
            playerFunds.profitWithoutBonusesAndTickets.totalUSD,
          averageBuyIn: playerFunds.averageBuyIn,
          surplus: playerFunds.surplus.totalUSD,
          significantSurplus: playerFunds.significantSurplus.totalUSD,
          contractName: user.contract?.name || 'Пакета нет',
          rooms: roomsBalancesTree,
        }
      })
  }, [playerBalances])

  const rowsSum = useMemo(() => {
    const sum: Partial<Record<string, number>> = {}

    for (const row of rows) {
      columnsOrder.map(columnKey => {
        const keys = columnKey.split('.')
        // @ts-ignore
        const value = keys.reduce((value, key) => value?.[key], row)

        if (typeof value !== 'number') {
          return
        }

        // Средства проекта учитываются только положительные в сумме.
        if (columnKey === 'projectFundsContract' && value < 0) {
          return
        }

        sum[columnKey] = (sum[columnKey] || 0) + value
      })
    }

    return sum
  }, [rows, columnsOrder])

  const [moneyStoragesSum, negativeProfitsSum] = useMemo(() => {
    if (!playerBalances || !playerBalances.length) {
      return [0, 0]
    }

    let moneyStoragesUsd = 0
    let negativeProfitsUsd = 0

    for (const player of playerBalances) {
      moneyStoragesUsd +=
        player.playerFunds.roomsByCategorySum.moneyStorages.totalUSD
      negativeProfitsUsd +=
        player.playerFunds.profit.totalUSD < 0
          ? player.playerFunds.profit.totalUSD
          : 0
    }

    return [moneyStoragesUsd, negativeProfitsUsd]
  }, [playerBalances])

  const roomsColumns = useMemo<Record<RoomName, TableHeaderColumn>>(() => {
    const columns: Record<RoomName, TableHeaderColumn> = {}
    const NEED_TO_SHOW_CATEGORIES = ROOMS_LIMITATIONS.ADMIN_USER_TOTAL_TABLE

    for (const room of rooms) {
      const roomKey = getColumnRoomKey(room.id)
      const roomCategory = room.category as RoomCategoryEnum[]

      columns[roomKey] = {
        name: roomKey,
        label: room.name,
        display: roomCategory.some(category =>
          NEED_TO_SHOW_CATEGORIES.includes(category),
        ),
        additional: {
          head: {
            avatar: room?.avatar?.location || true,
          },
          body: {
            size: 'small',
            roomId: room.id,
            ...(room.currency && {
              currency: room.currency,
            }),
          },
          footer: {
            sum: rowsSum?.[roomKey] || 0,
          },
        },
      } as TableHeaderColumn
    }

    return columns
  }, [rooms, rowsSum])

  const isLoading = isGetAdminPlayerBalancesLoading || isRoomsLoading

  const errorMessage = useMemo(() => {
    const errors = []
    isGetAdminPlayerBalancesTotalError &&
      errors.push('Не удалось загрузить данные с игроками и их балансами')
    isErrorLoadingRooms && errors.push('Не удалось загрузить румы')

    return errors.join('\n')
  }, [isGetAdminPlayerBalancesTotalError, isErrorLoadingRooms])

  return {
    isLoading,
    isError: Boolean(errorMessage),
    errorMessage,
    table: {
      columnsOrder,
      header: {
        profit: {
          name: 'profit',
          label: 'Профит (по балансам)',
          additional: {
            body: {
              size: 'medium',
              currency: 'USD',
            },
            footer: {
              sum: rowsSum['profit'] || 0,
            },
          },
        },
        profitWithoutBonusesAndTickets: {
          name: 'profitWithoutBonusesAndTickets',
          label: 'Профит',
          additional: {
            body: {
              size: 'medium',
              currency: 'USD',
            },
            footer: {
              sum: rowsSum['profitWithoutBonusesAndTickets'] || 0,
            },
          },
        },
        username: {
          name: 'username',
          label: 'Счёт',
          additional: {
            head: {
              fixed: true,
            },
            body: {
              avatar: false,
              size: 'large',
            },
            footer: {
              text: 'Итого:',
            },
          },
        },
        projectFundsContract: {
          name: 'projectFundsContract',
          label: '$ проекта',
          additional: {
            body: {
              currency: 'USD',
              size: 'medium',
            },
            footer: {
              sum: rowsSum['projectFundsContract'] || 0,
            },
          },
        },
        personalFundsContract: {
          name: 'personalFundsContract',
          label: '$ игрока',
          additional: {
            body: {
              currency: 'USD',
              size: 'medium',
            },
            footer: {
              sum: rowsSum['personalFundsContract'] || 0,
            },
          },
        },
        averageBuyIn: {
          name: 'averageBuyIn',
          label: 'Примерное АБИ',
          additional: {
            body: {
              size: 'small',
            },
          },
        },
        contractName: {
          name: 'contractName',
          label: 'Пакет',
          additional: {
            body: {
              size: 'small',
            },
          },
        },
        bonuses: {
          name: 'bonuses',
          label: 'Бонусы',
          additional: {
            body: {
              size: 'large',
              currency: 'USD',
            },
            footer: {
              sum: rowsSum['bonuses'] || 0,
            },
          },
        },
        surplus: {
          name: 'surplus',
          label: 'Излишки',
          additional: {
            body: {
              size: 'large',
              currency: 'USD',
            },
            footer: {
              sum: rowsSum['surplus'] || 0,
            },
          },
        },
        significantSurplus: {
          name: 'significantSurplus',
          label: 'Существенные излишки',
          additional: {
            body: {
              size: 'large',
              currency: 'USD',
            },
            footer: {
              sum: rowsSum['significantSurplus'] || 0,
            },
          },
        },
        ...roomsColumns,
      },
      body: rows,
      footer: {
        moneyStoragesSum,
        negativeProfitsSum,
      },
    },
  }
}
