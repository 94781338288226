import { grey, lightGreen, lightBlue, red, pink } from '@mui/material/colors'

export const rankToColor = (rank: number) => {
  // eslint-disable-next-line prefer-destructuring
  const DEFAULT_COLOR = grey[50]

  return {
    0: grey[200],
    1: grey[300],
    2: grey[400],
    3: grey[500],
    4: lightGreen[200],
    5: lightGreen[300],
    6: lightGreen[400],
    7: lightGreen[500],
    8: lightGreen[600],
    9: lightBlue[200],
    10: lightBlue[300],
    11: lightBlue[400],
    12: lightBlue[500],
    13: pink[200],
    14: pink[300],
    15: pink[400],
    16: pink[500],
    17: red[200],
    18: red[300],
    19: red[400],
    20: red[500],
    21: red[600],
    22: red[700],
    23: red[800],
    24: red[900],
  }[rank] || DEFAULT_COLOR
}
