export class CurrencyHelper {
  private static currencyMap: Record<string, string> = {
    USD: '$',
    EUR: '€',
  }

  public static getCurrencySign = (
    currency: 'USD' | 'EUR' | unknown,
  ): string => {
    if (typeof currency !== 'string') {
      return ''
    }

    currency = currency.toUpperCase()

    if (currency === 'USD' || currency === 'EUR') {
      return CurrencyHelper.currencyMap[currency]
    }

    return ''
  }

  public static formatCurrency = (
    amount: number | string,
    currency: 'USD' | 'EUR' | unknown,
  ): string => {
    if (!amount) {
      amount = 0
    }

    if (typeof amount === 'string') {
      amount = parseFloat(amount)
    }

    amount = Math.round(amount * 100) / 100;

    if (Number.isNaN(amount)) {
      return ''
    }

    // remove minus zero
    if (Object.is(amount, -0)) {
      amount = 0;
    }

    const signFormatted = CurrencyHelper.getCurrencySign(currency)
    const amountFormatted = amount.toFixed(2)

    return [signFormatted, amountFormatted].join('')
  }
}
