import { AxiosResponse } from 'axios'
import { Request } from '../Request'
import { UploadFileDto, UploadFileRo } from '@/services/data-contracts'

export const Uploads = {
  create: async (params: UploadFileDto) => {
    const formData = new FormData()
    formData.set('file', params.file)

    return Request.post<UploadFileDto, AxiosResponse<UploadFileRo>>('/uploads', formData)
  },
}
