export const transform = {
  emptyStringToNull: (value: unknown, originalValue: unknown) => {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null
    }
    return value
  },
  emptyStringToZero: (value: unknown, originalValue: unknown) => {
    if (typeof originalValue === 'string' && originalValue === '') {
      return 0
    }

    return value
  },
}
