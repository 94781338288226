export const isEmptyRoomSetting = (value: string) => {
  if (!value) {
    return true;
  }

  if (value === '-') {
    return true;
  }

  return false;
}
