import { useState } from 'react'

import { getCustomSettingOtherRoomsName } from '@/components/CustomSettings/constants'
import { CustomSettingWrap } from '@/components/CustomSettings/templates/CustomSettingWrap'
import { TextSetting } from '@/components/CustomSettings/templates/TextCustomSetting/Text.setting'
import { Box, TextField } from '@mui/material'

export const OtherRoomsTableSetting = () => {
  const [rank, setRank] = useState<number | null>(null)

  return (
    <CustomSettingWrap>
      <TextField
        fullWidth
        label={'Ранг игрока (контент в турнирах)'}
        InputProps={{ type: 'number', placeholder: 'Введите ранг' }}
        value={rank}
        onChange={event =>
          setRank(
            isNaN(+event.target.value) || event.target.value === ''
              ? null
              : +event.target.value,
          )
        }
      />
      {rank !== null && (
        <Box mt={2}>
          <TextSetting
            name={getCustomSettingOtherRoomsName(rank)}
            title={`Настройка контента для ранга ${rank} в турнирах.`}
            key={rank}
          />
        </Box>
      )}
    </CustomSettingWrap>
  )
}
