import React from 'react'

import { PlayerCard } from '@/components/UI/PlayerCard/PlayerCard'
import { WithAlert } from '@/components/Utils/WithAlert/WithAlert'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { ByCurrencyRo } from '@/services/data-contracts'
import { useGetPlayerFundsByToken } from '@/services/hooks/cabinet/player/useGetPlayerFundsByToken'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { CurrencyHelper } from '@/utils/CurrencyHelper'
import { RoleHelper } from '@/utils/RoleHelper'
import { Box, Divider, Typography } from '@mui/material'

export const PlayerResultsCard = () => {
  const {
    currentUser,
    isLoading: isCurrentUserLoading,
    isError: isCurrentUserError,
  } = useCurrentUser()

  const {
    data: playerFunds,
    isLoading: isLoadingPlayerFunds,
    isError: isErrorPlayerFunds,
  } = useGetPlayerFundsByToken({ refetchOnMount: 'always' })
  const isPlayer = RoleHelper.isPlayer(currentUser?.role)
  const rows: {
    id: string
    title: string
    tooltip?: string
    amount?: ByCurrencyRo
    text?: string
  }[] = playerFunds
    ? isPlayer
      ? [
          {
            id: 'contract',
            title: 'Текущий пакет',
            text: playerFunds.contract?.name || 'Пакета нет',
          },
          {
            id: 'personal',
            title: 'Личные средства',
            amount: playerFunds.personalContract,
          },
          {
            id: 'profit',
            title: 'Профит',
            amount: playerFunds.profitWithoutBonusesAndTickets,
          },
          {
            id: 'bonuses',
            title: 'Бонусы',
            amount: playerFunds.roomsByCategorySum.bonuses,
          },
          {
            id: 'significantSurplus',
            title: 'Излишки',
            tooltip: 'Существенные излишки',
            amount: playerFunds.significantSurplus,
          },
        ]
      : [
          {
            id: 'contract',
            title: 'Текущий пакет',
            text: playerFunds.contract?.name || 'Пакета нет',
          },
          {
            id: 'project',
            title: 'Проектные средства',
            amount: playerFunds.project,
          },
          {
            id: 'personal',
            title: 'Личные средства',
            amount: playerFunds.personalContract,
          },
          {
            id: 'profit',
            title: 'Профит',
            amount: playerFunds.profitWithoutBonusesAndTickets,
          },
          {
            id: 'bonuses',
            title: 'Бонусы',
            amount: playerFunds.roomsByCategorySum.bonuses,
          },
          {
            id: 'significantSurplus',
            title: 'Излишки',
            tooltip: 'Существенные излишки',
            amount: playerFunds.significantSurplus,
          },
        ]
    : []

  const getTypographyColor = (amount: number) => {
    const rounded = Math.round(amount * 100) / 100

    if (rounded === 0) {
      return ''
    }

    return rounded > 0 ? 'success.main' : 'error.main'
  }

  const isVisibleDivisionByCurrency = RoleHelper.isAdmin(currentUser?.role)

  return (
    <WithLoader isLoading={isCurrentUserLoading}>
      <WithAlert
        isRestricted={isCurrentUserError}
        message="Не удалось получить данные о пользователе"
      >
        {currentUser ? (
          <PlayerCard user={currentUser}>
            <WithLoader isLoading={isLoadingPlayerFunds}>
              <WithAlert
                isRestricted={isErrorPlayerFunds}
                message="Не удалось получить данные о результатах пользователя"
              >
                <Box my={1}>
                  <Divider />
                </Box>
                {rows.map(row => {
                  if (!row.amount && !row.text) {
                    return <React.Fragment key={row.id} />
                  }

                  return (
                    <Box key={row.id} component="li" title={row.tooltip}>
                      <Typography component="span">{row.title}: </Typography>
                      {row.text && (
                        <Typography component="span">{row.text}</Typography>
                      )}
                      {row.amount && (
                        <>
                          <Typography
                            component="span"
                            fontWeight={600}
                            color={getTypographyColor(row.amount.totalUSD)}
                          >
                            {CurrencyHelper.formatCurrency(
                              row.amount.totalUSD,
                              'USD',
                            )}
                          </Typography>
                          {isVisibleDivisionByCurrency && (
                            <>
                              <span>{' ('}</span>
                              <Typography
                                component="span"
                                color={getTypographyColor(row.amount.USD)}
                              >
                                {CurrencyHelper.formatCurrency(
                                  row.amount.USD,
                                  'USD',
                                )}
                              </Typography>
                              <span>{' | '}</span>
                              <Typography
                                component="span"
                                color={getTypographyColor(row.amount.EUR)}
                              >
                                {CurrencyHelper.formatCurrency(
                                  row.amount.EUR,
                                  'EUR',
                                )}
                              </Typography>
                              <span>{')'}</span>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  )
                })}
              </WithAlert>
            </WithLoader>
          </PlayerCard>
        ) : null}
      </WithAlert>
    </WithLoader>
  )
}
