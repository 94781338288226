import {
  FundsTransferCreatePersonalDto,
  FundsTransferCreateProjectDto,
  FundsTransferEditPersonalDto,
  FundsTransferEditProjectDto,
} from '@/services/data-contracts'
import * as yup from 'yup'

export type CreateTransferModalSchemaProject = Omit<
  FundsTransferCreateProjectDto,
  'totalAmount'
> & {
  factAmount: number
}
export type CreateTransferModalSchemaPersonal = Omit<
  FundsTransferCreatePersonalDto,
  'totalAmount'
> & {
  factAmount: number
}

export type CreateTransferModalSchema =
  | CreateTransferModalSchemaProject
  | CreateTransferModalSchemaPersonal

export type EditTransferModalSchema =
  | FundsTransferEditProjectDto
  | FundsTransferEditPersonalDto

type TransferModalSchemaOption = {
  type: 'personal' | 'project'
}

export const transferModalSchema = {
  generate: ({ type }: TransferModalSchemaOption) => {
    const GENERAL_VALIDATION = {
      room: yup
        .object({
          id: yup.string().required('Рум обязателен'),
        })
        .required('Рум обязателен'),
      factAmount: yup
        .number()
        .transform((_, value) => {
          if (value.toString().includes(',')) {
            return +value.replace(/,/, '.')
          }
          return +value
        })
        .typeError('Введите фактическую сумму перевода')
        .required('Введите фактическую сумму перевода'),
      transferFee: yup
        .number()
        .transform((_, value) => {
          if (value.toString().includes(',')) {
            return +value.replace(/,/, '.')
          }
          return +value
        })
        .optional()
        .transform(value => (!value ? 0 : value)),
      screenshot: yup
        .object({
          id: yup.mixed().nullable(),
        })
        .nullable()
        .optional(),
      comment: yup
        .string()
        .max(500, 'Комментарий не может быть больше 500 символов'),
    }

    if (type === 'project') {
      return yup.object({
        ...GENERAL_VALIDATION,
        recipient: yup
          .object({
            id: yup.string().required('Получатель обязателен'),
          })
          .required('Получатель обязателен'),
      })
    }

    if (type === 'personal') {
      return yup.object({
        ...GENERAL_VALIDATION,
        transferPurpose: yup.string().required('Заполните тип перевода'),
      })
    }

    return yup.object({
      ...GENERAL_VALIDATION,
    })
  },
}
