import { JSX, MouseEvent, useState } from 'react'

import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { MUIDataTableColumnOptions } from 'mui-datatables'

type UnknownRow = { id: number }

type MenuOption<T extends UnknownRow> = {
  id: string | number
  label: string
  onClick: (row: T) => void
  isDisabled?: (row: T) => boolean
  icon?: JSX.Element
  disabled?: boolean
}

type MenuOptionCallback<T extends UnknownRow> = (row: T) => MenuOption<T> | null

export type UseMenuProps<T extends UnknownRow> = {
  data: T[]
  options: (MenuOption<T> | MenuOptionCallback<T>)[]
}

export const useMenu = <T extends UnknownRow>({
  options: rawOptions,
  data,
}: UseMenuProps<T>) => {
  const [anchorEls, setAnchorEls] = useState<
    Record<number, HTMLElement | null>
  >({})

  const handleOpenMenu = (rowId: number, event: MouseEvent<HTMLElement>) => {
    setAnchorEls(prev => ({
      ...prev,
      [rowId]: event.currentTarget,
    }))
  }

  const handleCloseAllMenu = () => {
    setAnchorEls({})
  }

  const handleCloseMenu = (rowId: number) => {
    setAnchorEls(prev => ({
      ...prev,
      [rowId]: null,
    }))
  }

  const TableMenuColumn: MUIDataTableColumnOptions['customBodyRenderLite'] = (
    dataIndex: number,
  ) => {
    const row = data?.[dataIndex]

    if (!row) {
      return
    }

    const options = rawOptions.filter(option =>
      typeof option === 'function' ? !!option(row) : !!option,
    )

    if (!options.length) {
      return ''
    }

    return (
      <TableColumn>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={event => handleOpenMenu(row?.id, event)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEls[row.id]}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted={false}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEls[row.id])}
            onClose={() => handleCloseMenu(row?.id)}
          >
            {options.map(menuOption => {
              const option =
                typeof menuOption === 'function' ? menuOption(row) : menuOption

              if (!option) {
                return
              }

              return (
                <MenuItem
                  key={option.id}
                  disabled={option?.disabled || option?.isDisabled?.(row)}
                  onClick={() => {
                    handleCloseAllMenu()
                    option.onClick(row)
                  }}
                >
                  {option.icon && (
                    <Box
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 1,
                      }}
                    >
                      {option.icon}
                    </Box>
                  )}
                  <Typography textAlign="center">{option.label}</Typography>
                </MenuItem>
              )
            })}
          </Menu>
        </Box>
      </TableColumn>
    )
  }

  return {
    anchorEls,
    handleOpenMenu,
    handleCloseAllMenu,
    handleCloseMenu,
    TableMenuColumn,
  }
}
