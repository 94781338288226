import { Box, Dialog, DialogContent, DialogProps, DialogTitle, Divider, ImageList, ImageListItem, Typography } from '@mui/material'
import { useGetUserReportById } from '@/services/hooks/user-reports/useGetUserReportById'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'

interface UserReportScreenshotsModalProps extends DialogProps {
  reportId: number
}

export const UserReportScreenshotsModal = ({ reportId, ...props }: UserReportScreenshotsModalProps) => {
  const { data: userReportResponse, isFetching: isUserReportFetching } = useGetUserReportById(reportId)

  return (
    <Dialog maxWidth="xl" {...props}>
      <DialogTitle>Скриншоты. ID Отчёта: {reportId}</DialogTitle>
      <DialogContent>
        <Box width="100%">
          <WithLoader isLoading={isUserReportFetching}>
            <Box mb={1}>
              <Typography variant="h5" mb={2}>Руки</Typography>
              {!!userReportResponse?.hands?.length && (
                <ImageList cols={3} rowHeight={200}>
                  {userReportResponse.hands.map((screenshot) => (
                    <ImageListItem key={screenshot.id} sx={{ cursor: 'pointer' }} onClick={() => window.open(screenshot.location, '_blank')}>
                      <img
                        src={screenshot.location}
                        alt={screenshot.originalname}
                        loading="lazy"
                        style={{ height: '100%' }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
              {!userReportResponse?.hands?.length && 'Нет скриншотов рук'}
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Box mb={1}>
              <Typography variant="h5" mb={2}>Скриншоты</Typography>
              {!!userReportResponse?.screenshots?.length && (
                <ImageList cols={3} rowHeight={200}>
                  {userReportResponse.screenshots.map((screenshot) => (
                    <ImageListItem key={screenshot.id} sx={{ cursor: 'pointer' }} onClick={() => window.open(screenshot.location, '_blank')}>
                      <img
                        src={screenshot.location}
                        alt={screenshot.originalname}
                        loading="lazy"
                        style={{ height: '100%' }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
              {!userReportResponse?.hands?.length && 'Нет скриншотов'}
            </Box>
          </WithLoader>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
