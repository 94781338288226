import { useState } from 'react'

import { Form } from '@/components/Forms/Form.tsx'
import { TextField } from '@/components/Forms/TextField.tsx'
import {
  TableImageTitle,
  TableImageTitleProps,
} from '@/components/Tables/columns/TableImageTitle/TableImageTitle.tsx'
import { TransferPurposeEnum } from '@/constants.ts'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Box, ButtonGroup, IconButton } from '@mui/material'

type TFieldValues = {
  totalAmount: number
  transferPurpose: TransferPurposeEnum
}

interface RoomCellProps extends TableImageTitleProps {
  handleSubmit?: (data: TFieldValues) => void
}
const RoomCell = ({ handleSubmit, ...rest }: RoomCellProps) => {
  const [isEditable, setIsEditable] = useState(false)
  const value = +rest.title.replace(/[^0-9.-]+/g, '')
  return (
    <Box onDoubleClick={() => setIsEditable(value => !value)}>
      {!isEditable && <TableImageTitle {...rest} />}
      {isEditable && (
        <Box width="150px">
          <Form
            onSubmit={data => {
              handleSubmit?.({
                ...data,
                totalAmount: data.totalAmount.replace(/,/g, '.'),
              } as TFieldValues)
              setIsEditable(false)
            }}
          >
            <Box>
              <TextField
                label="Сумма"
                name="totalAmount"
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                  defaultValue: Number.isNaN(+value) ? '' : value,
                }}
                placeholder="Введите сумму"
              />
            </Box>
            <ButtonGroup size="small">
              <IconButton color="success" type="submit">
                <DoneIcon />
              </IconButton>
              <IconButton color="error" onClick={() => setIsEditable(false)}>
                <CloseIcon />
              </IconButton>
            </ButtonGroup>
          </Form>
        </Box>
      )}
    </Box>
  )
}

export default RoomCell
